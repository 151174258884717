import axios from "axios";
import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { url } from "../context/url.js";

const BookingModal = ({ showModal, setShowModal, onSubmit }) => {
  const [tanggal, setTanggal] = useState("");
  const [SisaKuota, setSisaKuota] = useState("");
  const [sisa, setSisa] = useState("");
  const [error, setError] = useState("");
  const [jamBooking, setJamBooking] = useState([]); // State to store booking times
  const [selectedJamBooking, setSelectedJamBooking] = useState(""); // State to store selected booking time
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch available booking times when component mounts
    const fetchJamBooking = async () => {
      try {
        const response = await axios.get(`${url}/booking/jamBooking/1`, {
          headers: { access_token: localStorage.getItem("access_token") }
        });
        setJamBooking(response.data);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setError(err.response.data.err || "An error occurred.");
        } else {
          setError("An error occurred.");
        }
      }
    };

    fetchJamBooking();
  }, []); // Empty dependency array ensures this runs only on mount

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (SisaKuota === "0") {
      setError("Harap pilih hari booking lain");
      return;
    }
    try {
      await onSubmit({ tanggal, sisa, selectedJamBooking }); // Call the onSubmit function with the required data
      setShowModal(false); // Close the modal
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  const saveTanggal = async () => {
    if (!tanggal) {
      setError("Tanggal harus dipilih terlebih dahulu.");
      return;
    }
    try {
      const response = await axios.post(
        `${url}/booking/kuota`,
        { tanggal_booking: tanggal, id_jam: selectedJamBooking }, // Include selectedJamBooking in the request
        { headers: { access_token: localStorage.getItem("access_token") } }
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        setSisaKuota(response.data[0].quota);
      } else {
        setSisaKuota("0");
      }
      setError("");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="modal fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
  <div className="bg-white rounded-lg shadow-lg w-2/3 lg:w-1/3 p-4">
    <div className="flex justify-end p-2">
      <AiOutlineClose
        size={25}
        className="text-black hover:text-gray-800 cursor-pointer"
        onClick={handleClose}
      />
    </div>
    {error && (
      <div className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg mb-4 max-w-full overflow-hidden">
        {error}
      </div>
    )}
        <form onSubmit={handleSubmit}>
          <div className="py-1 mx-4">
            <label className="label py-2 pb-3 text-xs font-bold">
              Tanggal Booking<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-1">
              <input
                type="date"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={tanggal}
                onChange={(e) => setTanggal(e.target.value)}
                placeholder="Tanggal Booking"
              />
            </div>
          </div>
          <div className="py-1 mx-4">
            <label className="label py-6 pb-3 text-xs">
              Pilih Jam Booking<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-1">
              <select
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={selectedJamBooking}
                onChange={(e) => setSelectedJamBooking(e.target.value)}
              >
                <option value="">Pilih Jam Booking</option>
                {jamBooking.map((jam) => (
                  <option key={jam.id_jam_booking} value={jam.id_jam_booking}>
                    {jam.jam_booking} 
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="py-1 mx-4">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={saveTanggal}
                className="button bg-[#F08519] w-full font-bold text-white text-xs p-3 border-none rounded-lg"
                disabled={!tanggal}
              >
                Cek Tanggal dan Jam Booking
              </button>
            </div>
          </div>

          <div className="py-1 mx-4">
            <label className="label py-6 pb-3 text-xs">
              Quota<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-1">
              <input
                type="text"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value="5"
                readOnly
                placeholder="Quota Yang Tersedia"
              />
            </div>
          </div>
          <div className="py-1 mx-4">
            <label className="label py-6 pb-3 text-xs">
              Sisa<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-1">
              <input
                type="text"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={SisaKuota || "0"}
                onChange={(e) => setSisa(e.target.value)}
                placeholder="Sisa Quota"
                readOnly
              />
            </div>
          </div>
          <div className="py-1 mx-4">
            <div className="py-1 flex justify-center">
              <button
                type="submit"
                className="button bg-[#F08519] w-full font-bold text-white text-xs p-3 border-none rounded-lg"
                disabled={SisaKuota === "0" || !tanggal || !selectedJamBooking}
              >
                Pilih
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;