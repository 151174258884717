import React, {useEffect, useState} from 'react';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import axios from 'axios';
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js"; 

const DashboardIn = () => {
    const [keuntungan, setKeuntungan] = useState([]);
    const [servis, setServis] = useState([]);
  
    const getKeuntungan = async () => {
      try {
        const response = await axios.get(`${url}/keuntungan/harian`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        if (response.data.err) {
          alert(response.data.err);
        } else {
          setKeuntungan(response.data);
        }
      } catch (err) {
        console.error(err);
       }
    };
  
    const getServis = async () => {
      try {
        const response = await axios.get(`${url}/transaksiservis/harian`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        if (response.data.err) {
          alert(response.data.err);
        } else {
          setServis(response.data);
        }
      } catch (err) {
        console.error(err)
       }
    };
  
    useEffect(() => {
      getKeuntungan();
      getServis();
    }, []);
  
    return (
      <div className='bg-[#F7F7F7]'>
        <div className='bg-white p-6 shadow-b-lg'>
          <p className='text-black font-bold text-md'>Dashboard</p>
        </div>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 m-6 gap-4'>
          <div className='rounded object-contain'>
            <div className='border-2 bg-white border-none rounded-lg object-contain shadow-md mx-2'>
              <div className='flex py-10 items-center justify-around object-contain mx-2'>
                <div className='px-2 text-md object-contain'>
                  <div className='border-2 border-none rounded-md p-4 bg-[#FAD3D4]'>
                    <FaMoneyBillTransfer size={30} className='text-[#E62129]' />
                  </div>
                </div>
                <div className='px-2 object-contain'>
                  <p className='text-xs text-[#777777] object-contain'>Transaksi Pemasukan Hari Ini</p>
                  <h1 className='text-xl font-bold'>
                  {formatCurrency(keuntungan.length > 0 ? keuntungan[0].total_keuntungan : 0)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className='rounded object-contain'>
            <div className='border-2 bg-white border-none rounded-lg object-contain shadow-md mx-2'>
              <div className='flex py-10 items-center justify-around object-contain mx-2'>
                <div className='px-2 text-md object-contain'>
                  <div className='border-2 border-none rounded-md p-4 bg-[#FAD3AC]'>
                    <FaTools size={30} className='text-[#F08519]' />
                  </div>
                </div>
                <div className='px-2 object-contain'>
                  <p className='text-xs text-[#777777]'>Transaksi Service Hari Ini</p>
                  <h1 className='text-xl font-bold'>
                    {formatCurrency(servis.length > 0 ? servis[0].total_harga_servis : '0')}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default DashboardIn;
