
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../context/url.js";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [err, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Tambahkan state isLoading
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set isLoading menjadi true ketika submit dimulai
    try {
      const response = await axios.post(`${url}/reset/req`, { email });
      setMessage(response.data);
      setIsLoading(false); // Set isLoading menjadi false ketika proses selesai
      navigate("/forgot-password/reset");
    } catch (err) {
      setError(err.response.data);
      setIsLoading(false); // Set isLoading menjadi false ketika terjadi error
    }
  };

  return (
    <div className="mx-auto py-36">
      <h1 className="font-extrabold py-6 text-4xl text-center">Lupa Password</h1>
      <div className="my-4 items-center flex flex-col w-full">
        <div className="w-[75%] md:w-[50%]">
          <form onSubmit={handleSubmit} className="box">
            {err && (
              <p className="text-[#E62129] text-sm font-medium bg-[#FCE7D1] p-4 border-none rounded-lg w-full">
                {err}
              </p>
            )}
            {message && (
              <p className="text-[#1FAD66] text-sm font-medium bg-[#DFF8E6] p-4 border-none rounded-lg w-full">
                {message}
              </p>
            )}
            <div className="py-2">
              <label className="label py-8 pb-3">
                Email<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  required
                  type="email"
                  placeholder="Email Anda"
                  value={email}
                  onChange={handleChange}
                  className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                />
              </div>
            </div>
            <div className="py-2">
              <button
                type="submit"
                className="button bg-[#E62129] font-bold text-white p-3 border-none rounded-lg w-full"
                disabled={isLoading} // Disable tombol saat loading
              >
                {isLoading ? "Loading..." : "Kirim"} {/* Tampilkan Loading... saat isLoading true */}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
