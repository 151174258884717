import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js";

const SukuCadang = () => {
  const [sukuCadang, setSukuCadang] = useState([]);
  const [Kategori, setKategori] = useState([]);
  const [Supplier, setSupplier] = useState([]);
  const [Status, setStatus] = useState([]);
  const [FilteredSukuCadang, setFilteredSukuCadang] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKategori, setSelectedKategori] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredSukuCadang.length / perpage);
const [lowStockWarning, setLowStockWarning] = useState(false);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    getSukuCadang();
    getKategori();
    getSupplier();
    getStatus();
  }, []);

  useEffect(() => {
    filterSukuCadang();
  }, [
    Kategori,
    Supplier,
    Status,
    searchTerm,
    selectedKategori,
    selectedStatus,
  ]);

  const getSukuCadang = async () => {
    try {
      const response = await axios.get(`${url}/skc`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setSukuCadang(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const getKategori = async () => {
    try {
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setKategori(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const getSupplier = async () => {
    try {
      const response = await axios.get(`${url}/supplier`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setSupplier(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(
        `${url}/status/sukucadang`,
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatus(response.data);
      }
    } catch (error) {
      console.error(error);    }
  };

  const getKategoriName = (id_kategori) => {
    const kategori = Kategori.find(
      (kategori) => kategori.id_kategori === id_kategori
    );
    return kategori ? kategori.kategori : "Unknown";
  };

  const getSupplierName = (id_supplier) => {
    const supplier = Supplier.find(
      (supplier) => supplier.id_supplier === id_supplier
    );
    return supplier ? supplier.nama : "Unknown";
  };

  const getStatusName = (id_status_sc) => {
    const status = Status.find(
      (status) => status.id_status_sc === id_status_sc
    );
    return status ? status.status : "Unknown";
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const handleKategoriChange = (e) => {
    setSelectedKategori(e.target.value);
    setPagenumber(0);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setPagenumber(0);
  };

  const filterSukuCadang = () => {
    const filtered = sukuCadang.filter((sukuCadang) => {
      const kategoriName = getKategoriName(
        sukuCadang.id_kategori
      ).toLowerCase();
      const supplierName = getSupplierName(
        sukuCadang.id_supplier
      ).toLowerCase();
      const statusName = getStatusName(sukuCadang.id_status_sc).toLowerCase();
      const matchesSearchTerm =
        sukuCadang.nama_produk.toLowerCase().includes(searchTerm) ||
        supplierName.includes(searchTerm) ||
        kategoriName.includes(searchTerm) ||
        statusName.includes(searchTerm) ||
        sukuCadang.harga_beli.toString().toLowerCase().includes(searchTerm) ||
        sukuCadang.harga_jual.toString().toLowerCase().includes(searchTerm) ||
        sukuCadang.tanggal.toString().toLowerCase().includes(searchTerm) ||
        sukuCadang.stok.toString().toLowerCase().includes(searchTerm);

      const matchesKategori =
        !selectedKategori ||
        sukuCadang.id_kategori === parseInt(selectedKategori);

      const matchesStatus =
        !selectedStatus || sukuCadang.id_status_sc === parseInt(selectedStatus);

      return matchesSearchTerm && matchesKategori && matchesStatus;
    });
    setFilteredSukuCadang(filtered);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
};

const EnlargedImage = ({ imageUrl, onClose }) => {
  return (
      <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="max-w-lg max-h-screen overflow-auto">
              <img src={imageUrl} alt="Enlarged" className="w-full" />
              <AiOutlineClose size={25} onClick={onClose} className="absolute top-4 right-4 text-white hover:text-gray-700 focus:outline-none"/>
          </div>
      </div>
  );
};

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Produk Suku Cadang</p>
        </div>
      </div>

      <div className="m-2 md:m-6 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Suku Cadang</h1>
        <div className="flex flex-col sm:flex-row justify-between my-2 space-y-2 sm:space-y-0 sm:space-x-2">
  <div className="search w-full sm:w-auto">
    <div className="flex-grow relative flex justify-end">
      <input
        type="text"
        placeholder="Cari"
        className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs w-full sm:w-auto"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
        <IoMdSearch size={15} className="text-[#777777]" />
      </span>
    </div>
  </div>

  <div className="kategori w-full sm:w-auto">
    <select
      className="bg-[#F7F7F7] border border-gray-400 text-gray-400 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs w-full sm:w-auto"
      value={selectedKategori}
      onChange={handleKategoriChange}
    >
      <option value="" className="text-gray-700">Pilih Kategori Produk</option>
      {Kategori.map((kategori) => (
        <option key={kategori.id_kategori} value={kategori.id_kategori}>
          {kategori.kategori}
        </option>
      ))}
    </select>
  </div>

  <div className="status w-full sm:w-auto">
    <select
      className="bg-[#F7F7F7] border border-gray-400 text-gray-400 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs w-full sm:w-auto"
      value={selectedStatus}
      onChange={handleStatusChange}
    >
      <option value="">Pilih Status</option>
      {Status.map((status) => (
        <option key={status.id_status_sc} value={status.id_status_sc}>
          {status.status}
        </option>
      ))}
    </select>
  </div>
</div>

      </div>

      <div className="w-full bg-[#F7F7F7] my-6">
        <div className="text-xs pt-2">
          <Link
            to="/suku-cadang/add"
            className="button bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
          >
            Tambah
          </Link>
        </div>
      </div>

      <div className="flex gap-2 mx-2 md:mx-6 p2-4 relative align-middle items-center">
        <p className="text-xs">Show</p>
        <select
          className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
          value={perpage}
          onChange={handlePerPageChange}
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <p className="text-xs">entries</p>
      </div>

 {lowStockWarning && (
    <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full mx-2 md:mx-6">
      Terdapat Suku Cadang yang sudah mau habis
    </p>
  )}

      <div className="flex justify-center my-4">
          <div className="my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
          <div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama Suku Cadang
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Kategori Produk
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Harga Beli
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Harga Jual
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Stok
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Gambar
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Supplier
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                { FilteredSukuCadang.length > 0 ? 
                FilteredSukuCadang.slice(pageclick, pageclick + perpage).map(
                  (sukuCadang, index) => (
			
                    <tr
                      key={sukuCadang.id_suku_cadang}
                      className={`${
    sukuCadang.stok < 3 ? "bg-[#FCE7D1]" : "odd:bg-white even:bg-[#F9F9F9]"
  } border-b`}
                    >
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4">{sukuCadang.tanggal}</td>
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {sukuCadang.nama_produk}
                      </td>
                      <td className="px-6 py-4">
                        {getKategoriName(sukuCadang.id_kategori)}
                      </td>
                      <td className="px-6 py-4">{formatCurrency(sukuCadang.harga_beli)}</td>
                      <td className="px-6 py-4">{formatCurrency(sukuCadang.harga_jual)}</td>
                      <td className="px-6 py-4">{sukuCadang.stok}</td>
                      <td className="px-6 py-4">
                        <img
                          src={`http://45.130.229.21/images/${sukuCadang.gambar}`}
                          alt="Gambar Produk"
                          className="w-16 h-16 cursor-pointer object-cover rounded-lg border-solid border border-gray-400"
                          onClick={() => handleImageClick(`http://45.130.229.21/images/${sukuCadang.gambar}`)}
                        />
                      </td>
                      <td className="px-6 py-4">
                        {getSupplierName(sukuCadang.id_supplier)}
                      </td>
                      <td className="px-6 py-4">
                        {getStatusName(sukuCadang.id_status_sc)}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex justify-center">
                          <Link
                            to={`/suku-cadang/edit/${sukuCadang.id_suku_cadang}`}
                            title="Edit"
                          >
                            <MdEdit size={20} className="text-[#F08519]" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="11" className="px-6 py-4 text-center text-gray-500">
                      Belum Ada Produk
                    </td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
        <div className="flex justify-end my-4 mb-4 mx-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white text-xs hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777] text-xs"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white text-xs"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white text-xs"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      {selectedImage && (
                <EnlargedImage imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

    </div>
  );
};

export default SukuCadang;
