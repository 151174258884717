import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { HiMiniUsers } from "react-icons/hi2";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js";

const AntrianBookingUser = () => {
  const [antrian, setAntrian] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      getAntrian();
    } else {
      console.log("ID is required to fetch antrian");
    }
  }, [id, navigate, currentUser]);

  const getAntrian = async () => {
    try {
      const response = await axios.get(
        `${url}/booking/user/${currentUser.id}`,
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setAntrian(response.data);
      }
    } catch (err) {
      console.error("Error Mengambil Data Booking:", err);
    }
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mx-2 md:mx-4 px-2 md:px-4 mt-6">
        <h1 className="text-black font-bold text-xl">Antrian Layanan</h1>
      </div>

      <div className="flex justify-center">
        <div className="my-6 mx-2 md:mx-4 overflow-x-auto w-[96%] relative border border-[#c8c8c8] rounded-lg bg-white py-4">
          {antrian && antrian.length > 0 ? (
            <div className="py-2 mx-4 uppercase font-black text-2xl">
              Status Booking Anda Hari Ini
            </div>
          ) : (
            <div className="py-2 mx-4 uppercase font-black text-2xl">
              Antrian Layanan Anda Hari Ini
            </div>
          )}
          {antrian && antrian.length > 0 ? (
            antrian.map((item, index) => (
              <div key={index}>
                {item.no_antrian === 1 && (
                  <div className="py-2 mx-4 text-2xl font-bold w-fit">
                    <h1 className="text-green-500 border-green-500 border shadow-md px-8 py-2 rounded-lg">
                      Diterima
                    </h1>
                  </div>
                )}
                {(item.no_antrian === 2 || item.no_antrian === null) && (
                  <div className="py-2 mx-4 text-2xl font-bold w-fit">
                    <h1 className="text-[#F08519] border-[#F08519] border shadow-md px-8 py-2 rounded-lg">
                      Menunggu
                    </h1>
                  </div>
                )}
                {item.no_antrian === 3 && (
                  <div className="py-2 mx-4 text-2xl font-bold w-fit">
                    <h1 className="text-[#E62129] border-[#E62129] border shadow-md px-8 py-2 rounded-lg">
                      Ditolak
                    </h1>
                  </div>
                )}
                <div className="flex justify-between items-center align-middle mx-4 text-xl font-bold">
                  {item.no_antrian && (
                    <div>
                      <div className="text-bold text-black">
                        No. Antrian {item.no_antrian}
                      </div>
                      <HiMiniUsers size={60} className="text-[#E62129]" />
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="py-2 mx-4 text-[#E62129] font-black text-xl items-center text-center">
              Anda Belum Melakukan Booking Service Hari Ini
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AntrianBookingUser;
