import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { url } from "../context/url.js";
import { AuthContext } from "../context/authcContext.js";
import { parseCurrency, handleChange } from "../context/formatCurrency.js";

const AddSukuCadang = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [fileProduk, setFileProduk] = useState();
  const [previewProduk, setPreviewProduk] = useState(null);
  const [NamaProduk, setNamaProduk] = useState("");
  const [Kategori, setKategori] = useState([]);
  const [KategoriProduk, setKategoriProduk] = useState("");
  const [AddKategori, setAddKategori] = useState("");
  const [showAddKategoriInput, setShowAddKategoriInput] = useState(false);
  const [HargaBeli, setHargaBeli] = useState("");
  const [HargaJual, setHargaJual] = useState("");
  const [Stok, setStok] = useState("");
  const [Supplier, setSupplier] = useState("");
  const [GetSupplier, setGetSupplier] = useState([]);
  const [Tanggal, setTanggal] = useState("");
  const [Status, setStatus] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getKategori();
    getSupplier();
  }, []);

  const handleUploadProduk = (e) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFileProduk(selectedFile);
      setPreviewProduk(URL.createObjectURL(selectedFile));
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const formDataProduk = new FormData();
      formDataProduk.append("gambar", fileProduk);
      formDataProduk.append("id", currentUser.id); // Tambahkan id ke formData
  
      // Upload gambar
      const responseProduk = await axios.post(
        `${url}/upgambar`,
        formDataProduk,
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      const produkUrl = responseProduk.data;
  
      //requestData
      const requestData = {
        gambar: produkUrl,
        nama_produk: NamaProduk,
        id_kategori: KategoriProduk,
        harga_beli: parseCurrency(HargaBeli),
        harga_jual: parseCurrency(HargaJual),
        stok: Stok,
        id_supplier: Supplier,
        id_status: Status ? "1" : "2",
        tanggal: Tanggal,
      };
  
      // Kirim data
      await axios.post(`${url}/skc`, requestData, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      navigate("/suku-cadang");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 400) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };
  
  const getKategori = async () => {
    try {
      const response = await axios.get(`${url}/supplier`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setGetSupplier(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getSupplier = async () => {
    try {
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setKategori(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const saveKategori = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/kategoriSC`,
        {
          kategori: AddKategori,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      setShowAddKategoriInput(false);
      setAddKategori("");
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setKategori(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const closePopup = () => {
    setShowAddKategoriInput(false);
  };

  return (
    <div>
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Produk Suku Cadang</p>
        </div>
      </div>
      <div className="mx-6 my-4">
        <div className="title mt-6 mx-4">
          <h1 className="text-black font-bold text-lg">Tambah Suku Cadang</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white">
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>

            <div className="flex justify-center">
              <div className="overflow-x-auto w-full relative">
                <div className="mx-auto grid md:grid-cols-2 gap-8 justify-center">
                  <div>
                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Tanggal<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Tanggal}
                          required
                          type="date"
                          onChange={(e) => setTanggal(e.target.value)}
                          placeholder="Tanggal Tambah atau Update Produk"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Nama Produk<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={NamaProduk}
                          required
                          onChange={(e) => setNamaProduk(e.target.value)}
                          placeholder="Nama Produk"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Kategori Produk<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2 flex justify-between items-center gap-2">
                        <div className="w-3/4">
                          <select
                            name="id_kategori"
                            className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                            value={KategoriProduk}
                            required
                            onChange={(e) => setKategoriProduk(e.target.value)}
                            placeholder="Kategori Produk"
                          >
                            <option value="" disabled>
                              Pilih Kategori
                            </option>
                            {Kategori.map((kategori) => (
                              <option
                                key={kategori.id_kategori}
                                value={kategori.id_kategori}
                              >
                                {kategori.kategori}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="w-1/4">
                          <button
                            className="bg-[#F08519] py-3 text-xs text-center w-full rounded-lg text-white"
                            onClick={() => setShowAddKategoriInput(true)}
                          >
                            Tambah
                          </button>
                        </div>
                      </div>
                    </div>

                    {showAddKategoriInput && (
                      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%] max-w-md">
                          <button
                            className="absolute top-2 right-2 text-black hover:text-gray-800"
                            onClick={closePopup}
                          >
                            <AiOutlineClose className="text-xl" />
                          </button>
                          <label className="block text-xs font-medium mb-2">
                            Tambah Kategori Baru
                          </label>
                          <input
                            type="text"
                            className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400 mb-4"
                            value={AddKategori}
                            onChange={(e) => setAddKategori(e.target.value)}
                            placeholder="Kategori Baru"
                          />
                          <button
                            className="bg-[#F08519] py-3 text-xs text-center w-full rounded-lg text-white"
                            onClick={saveKategori}
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Gambar Produk
                      </label>
                      <div className="py-2">
                        <input
                          type="file"
                          required
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          onChange={handleUploadProduk}
                        />
                        {previewProduk && (
                          <div className="mt-2">
                            <img
                              src={previewProduk}
                              alt="Preview Produk"
                              className="h-32 w-32 object-cover rounded-lg"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Harga Beli<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={HargaBeli}
                          required
                          onChange={handleChange(setHargaBeli)}
                          placeholder="Harga Beli"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Harga Jual<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={HargaJual}
                          required
                          onChange={handleChange(setHargaJual)}
                          placeholder="Harga Jual"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Stok<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="number"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Stok}
                          required
                          onChange={(e) => setStok(e.target.value)}
                          placeholder="Stok"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Supplier<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <select
                          name="id_supplier"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Supplier}
                          required
                          onChange={(e) => setSupplier(e.target.value)}
                          placeholder="Supplier"
                        >
                          <option value="" disabled>
                            Pilih Supplier
                          </option>
                          {GetSupplier.map((supplier) => (
                            <option
                              key={supplier.id_supplier}
                              value={supplier.id_supplier}
                            >
                              {supplier.nama}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Status<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <select
                          name="id_status"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Status}
                          required
                          onChange={(e) => setStatus(e.target.value === "true")}
                          placeholder="Status"
                        >
                          <option value="" disabled>
                            Pilih Status
                          </option>
                          <option value="true">Aktif</option>
                          <option value="false">Non-Aktif</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex justify-end mx-4 my-4">
                      <button
                        className="bg-[#F08519] py-3 text-xs text-center w-32 rounded-lg text-white"
                        onClick={handleClick}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSukuCadang;
