import React, { useEffect } from "react";
import { AuthContext } from "../context/authcContext.js";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Img1 from "../img/dash-booking.png";
import Img2 from "../img/dash-antrian.png";
import Img3 from "../img/dash-klaim.png";
import Img4 from "../img/dash-katalog.png";

const DashboardEx = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  
  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    } 
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");

      const popup = document.createElement("div");
      popup.textContent = "Mohon login ke akun Anda!";
      popup.className =
        "fixed text-lg text-[#E62129] top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FFF2F2] border border-[#E62129] z-10 p-4 w-[90%] rounded";
      document.body.appendChild(popup);

      setTimeout(() => {
        document.body.removeChild(popup);
      }, 1500);
    }
  }, [currentUser, navigate]);

  const riwayat = () => {
    navigate(`/riwayat-aktivitas/${currentUser.id}`);
  }

  return (
    <div>
      <div className="w-full py-8 px-4">
        <div className="flex justify-end">
        <p onClick={riwayat} className="text-[#E62129] hover:text-[#EB4D54] cursor-pointer text-base font-bold">
          Lihat Riwayat Aktivitas
        </p>
        </div>
        <h1 className="text-black font-bold text-3xl px-6 py-2 text-center">
          Dashboard
        </h1>
        <div className="flex justify-between">
        <p className="text-black text-2xl py-2">
          Welcome Back,{" "}
          <span className="text-black font-semibold">
            {currentUser.nama_lengkap}
          </span>
        </p>
        </div>
        <div className="pt-2 gap-4">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 my-4 gap-8 justify-center items-center">
            
            <div>
              <Link to={`/booking/${currentUser.id}`}>
              <div
                style={{ "--image-url": `url(${Img1})` }}
                className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start min-h-[18rem] min-w-[18rem] sm:min-h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
                >
                <h2 className="text-2xl font-bold text-white p-2">
                  Booking Service
                </h2>
              </div>
                </Link>
            </div>

            <div>
            <Link to={`/antrian/${currentUser.id}`}>
              <div
                style={{ "--image-url": `url(${Img2})` }}
                className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start min-h-[18rem] min-w-[18rem] sm:min-h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
                >
                <h2 className="text-2xl font-bold text-white p-2">
                  Antrian Layanan
                </h2>
              </div>
                </Link>
            </div>
          </div>
          
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 my-4 gap-8 justify-center items-center">
            
            <div>
              <Link to={`/klaim-garansi/${currentUser.id}`}>
              <div
                style={{ "--image-url": `url(${Img3})` }}
                className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start min-h-[18rem] min-w-[18rem] sm:min-h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
                >
                <h2 className="text-2xl font-bold text-white p-2">
                  Klaim Garansi
                </h2>
              </div>
                </Link>
            </div>

            <div>
            <Link to={`/katalog`}>
              <div
                style={{ "--image-url": `url(${Img4})` }}
                className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start min-h-[18rem] min-w-[18rem] sm:min-h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
                >
                <h2 className="text-2xl font-bold text-white p-2">
                  Katalog Sparepart
                </h2>
              </div>
                </Link>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default DashboardEx;