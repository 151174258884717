import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../context/url.js"

const EditSupplier = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [Nama, setNama] = useState("");
  const [Email, setEmail] = useState("");
  const [Alamat, setAlamat] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const getSupplierById = async () => {
      try {
        const response = await axios.get(`${url}/supplier/${id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setNama(response.data.nama);
        setEmail(response.data.email);
        setAlamat(response.data.alamat);
        setNo_Telepon(response.data.no_telepon);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getSupplierById();
  }, [id]);

  const updateSupplier = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/supplier/${id}`,
        {
          nama: Nama,
          email: Email,
          alamat: Alamat,
          no_telepon: No_Telepon,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/supplier");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div>
        <div className="title mb-6">
          <div className="bg-white p-6 shadow-b-lg">
            <h1 className="text-black font-bold text-md">Data Supplier</h1>
          </div>
        </div>

        <div className="mx-2 md:mx-4">
          <h1 className="text-black font-bold text-xl">Edit Supplier</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
            <form onSubmit={updateSupplier}>
              <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
                <p
                  className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
                >
                  {error}
                </p>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Nama<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Nama}
                    onChange={(e) => setNama(e.target.value)}
                    placeholder="Nama Supplier"
                  />
                </div>
              </div>

              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Email<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="email"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Aktif Supplier"
                  />
                </div>
              </div>

              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">Alamat</label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Alamat Supplier"
                  />
                </div>
              </div>

              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  No. Telepon<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={No_Telepon}
                    onChange={(e) => setNo_Telepon(e.target.value)}
                    placeholder="08***********"
                    pattern="[0-9]*"
                    title="Nomor telepon hanya boleh terdiri dari angka."
                  />
                </div>
              </div>

              <div className="py-2 mx-4">
                <div className="py-2 flex justify-center">
                  <button
                    type="submit"
                    className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSupplier;
