import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcContext.js";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [inputs, setInputs] = useState({
    identifier: "",
    password: "",
  });
  const [err, setError] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();
  const { login, currentUser } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      setError("Mohon Selesaikan CAPTCHA!");
      return;
    }

    try {
      await login({ ...inputs, captcha: captchaValue });
    } catch (err) {
      setError(err.response?.data || "Terjadi kesalahan saat login.");
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === 1 || currentUser.role === 2 || currentUser.role === 3) {
        navigate("/dashboardinter");
      } else if (currentUser.role === 4) {
        navigate("/dashboard");
      }
    }
  }, [currentUser, navigate]);

  return (
    <div className="mx-auto py-36">
      <h1 className="font-extrabold py-6 text-4xl text-center">LOGIN</h1>
      <div className="my-4 items-center flex flex-col">
        <div className="w-[75%] md:w-[50%]">
          {err && (
            <p className="text-[#E62129] text-sm font-medium bg-[#FCE7D1] p-4 border-none rounded-lg w-full">
              {err}
            </p>
          )}
          <div className="loginContainer">
            <form onSubmit={handleSubmit}>
              <div className="py-2">
                <label className="label py-8 pb-3">
                  Username atau No. Telepon{" "}
                  <sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    placeholder="Username atau Nomor Aktif yang Terdaftar"
                    name="identifier"
                    onChange={handleChange}
                    required
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  />
                </div>
              </div>

              <div className="py-2">
                <label className="label py-8 pb-3">
                  Password<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    required
                  />
                </div>
              </div>

              <div className="py-2">
                <ReCAPTCHA
                  sitekey="6Lc4XjYqAAAAAEulb9hlTOWtj7CfPIgpXSpA7OVJ" // Replace with your site key
                  onChange={handleCaptchaChange}
                />
              </div>

              <div className="flex justify-end">
                <Link to="/forgot-password">
                  <p className="py-8 pt-3 text-[#E62129] font-bold cursor-pointer">
                    Lupa Password ?
                  </p>
                </Link>
              </div>
              <button
                type="submit"
                className="bg-[#E62129] font-bold text-white p-3 border-none rounded-lg w-full"
              >
                Login
              </button>
            </form>
          </div>
          <p className="py-6">
            Belum Punya Akun ?{" "}
            <Link to="/register">
              <span className="text-[#E62129] font-bold cursor-pointer">
                Klik Disini
              </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
