import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js"

const Garansi = () => {
  const navigate = useNavigate();
  const [garansi, setGaransi] = useState([]);
  const [garansiData, setGaransiData] = useState([]);
  const [idNota, setIdNota] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [idStatus, setIdStatus] = useState("");
  const [Status, setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedGaransiId, setSelectedGaransiId] = useState(null);
  const [error, setError] = useState("");
  const [FilteredGaransi, setFilteredGaransi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredGaransi.length / perpage);

  useEffect(() => {
    getGaransi();
    getStatus();
  }, []);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    filterGaransi();
  }, [garansi, Status, searchTerm]);

  const getStatusName = (id_status) => {
    const status = Status.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const filterGaransi = () => {
    const filtered = garansi.filter((garansi) => {
      const statusName = getStatusName(garansi.id_status).toLowerCase();
      return (
        garansi.nama_lengkap.toLowerCase().includes(searchTerm) ||
        garansi.deskripsi.toLowerCase().includes(searchTerm) ||
        garansi.id_nota.toString().toLowerCase().includes(searchTerm) ||
        statusName.includes(searchTerm)
      );
    });
    setFilteredGaransi(filtered);
  };

  const getGaransi = async () => {
    try {
      const response = await axios.get(`${url}/garansi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setGaransi(response.data);
      }
    } catch (error) {
      setError(error.response.data || "An error occurred." );
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(`${url}/status/garansi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatus(response.data);
      }
    } catch (error) {
      setError(error.response.data || "An error occurred." );
    }
  };

  const openModal = (garansi) => {
    setSelectedGaransiId(garansi.id_garansi);
    setIdNota(garansi.id_nota);
    setDeskripsi(garansi.deskripsi);
    setIdStatus(garansi.id_status);
    setShowModal(true);
  };

  const getGaransiById = async () => {
    try {
      const response = await axios.get(`${url}/garansi/${selectedGaransiId}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        return response.data; // Return the fetched data
      }
    } catch (error) {
      setError(error.response.data || "An error occurred.");
    }
  };
  

  const updateGaransi = async (e) => {
    e.preventDefault();
    try {
      // Perform the update
      await axios.patch(
        `${url}/garansi/${selectedGaransiId}`,
        {
          id_nota: idNota,
          deskripsi: deskripsi,
          id_status: idStatus,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
  
      // Close modal
      setShowModal(false);
  
      // Fetch the updated garansi data
      const updatedGaransi = await getGaransiById();
      if (!updatedGaransi) {
        console.error("Failed to fetch updated garansi data.");
        return;
      }
  
      console.log(updatedGaransi.id_garansi)  
      // Extract id_transaksi_garansi from updatedGaransi
      const idTransaksiGaransi = updatedGaransi.id_transaksi_garansi;
  
      // Navigate if id_transaksi_garansi is present
      if (idTransaksiGaransi) {
        navigate(`/transaksi/garansi/add/${idTransaksiGaransi}`);
      } else {
        console.error("id_transaksi_garansi not found in updated garansi data.");
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };
  
  
  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Data Garansi</p>
        </div>
      </div>
      <div className="flex mx-2 md:mx-6 p2-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative align-middle items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perpage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari"
            className="lg:w-[25%] w-[50%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
        <div className="text-xs pt-6">
          <Link
            to="/garansi/add"
            className="button bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
          >
            Tambah
          </Link>
        </div>
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b items-center">
                <tr className="items-center text-center">
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nomor Nota
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Deskripsi Keluhan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredGaransi.length > 0 ? 
                FilteredGaransi.slice(pageclick, pageclick + perpage).map(
                  (garansi, index) => (
                    <tr
                      key={garansi.id_garansi}
                      className="odd:bg-white even:bg-[#F9F9F9] border-b"
                    >
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {garansi.nama_lengkap}
                      </td>
                      <td className="px-6 py-4">{garansi.id_nota}</td>
                      <td className="px-6 py-4">{garansi.deskripsi}</td>
                      <td
                        className="px-6 py-4 cursor-pointer"
                        onClick={() => openModal(garansi)}
                      >
                        {garansi.id_status === 1 && (
                          <div className="flex justify-center bg-[#2AB74A] p-2 text-xs rounded-lg text-white">
                            <h1 className="text-xs">
                              {getStatusName(garansi.id_status)}
                            </h1>
                          </div>
                        )}
                        {garansi.id_status === 2 && (
                          <div className="flex justify-center bg-[#C8C8C8] p-2 text-xs rounded-lg text-black">
                            <h1 className="text-xs">
                              {getStatusName(garansi.id_status)}
                            </h1>
                          </div>
                        )}
                        {garansi.id_status === 3 && (
                          <div className="flex justify-center bg-[#E62129] p-2 text-xs rounded-lg text-white">
                            <h1 className="text-xs">
                              {getStatusName(garansi.id_status)}
                            </h1>
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                ): (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                      Belum Ada Klaim Garansi
                    </td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end mt-4 mx-6 text-xs">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>

        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg md:w-1/3 w-2/3">
              <div className="flex justify-between align-middle border-b-slate-400 border-b mb-4 p-3">
                <h1 className="text-sm font-bold">
                  Perbaharui Detail Status Klaim Garansi
                </h1>
                <AiOutlineClose
                  size={25}
                  onClick={() => setShowModal(false)}
                  className="cursor-pointer"
                />
              </div>
              <form onSubmit={updateGaransi}>
                <div className="px-3">
                  <div className={`py-2 ${error ? "block" : "hidden"}`}>
                    <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                      {error}
                    </p>
                  </div>
                  <div className="py-2">
                    <div className="py-2 flex flex-col sm:flex-row justify-between gap-2">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="2"
                          checked={idStatus === 2}
                          onChange={(e) => setIdStatus(2)}
                        />
                        <span className="ml-2 flex justify-center bg-[#C8C8C8] p-2 text-xs rounded-lg text-black">
                          Menunggu
                        </span>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="1"
                          checked={idStatus === 1}
                          onChange={(e) => setIdStatus(1)}
                        />
                        <span className="ml-2 flex justify-center bg-[#2AB74A] p-2 text-xs rounded-lg text-white">
                          Diterima
                        </span>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="3"
                          checked={idStatus === 3}
                          onChange={(e) => setIdStatus(3)}
                        />
                        <span className="ml-2 flex justify-center bg-[#E62129] p-2 text-xs rounded-lg text-white">
                          Ditolak
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <div className="py-2 flex justify-center">
                    <button
                      type="submit"
                      className="button bg-[#F08519] lg:w-[30%] w-[50%] font-bold text-white text-xs p-3 border-none rounded-lg"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Garansi;
