import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js"; 

const DetailManajemenSD = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [Tanggal, setTanggal] = useState("");
  const [Bensin, setBensin] = useState("");
  const [Listrik, setListrik] = useState("");
  const [SukuCadang, setSukuCadang] = useState("");
  const [Kebersihan, setKebersihan] = useState("");
  const [Gaji, setGaji] = useState("");
  const [Total, setTotal] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const calculateTotal = () => {
      const total =
        parseFloat(Kebersihan || 0) +
        parseFloat(Gaji || 0) +
        parseFloat(Bensin || 0) +
        parseFloat(Listrik || 0) +
        parseFloat(SukuCadang || 0);
      setTotal(total);
    };

    calculateTotal();
  }, [Bensin, Listrik, SukuCadang, Kebersihan, Gaji]);

  useEffect(() => {
    const getManajemenById = async () => {
      try {
        const response = await axios.get(`${url}/msd/${id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setTanggal(response.data.tanggal);
        setBensin(response.data.pembelian_bensin);
        setListrik(response.data.listrik);
        setSukuCadang(response.data.pembelian_suku_cadang);
        setKebersihan(response.data.kebersihan);
        setGaji(response.data.gaji);
        setTotal(response.data.total);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getManajemenById();
  }, [id]);

  const updateManajemen = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/msd/${id}`,
        {
          tanggal: Tanggal,
          pembelian_bensin: Bensin,
          listrik: Listrik,
          pembelian_suku_cadang: SukuCadang,
          kebersihan: Kebersihan,
          gaji: Gaji,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/sumber-daya");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div>
        <div className="title mb-6">
          <div className="bg-white p-6 shadow-b-lg">
            <h1 className="text-black font-bold text-md">
              Manajemen Sumber Daya
            </h1>
          </div>
        </div>

        <div className="mx-2 md:mx-4">
          <h1 className="text-black font-bold text-xl">Detail Sumber Daya</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
            <form onSubmit={updateManajemen}>
              <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
                <p
                  className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
                >
                  {error}
                </p>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Tanggal<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="date"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Tanggal}
                    readOnly
                    onChange={(e) => setTanggal(e.target.value)}
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Harga Pembelian Bensin<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(Bensin)}
                    readOnly
                    onChange={(e) => setBensin(e.target.value)}
                    placeholder="Harga Pembelian Bensin"
                    pattern="[0-9]*"
                    title="Hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">Harga Listrik</label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(Listrik)}
                    readOnly
                    onChange={(e) => setListrik(e.target.value)}
                    placeholder="Biaya Listrik"
                    pattern="[0-9]*"
                    title="Hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Harga Pembelian Suku Cadang
                  <sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(SukuCadang)}
                    readOnly
                    onChange={(e) => setSukuCadang(e.target.value)}
                    placeholder="Harga Pembelian Suku Cadang"
                    pattern="[0-9]*"
                    title="Hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Lain - lain
                  <sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(Kebersihan)}
                    readOnly
                    onChange={(e) => setKebersihan(e.target.value)}
                    placeholder="Harga Peralatan Kebersihan"
                    pattern="[0-9]*"
                    title="Hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Gaji<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(Gaji)}
                    readOnly
                    onChange={(e) => setGaji(e.target.value)}
                    placeholder="Total Gaji"
                    pattern="[0-9]*"
                    title="Hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Total<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={formatCurrency(Total)}
                    readOnly
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailManajemenSD;
