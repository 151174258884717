import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js";

const EditUser = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [Nama_Lengkap, setNama_Lengkap] = useState("");
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const [Alamat, setAlamat] = useState("");
  const [Role, setRole] = useState("");
  const [GetRole, setGetRole] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`${url}/user/${id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setNama_Lengkap(response.data.nama_lengkap);
        setUsername(response.data.username);
        setEmail(response.data.email);
        setNo_Telepon(response.data.no_telepon);
        setAlamat(response.data.alamat);
        setRole(response.data.id_role);
        //console.log(response.data);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setError(err.response.data.err || "An error occurred.");
        } else {
          setError("An error occurred.");
        }
      }
    };
    getUserById();
    getRole();
  }, [id]);

  const getRole = async () => {
    try {
      const response = await axios.get(
        `${url}/role`,
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setGetRole(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/user/${id}`,
        {
          username: Username,
          no_telepon: No_Telepon,
          password: Password,
          nama_lengkap: Nama_Lengkap,
          email: Email,
          alamat: Alamat,
          id_role: Role,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/user");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div>
        <div className="title mb-6">
          <div className="bg-white p-6 shadow-b-lg">
            <h1 className="text-black font-bold text-md">Data Pengguna</h1>
          </div>
        </div>

        <div className="mx-2 md:mx-4">
          <h1 className="text-black font-bold text-xl">Edit Pengguna</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
            <form onSubmit={updateUser}>
              <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
                <p
                  className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
                >
                  {error}
                </p>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Role<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <select
                  name="id_role"
                    value={Role}
                    onChange={(e) => setRole(e.target.value)}
                    className="text-xs p-3 pr-10 flex w-full rounded-lg border-solid border border-gray-400"
                  >
                    <option value="" disabled>
                            Pilih Role
                          </option>
                    {GetRole.map((role) => (
                      <option key={role.id_role} value={role.id_role}>
                        {role.role}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Nama<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    readOnly
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Nama_Lengkap}
                    onChange={(e) => setNama_Lengkap(e.target.value)}
                    placeholder="Nama Lengkap Anda"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Username<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    readOnly
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Email<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="email"
                    readOnly
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Aktif Anda"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  No. Telepon<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    readOnly
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={No_Telepon}
                    onChange={(e) => setNo_Telepon(e.target.value)}
                    placeholder="62************"
                    pattern="[0-9]*" // Hanya menerima angka
                    title="Nomor telepon hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">Alamat</label>
                <div className="py-2">
                  <input
                    type="text"
                    readOnly
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Alamat Anda"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <div className="py-2 flex justify-center">
                  <button
                    type="submit"
                    className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
