import React from "react";
import { Link } from "react-router-dom";
import img from "../img/page_not_found_404.png";

const PageNotFound = () => {
  return (
    <div className="mx-auto flex flex-col justify-center items-center min-h-screen align-middle gap-4">
      <img className="mx-auto p-2 w-1/2 lg:w-1/3" src={img} alt="Page Not Found" />
      <h1 className="text-xl md:text-3xl font-bold text-black text-center">
        Halaman Tidak Ditemukan
      </h1>
      <h2 className="text-md md:text-xl text-[#777777] text-center">
        Halaman yang anda cari tidak tersedia. Cek kembali url atau kembali ke
        halaman beranda.
      </h2>
      <Link to="/">
        <button className="bg-[#F08519] w-[200px] text-xs md:text-lg rounded-md font-medium p-2 mx-auto my-6 py-3 text-white">
          Kembali ke Home
        </button>
      </Link>
    </div>
  );
};

export default PageNotFound;