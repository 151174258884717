import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js";
import { BsFillPatchCheckFill } from "react-icons/bs";

const KlaimGaransi = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Menggunakan useLocation untuk mendapatkan query parameters
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [Nota, setNota] = useState("");  
  const [Deskripsi, setDeskripsi] = useState("");
  const [IDUser, setIDUser] = useState({ id_user: "", nama_lengkap: "" });
  const [showModal, setShowModal] = useState(false);  // Modal visibility state

  useEffect(() => {
    // Ambil query parameter id_nota dari URL
    const queryParams = new URLSearchParams(location.search);
    const id_nota = queryParams.get("id_nota");

    if (id_nota) {
      setNota(id_nota);  // Setel nilai Nota jika id_nota ada di URL
    }
  }, [location.search]);

  const saveGaransi = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/garansi/tambah`,
        {
          id_user: IDUser.id_user,
          id_nota: Nota,
          deskripsi: Deskripsi,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      setShowModal(true);  // Show modal on successful submission
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`${url}/profil/${currentUser.id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setIDUser(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    getUserById();
  }, [currentUser.id]);

  return (
    <div>
      <div className="mx-2 md:mx-4 px-2 md:px-4 mt-6">
        <h1 className="text-black font-bold text-xl">Form Pengajuan Klaim Garansi</h1>
      </div>

      <div className="flex justify-center">
        <div className="my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveGaransi}>
            {error && (
              <div className="py-2 mx-4">
                <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                  {error}
                </p>
              </div>
            )}
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={IDUser.nama_lengkap}
                  readOnly
                  required
                  placeholder="Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nota<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="number"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nota}
                  required
                  onChange={(e) => setNota(e.target.value)}
                  placeholder="Nomor Nota"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Deskripsi Kerusakan<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <textarea
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Deskripsi}
                  required
                  onChange={(e) => setDeskripsi(e.target.value)}
                  placeholder="Tulis Kerusakan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-md mx-4 sm:mx-6 lg:mx-8 my-6">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative mt-6 p-3 flex-auto flex justify-center">
                  <BsFillPatchCheckFill size={80} className='text-[#2AB74A]'/>
                </div>
                <div className="relative pt-3 flex-auto flex justify-center">
                  <h1 className='text-xl font-semibold'>Berhasil</h1>
                </div>
                <div className="relative px-6 flex-auto text-center">
                  <p className="my-4 text-[#777777] text-sm leading-relaxed">
                    Pengajuan Klaim Garansi Anda Berhasil Diajukan. Silahkan Menghubungi <a href="https://wa.me/628974229333" target="_blank" rel="noopener noreferrer" className="hover:text-gray-800 ">08974229333</a> untuk konfirmasi pengajuan klaim garansi. Terimakasih!
                  </p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="bg-[#E62129] relative p-6 flex-auto text-white hover:bg-[#E8373E] font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      navigate('/dashboard');  // Redirect to a different route upon closing modal
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
};

export default KlaimGaransi;

