import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js";

const Katalog = () => {
  const [sukuCadang, setSukuCadang] = useState([]);
  const [Kategori, setKategori] = useState([]);
  const [FilteredSukuCadang, setFilteredSukuCadang] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKategori, setSelectedKategori] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(15);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredSukuCadang.length / perpage);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    fetchSukuCadang();
    getKategori();
  }, []);

  useEffect(() => {
    filterSukuCadang();
  }, [Kategori, searchTerm, selectedKategori]);

  const fetchSukuCadang = async () => {
    try {
      const response = await axios.get(`${url}/katalog`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setSukuCadang(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getKategori = async () => {
    try {
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setKategori(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getKategoriName = (id_kategori) => {
    const kategori = Kategori.find(
      (kategori) => kategori.id_kategori === id_kategori
    );
    return kategori ? kategori.kategori : "Unknown";
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const handleKategoriChange = (e) => {
    setSelectedKategori(e.target.value);
    setPagenumber(0);
  };

  const filterSukuCadang = () => {
    const filtered = sukuCadang.filter((sukuCadang) => {
      const kategoriName = getKategoriName(
        sukuCadang.id_kategori
      ).toLowerCase();

      const matchesSearchTerm =
        sukuCadang.nama_produk.toLowerCase().includes(searchTerm) ||
        kategoriName.includes(searchTerm) 

      const matchesKategori =
        !selectedKategori ||
        sukuCadang.id_kategori === parseInt(selectedKategori);

      return matchesSearchTerm && matchesKategori;
    });
    setFilteredSukuCadang(filtered);
  };

  const handleCardClick = (sukuCadang) => {
    const message = `Permisi Pak, Saya izin Bertanya, untuk Suku Cadang ${sukuCadang.nama_produk} ini Harganya Berapa, dan Stoknya Tinggal Berapa ya Pak? Terima Kasih`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/628974229333?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mx-6 mb-4">Katalog Suku Cadang</h1>

      <div className="mx-6 my-4 gap-6">
        <div className="border border-gray-200 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
          <h1 className="text-2xl font-bold text-black">Cari Produk</h1>
          <div className="flex justify-between align-middle items-center my-2 gap-6 w-full">
            <div className="search">
              <div className="flex-grow relative flex justify-end w-full">
                <input
                  type="text"
                  placeholder="Cari"
                  className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <IoMdSearch size={15} className="text-[#777777]" />
                </span>
              </div>
            </div>

            <div className="kategori w-full">
              <select
                className="bg-[#F7F7F7] border border-gray-400 text-gray-400 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={selectedKategori}
                onChange={handleKategoriChange}
              >
                <option value="" className="text-gray-700">
                  Pilih Kategori Produk
                </option>
                {Kategori.map((kategori) => (
                  <option
                    key={kategori.id_kategori}
                    value={kategori.id_kategori}
                  >
                    {kategori.kategori}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex gap-2 mx-6 my-2 p2-4 mt-4 relative align-middle items-center">
              <p className="text-xs">Show</p>
              <select
                className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={perpage}
                onChange={handlePerPageChange}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <p className="text-xs">entries</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {FilteredSukuCadang.slice(pageclick, pageclick + perpage).map((item) => (
          <div
            key={item.id_suku_cadang}
            className="border rounded-lg p-4 shadow-md cursor-pointer"
            onClick={() => handleCardClick(item)}
          >
            <img
              src={`http://45.130.229.21/images/${item.gambar}`}
              alt={item.nama_produk}
              className="w-full h-48 object-cover mb-4 rounded-lg"
            />
            <h2 className="text-lg font-bold">{item.nama_produk}</h2>
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-4 mx-6">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={countpage}
          onPageChange={changePage}
          containerClassName={"flex items-center space-x-2"}
          pageClassName={
            "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
          }
          pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
          previousClassName={
            "px-3 py-1 border border-gray-300 rounded-md bg-white"
          }
          nextClassName={"px-3 py-1 border border-gray-300 rounded-md bg-white"}
          activeClassName={"bg-[#F08519] text-white"}
          disabledClassName={"text-gray-300 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default Katalog;
