import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../context/url.js";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    const [loginTime, setLoginTime] = useState(() => {
        // Retrieve and parse login time from localStorage, or return null if not set
        const savedLoginTime = localStorage.getItem("login_time");
        return savedLoginTime ? parseInt(savedLoginTime, 10) : null;
    });
    const navigate = useNavigate();

    const login = async (inputs) => {
        try {
            const res = await axios.post(`${url}/login`, inputs);
            setCurrentUser(res.data.other);
            localStorage.setItem("user", JSON.stringify(res.data.other));
            localStorage.setItem("access_token", res.data.access_token);

            // Record login time
            const currentTime = Date.now();
            setLoginTime(currentTime);
            localStorage.setItem("login_time", currentTime); // Save login time to localStorage
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    const logout = () => {
        console.log("Logging out...");
        setCurrentUser(null);
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("login_time"); // Clear login time from localStorage
        setLoginTime(null); // Clear login time state
        navigate("/", { replace: true });
    };

    useEffect(() => {
        if (currentUser) {
            const now = Date.now();
            const expiryTime = loginTime + 2 * 60 * 60 * 1000; // 2 jam dalam milidetik

            if (now >= expiryTime) {
                console.log("2 jam berlalu. Logging out...");
                logout();
                return;
            }

            // Set up a timeout to automatically logout after 2 jam
            const timeoutId = setTimeout(() => {
                console.log("2 jam berlalu. Logging out...");
                logout();
            }, expiryTime - now);

            // Cleanup timeout on component unmount or when dependencies change
            return () => clearTimeout(timeoutId);
        }
    }, [currentUser, loginTime, navigate, logout]);

    return (
        <AuthContext.Provider value={{ currentUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
