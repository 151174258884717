import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../context/url.js";
import { parseCurrency, handleChange, formatCurrency } from "../context/formatCurrency.js"; 
import { AuthContext } from "../context/authcContext.js";

const EditSukuCadang = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [fileProduk, setFileProduk] = useState(null);
  const [previewProduk, setPreviewProduk] = useState(null);
  const [imagesProduk, setImagesProduk] = useState([]);
  const [NamaProduk, setNamaProduk] = useState("");
  const [Kategori, setKategori] = useState([]);
  const [KategoriProduk, setKategoriProduk] = useState("");
  const [AddKategori, setAddKategori] = useState("");
  const [showAddKategoriInput, setShowAddKategoriInput] = useState(false);
  const [HargaBeli, setHargaBeli] = useState("");
  const [HargaJual, setHargaJual] = useState("");
  const [Stok, setStok] = useState("");
  const [Supplier, setSupplier] = useState("");
  const [GetSupplier, setGetSupplier] = useState([]);
  const [Tanggal, setTanggal] = useState("");
  const [Status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();

  const handleUploadProduk = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileProduk(file);
      setPreviewProduk(URL.createObjectURL(file));
    } else {
      setFileProduk(null);
      setPreviewProduk(null);
    }
  };

  useEffect(() => {
    const getSCById = async () => {
      try {
        const response = await axios.get(`${url}/skc/${id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setNamaProduk(response.data.nama_produk);
        setKategoriProduk(response.data.id_kategori);
        setHargaBeli(formatCurrency(response.data.harga_beli)); 
        setHargaJual(formatCurrency(response.data.harga_jual)); 
        setStok(response.data.stok);
        setSupplier(response.data.id_supplier);
        setStatus(response.data.id_status_sc);
  
        const date = new Date(response.data.tanggal);
        const formattedDate = date.toISOString().split("T")[0];
        setTanggal(formattedDate);
  
        setFileProduk(null); // Reset fileProduk to null
        setPreviewProduk(null);
  
        if (Array.isArray(response.data.gambar)) {
          setImagesProduk(response.data.gambar);
        } else {
          setImagesProduk([response.data.gambar]);
        }
      } catch (err) {
        setError(err.response.data || "An error occurred.");
      }
    };
    getSCById();
  }, [id]);
  

  useEffect(() => {
    getKategori();
    getSupplier();
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      let produkUrl = previewProduk; // Gambar lama
      // Jika ada gambar baru yang diupload
      if (fileProduk) {
        const formDataProduk = new FormData();
        formDataProduk.append("gambar", fileProduk);
        formDataProduk.append("id", currentUser.id); // Add the id to the formData
        
        try {



          // Upload gambar baru
          const responseProduk = await axios.post(`${url}/upgambar`, formDataProduk, {
               headers: { access_token: localStorage.getItem("access_token") },
          });
          produkUrl = responseProduk.data; // Gambar baru


//const responseProduk = await axios.post(`${url}/upgambar`, formDataProduk, {
  //  headers: { access_token: localStorage.getItem("access_token") },
  //});
  //console.log(responseProduk.data); // <--- Add this line
  //const produkUrl = responseProduk.data.filename;


    //const responseProduk = await axios.post(`${url}/upgambar`, formDataProduk, {
          //params: { id: currentUser.id }, // Send id as a request parameter
          //headers: { access_token: localStorage.getItem("access_token") },
        //});
      //const produkUrl = responseProduk.data;
//const produkUrl = responseProduk.data.filename;



	  //const responseProduk = await axios.post(`${url}/upgambar/${currentUser.id}`, formDataProduk, {
          //    headers: { access_token: localStorage.getItem("access_token") },
          //});
          //produkUrl = responseProduk.data; // Gambar baru
      } catch (uploadError) {
          if (uploadError.response && uploadError.response.status === 404) {
              setError("Tidak Bisa Mengupload Gambar");
          } else {
              setError(uploadError.response.data || "An error occurred.");
          }
          return; // Exit the function if image upload fails
      }
        // Upload gambar baru
        // const responseProduk = await axios.post(`${url}/upgambar/${currentUser.id}`, formDataProduk, {
        //   headers: { access_token: localStorage.getItem("access_token") },
        // });
        // produkUrl = responseProduk.data; // Gambar baru
      }

      const requestData = {
        gambar: produkUrl,
        nama_produk: NamaProduk,
        id_kategori: KategoriProduk,
        harga_beli: parseCurrency(HargaBeli),
        harga_jual: parseCurrency(HargaJual),
        stok: Stok,
        id_supplier: Supplier,
        tanggal: Tanggal,
        id_status_sc: Status ? "1" : "2",
      };

      await axios.patch(`${url}/skc/${id}`, requestData, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      navigate("/suku-cadang");
    } catch (err) {
      setError(err.response.data || "An error occurred.");
    }
  };

  const getKategori = async () => {
    try {
      const response = await axios.get(`${url}/supplier`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setGetSupplier(response.data);
    } catch (err) {
      setError(err.response.data || "An error occurred.");
    }
  };

  const getSupplier = async () => {
    try {
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setKategori(response.data);
    } catch (err) {
      setError(err.response.data || "An error occurred.");
    }
  };

  const saveKategori = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/kategoriSC`,
        {
          kategori: AddKategori,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      setShowAddKategoriInput(false);
      setAddKategori("");
      const response = await axios.get(`${url}/kategoriSC`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setKategori(response.data);
    } catch (err) {
      setError(err.response.data || "An error occurred.");
    }
  };

  return (
    <div>
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Produk Suku Cadang</p>
        </div>
      </div>

      <div className="mx-6 my-8">
        <div className="title mt-6 mx-4">
          <h1 className="text-black font-bold text-lg">Edit Suku Cadang</h1>
        </div>

        <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
          <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
            {error}
          </p>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white">
            <div className="flex justify-center">
              <div className="overflow-x-auto w-full relative py-4">
                <div className="mx-auto grid md:grid-cols-2 gap-8 justify-center">
                  <div>
                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Tanggal<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Tanggal}
                          type="date"
                          onChange={(e) => setTanggal(e.target.value)}
                          placeholder="Tanggal Tambah atau Update Produk"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Nama Produk<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={NamaProduk}
                          onChange={(e) => setNamaProduk(e.target.value)}
                          placeholder="Nama Produk"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Kategori Produk<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2 flex justify-between items-center gap-2">
                        <div className="w-3/4">
                          <select
                            name="id_kategori"
                            className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                            value={KategoriProduk}
                            onChange={(e) => setKategoriProduk(e.target.value)}
                            placeholder="Kategori Produk"
                          >
                            <option value="" disabled>
                              Pilih Kategori
                            </option>
                            {Kategori.map((kategori) => (
                              <option
                                key={kategori.id_kategori}
                                value={kategori.id_kategori}
                              >
                                {kategori.kategori}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="w-1/4">
                          <button
                            className="bg-[#F08519] py-3 text-xs text-center w-full rounded-lg text-white"
                            onClick={() => setShowAddKategoriInput(true)}
                          >
                            Tambah
                          </button>
                        </div>
                      </div>
                    </div>

                    {showAddKategoriInput && (
                      <div className="py-2 mx-4">
                        <label className="label py-8 pb-3 text-xs">
                          Nama Kategori Baru
                          <sup className="text-[#E62129]">*</sup>
                        </label>
                        <div className="py-2 flex gap-2">
                          <input
                            type="text"
                            className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                            value={AddKategori}
                            onChange={(e) => setAddKategori(e.target.value)}
                            placeholder="Nama Kategori Baru"
                          />
                          <button
                            className="bg-[#F08519] px-2 py-3 text-xs text-center rounded-lg font-bold text-white"
                            onClick={saveKategori}
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    )}

<div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Harga Beli<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={HargaBeli}                          
                          required
                          onChange={handleChange(setHargaBeli)}
                          placeholder="Harga Beli"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Harga Jual<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={HargaJual}                          
                          required
                          onChange={handleChange(setHargaJual)}
                          placeholder="Harga Jual"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Stok<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="number"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Stok}
                          onChange={(e) => setStok(e.target.value)}
                          placeholder="Stok Produk"
                        />
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <label className="label py-8 pb-3 text-xs">
                        Supplier<sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <select
                          name="id_supplier"
                          type="text"
                          className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                          value={Supplier}
                          onChange={(e) => setSupplier(e.target.value)}
                          placeholder="Stok Produk"
                        >
                          <option value="" disabled>
                            Pilih Supplier
                          </option>
                          {GetSupplier.map((supplier) => (
                            <option
                              key={supplier.id_supplier}
                              value={supplier.id_supplier}
                            >
                              {supplier.nama}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="py-2 mx-4">
                      <div className="flex gap-2 align-middle items-center">
                        <span className="text-xs font-medium text-gray-900">
                          Produk Aktif
                        </span>
                        <label className="inline-flex relative items-center mr-5 cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={Status}
                            value={Status ? 1 : 2}
                          />
                          <div
                            onClick={() => {
                              setStatus(!Status);
                            }}
                            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#2AB74A]"
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <form encType="multipart/form-data">
                    <div className="py-2 mx-4">
                      <label className="label label py-8 pb-3 text-xs">
                        Pilih Foto Produk
                        <sup className="text-[#E62129]">*</sup>
                      </label>
                      <div className="py-2">
                        <input
                          type="file"
                          name="produk"
                          className="text-xs p-3 pr-10 flex w-full rounded-lg border-solid border border-gray-400"
                          onChange={handleUploadProduk}
                        />
                      </div>
                      {previewProduk && (
                        <div className="py-2">
                          <label className="label py-8 pb-3 text-xs">
                            Preview Gambar Produk
                          </label>
                          <img
                            className="w-full max-w-xs h-auto rounded-lg"
                            src={previewProduk}
                            alt="Preview Produk"
                          />
                        </div>
                      )}
                    </div>

                    <div className="py-2 pb-3 mx-4">
                      <p className="label text-xs">Gambar Produk Saat Ini</p>
                      <div className="py-2">
                        {imagesProduk.map((imageproduk, index) => (
                          <div key={index} className="cursor-pointer">
                            <img
                              src={`http://45.130.229.21/images/${imageproduk}`}
                              alt="Produk"
                              className="w-48 object-cover rounded-lg border-solid border border-gray-400"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </form>
                </div>

                <div className="py-2 mx-4">
                  <div className="py-2 flex justify-center">
                    <button
                      className="bg-[#F08519] w-full rounded-lg text-sm font-bold py-2 text-white"
                      onClick={handleClick}
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSukuCadang;
