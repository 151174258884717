import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authcContext.js';
import logo from '../img/logo_crazy_fast.png';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const [nav, setNav] = useState(false);
  const navRef = useRef(null);

  // Toggle navigation state
  const handleNav = () => {
    setNav(!nav);
  };

  // Navigate to a path and handle login popup if needed
  const handleNavigation = (path) => {
    if (currentUser) {
      navigate(path);
      if (nav) setNav(false); // Close the menu after navigation
    } else {
      navigate("/login");

      const popup = document.createElement("div");
      popup.textContent = "Mohon login ke akun Anda!";
      popup.className =
        "fixed text-lg text-[#E62129] top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FFF2F2] border border-[#E62129] z-10 p-4 w-[90%] rounded";
      document.body.appendChild(popup);

      setTimeout(() => {
        document.body.removeChild(popup);
      }, 1500);
    }
  };

  // Handle clicks outside of the navigation menu to close it
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNav(false);
    }
  };

  // Add event listener on component mount and cleanup on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className='header'>
        <div className='flex justify-between text-center items-center h-24 w-full mx-auto px-4 text-zinc-950 bg-white shadow-lg'>
          <img onClick={() => navigate("/")} src={logo} alt='Logo' className='h-20 cursor-pointer' />
          <ul className='hidden md:flex items-center md:max-w-screen-xl'>
            <li onClick={() => navigate("/")} className='p-2 text-lg font-bold cursor-pointer'>Home</li>
            <li onClick={() => handleNavigation(`/booking/${currentUser?.id}`)} className='p-2 text-lg font-bold cursor-pointer'>Booking Service</li>
            <li onClick={() => handleNavigation(`/antrian/${currentUser?.id}`)} className='p-2 text-lg font-bold cursor-pointer'>Antrian Layanan</li>
            <li onClick={() => handleNavigation(`/klaim-garansi/${currentUser?.id}`)} className='p-2 text-lg font-bold cursor-pointer'>Klaim Garansi</li>
            <li onClick={() => navigate("/katalog")} className='p-2 text-lg font-bold cursor-pointer'>Katalog Sparepart</li>
            {currentUser && (
              <div className=' flex items-center'>
                <FaUserCircle onClick={() => handleNavigation(`/profil/${currentUser.id}`)} size={40} className='text-[#E62129] cursor-pointer' />
              </div>
            )}
            {currentUser && currentUser.role === 4 && (
              <li className='p-2'>
                <button
                  onClick={() => navigate("/dashboard")}
                  className='bg-[#F08519] w-[120px] min-h-[40px] rounded-lg text-lg font-bold p-2 text-white'
                >
                  Dashboard
                </button>
              </li>
            )}
            {currentUser && (currentUser.role === 1 || currentUser.role === 2 || currentUser.role === 3) && (
              <li className='p-2'>
                <button
                  onClick={() => navigate("/dashboardinter")}
                  className='bg-[#F08519] w-[120px] min-h-[40px] rounded-lg text-lg font-bold p-2 text-white'
                >
                  Dashboard Internal
                </button>
              </li>
            )}
            {!currentUser ? (
              <li className='p-2'>
                <button
                  onClick={() => navigate("/login")}
                  className='bg-[#E62129] w-[100px] min-h-[40px] rounded-lg text-lg font-bold p-2 text-white'
                >
                  Login
                </button>
              </li>
            ) : (
              <li className='p-2'>
                <button
                  onClick={logout}
                  className='bg-[#E62129] w-[100px] min-h-[40px] rounded-lg text-lg font-bold p-2 text-white'
                >
                  Logout
                </button>
              </li>
            )}
          </ul>
          <div onClick={handleNav} className='block md:hidden cursor-pointer'>
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
          <div
            ref={navRef}
            className={`fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-300 items-center justify-center bg-white ease-in-out duration-500 z-50 ${
              nav ? 'translate-x-0' : 'translate-x-[-100%]'
            }`}
          >
            <img src={logo} alt='Logo' className='h-24 m-4 justify-center items-center' />
            <ul className='uppercase p-4'>
              <li onClick={() => navigate("/")} className='p-2 border-b border-gray-300 cursor-pointer'>Home</li>
              <li onClick={() => handleNavigation(`/booking/${currentUser?.id}`)} className='p-2 border-b border-gray-300 cursor-pointer'>Booking Service</li>
              <li onClick={() => handleNavigation(`/antrian/${currentUser?.id}`)} className='p-2 border-b border-gray-300 cursor-pointer'>Antrian Layanan</li>
              <li onClick={() => handleNavigation(`/klaim-garansi/${currentUser?.id}`)} className='p-2 border-b border-gray-300 cursor-pointer'>Klaim Garansi</li>
              <li onClick={() => navigate("/katalog")} className='p-2 border-b border-gray-300 cursor-pointer'>Katalog Sparepart</li>
              {currentUser && (
                <li onClick={() => handleNavigation(`/profil/${currentUser.id}`)} className='p-2 border-b border-gray-300 cursor-pointer'>Profil</li>
              )}
              {currentUser && currentUser.role === 4 && (
                <li onClick={() => navigate("/dashboard")} className='p-2 border-b border-gray-300 cursor-pointer'>Dashboard</li>
              )}
              {currentUser && (currentUser.role === 1 || currentUser.role === 2 || currentUser.role === 3) && (
                <li onClick={() => navigate("/dashboardinter")} className='p-2 border-b border-gray-300 cursor-pointer'>Dashboard Internal</li>
              )}
              {!currentUser ? (
                <li onClick={() => navigate("/login")} className='p-2 border-b border-gray-300 cursor-pointer'>Login</li>
              ) : (
                <li onClick={logout} className='p-2 border-b border-gray-300 cursor-pointer'>Logout</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
