import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js";

const DetailLaporanKeuangan = () => {
  const location = useLocation();
  const { tanggal, idMSD } = location.state;
  const [rincian, setRincian] = useState([]);

  //PRINT PDF
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Laporan-Keuangan",
    onAfterint: () => alert("Data Berhasil di Download"),
  });

  useEffect(() => {
    const getDetailData = async () => {
      try {
        const response = await axios.post(
          `${url}/rincian_keuangan`,
          {
            id_msd: idMSD,
            tanggal: tanggal,
          },
          {
            headers: { access_token: localStorage.getItem("access_token") },
          }
        );

        setRincian(response.data);
      } catch (err) {
        console.error(err)
      }
    };

    getDetailData();
  }, [tanggal, idMSD]);

  if (!rincian) return <div>Loading...</div>;

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Laporan Keuangan</p>
        </div>
      </div>
      <div className="w-full bg-[#F7F7F7]">
        <header className="m-4">
          <div>
            <ul>
              <div className="">
                <button
                  className="bg-[#F08519] rounded-md font-medium p-2 mx-auto py-3 text-white text-sm"
                  onClick={generatePDF}
                >
                  Print PDF
                </button>
              </div>
            </ul>
          </div>
        </header>

        <div className="flex justify-center my-4 " ref={componentPDF}>
          <div className="mx-4 m-2 overflow-x-auto w-full relative shadow-md rounded-lg">
            <div className="bg-white mx-auto p-4">
            <h2 className="font-bold text-xl text-center">CrazyFast SpeedShop</h2>
            <h1 className="font-bold text-2xl text-center">Laporan Pemasukan dan Pengeluaran</h1>

            </div>
            <table class="min-w-full bg-white">
              {rincian.map((rincian, index) => (
                <>
                  <thead>
                    <tr class=" text-center">
                      <th class="border border-gray-300 px-4 py-2" rowSpan="2">
                        No
                      </th>
                      <th class="border border-gray-300 px-4 py-2" rowSpan="2">
                        KETERANGAN
                      </th>
                      <th class="border border-gray-300 px-4 py-2" colSpan="2">
                        JENIS
                      </th>
                    </tr>
                    <tr class="  text-center">
                      <th class="border border-gray-300 px-4 py-2">
                        PEMASUKAN
                      </th>
                      <th class="border border-gray-300 px-4 py-2">
                        PENGELUARAN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">1</td>
                      <td class="border border-gray-300 px-4 py-2">Service</td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.total_sv)}
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">2</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Suku cadang
                      </td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.total_sc)}
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">3</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Jasa
                      </td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.total_pj)}
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                    </tr>
                    <tr key={rincian.index} class="font-bold">
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                        Sub Total
                      </td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.subtotal_pemasukan)}
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">3</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Pembelian Bensin
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.pembelian_bensin)}
                      </td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">4</td>
                      <td class="border border-gray-300 px-4 py-2">Listrik</td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.listrik)}
                      </td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">5</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Pembelian Suku Cadang
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.pembelian_suku_cadang)}
                      </td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">6</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Lain - lain
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.kebersihan)}
                      </td>
                    </tr>
                    <tr key={rincian.index}>
                      <td class="border border-gray-300 px-4 py-2">7</td>
                      <td class="border border-gray-300 px-4 py-2">
                        Total Gaji
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.gaji)}
                      </td>
                    </tr>
                    <tr key={rincian.index} class="font-bold">
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                        Sub Total
                      </td>
                      <td class="border border-gray-300 px-4 py-2"></td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.subtotal_pengeluaran)}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr key={rincian.index} class="font-bold">
                      <td class="border border-gray-300 px-4 py-2" colSpan="3">
                        TOTAL
                      </td>
                      <td class="border border-gray-300 px-4 py-2">
                         {formatCurrency(rincian.total_semua)}
                      </td>
                    </tr>
                  </tfoot>
                </>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailLaporanKeuangan;
