import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from "../context/url.js"

const AddTransaksiOffline = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [Nama, setNama] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const [Plat, setPlat] = useState("");
  const [Deskripsi, setDeskripsi] = useState("");

  const saveTransaksi = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/bookingoffline`, {
        nama_lengkap: Nama,
        plat_nomor: Plat,
        deskripsi: Deskripsi,
        no_telepon: No_Telepon,
      }, {
        headers: { access_token: localStorage.getItem('access_token') },
      });
      navigate('/transaksi');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div className="title mb-6">
        <div className="bg-white p-6 shadow-b-lg">
          <h1 className="text-black font-bold text-md">Manajemen Layanan</h1>
        </div>
      </div>

      <div className="mx-2 md:mx-4">
        <h1 className="text-black font-bold text-xl">Tambah Transaksi</h1>
      </div>

      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveTransaksi}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Nama<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nama}
                  required
                  onChange={(e) => setNama(e.target.value)}
                  placeholder="Nama Lengkap Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">No. Telepon<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={No_Telepon}
                  required
                  onChange={(e) => setNo_Telepon(e.target.value)}
                  placeholder="08***********"
                  pattern="[0-9]*"
                  title="Nomor telepon hanya boleh terdiri dari angka."
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Plat Nomor<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Plat}
                  required
                  onChange={(e) => setPlat(e.target.value)}
                  placeholder="Plat Nomor"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Deskripsi<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Deskripsi}
                  required
                  onChange={(e) => setDeskripsi(e.target.value)}
                  placeholder="Deskripsi Keluhan Yang Dialami"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
  );
};

export default AddTransaksiOffline;