import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../context/url.js";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    no_telepon: "",
    password: "",
    nama_lengkap: "",
    email: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null); 
  const [err, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (inputs.no_telepon === "") {
      setInputs(prev => ({ ...prev, no_telepon: "62" }));
    }
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if phone number starts with '62'
    return phoneNumber.startsWith("62");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setError("Mohon Selesaikan CAPTCHA!");
      return;
    }

    if (!validatePhoneNumber(inputs.no_telepon)) {
      setError("Nomor telepon harus diawali dengan '62'.");
      return;
    }

    try {
      await axios.post(`${url}/register`, {
        ...inputs,
        captcha: captchaValue
      });
      // Optionally redirect after successful registration
      navigate("/login");
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <div>
      <div className="mx-auto py-36">
        <h1 className="font-extrabold py-6 text-4xl text-center">REGISTER</h1>
        <div className="my-4 items-center flex flex-col w-full">
          <div className="w-[75%] md:w-[50%]">
            <form onSubmit={handleSubmit} className="box">
              {err && (
                <p className="text-[#E62129] text-sm font-medium bg-[#FCE7D1] p-4 border-none rounded-lg w-full">
                  {err}
                </p>
              )}
              <div className="py-2">
                <label className="label py-8 pb-3">
                  Nama<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    required
                    type="text"
                    placeholder="Nama Lengkap Anda"
                    name="nama_lengkap"
                    onChange={handleChange}
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  />
                </div>
              </div>
              <div className="py-2">
                <label className="label py-8 pb-3">
                  Username<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    required
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    type="text"
                    placeholder="username"
                    name="username"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="py-2">
                <label className="label py-8 pb-3">
                  Password<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    required
                    type="password"
                    placeholder="*******"
                    name="password"
                    onChange={handleChange}
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  />
                </div>
              </div>
              <div className="py-2">
                <label className="label py-8 pb-3">
                  Email<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    required
                    type="email"
                    placeholder="Email Aktif Anda"
                    name="email"
                    onChange={handleChange}
                    className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  />
                </div>
              </div>
              <div className="py-2">
                <label className="label py-8 pb-3">
                  No. Telepon<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <div className="select is-fullwidth">
                    <input
                      required
                      type="text"
                      placeholder="Nomor Telepon WA yang Aktif"
                      pattern="[0-9]*"
                      title="Nomor telepon hanya boleh terdiri dari angka."
                      name="no_telepon"
                      value={inputs.no_telepon} // Set value here
                      onChange={handleChange}
                      className="input p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <ReCAPTCHA
                  sitekey="6Lc4XjYqAAAAAEulb9hlTOWtj7CfPIgpXSpA7OVJ" // Replace with your site key
                  onChange={handleCaptchaChange}
                />
              </div>
              <div className="py-2">
                <button
                  type="submit"
                  className="button bg-[#E62129] font-bold text-white p-3 border-none rounded-lg w-full"
                >
                  Daftar
                </button>
              </div>
            </form>
            <p className="py-6">
              Sudah Punya Akun ?{" "}
              <Link to="/login">
                <span className="text-[#E62129] font-bold cursor-pointer">
                  Klik Disini
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
