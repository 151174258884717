import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/authcContext';

const ProtectedRoute = ({ element }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    const popup = document.createElement("div");
    popup.textContent = "Mohon login ke akun Anda!";
    popup.className =
      "fixed text-lg text-[#E62129] top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FFF2F2] border border-[#E62129] z-10 p-4 w-[90%] rounded";
    document.body.appendChild(popup);

    setTimeout(() => {
      document.body.removeChild(popup);
    }, 1500);

    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;