import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js";

const AddUser = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [Nama_Lengkap, setNama_Lengkap] = useState("");
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const [Alamat, setAlamat] = useState("");
  const [Role, setRole] = useState("");

  useEffect(() => {
    if (currentUser.role === 3) {
      setRole("4");
    }
  }, [currentUser.role]);
  const saveUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/user`,
        {
          username: Username,
          no_telepon: No_Telepon,
          password: Password,
          nama_lengkap: Nama_Lengkap,
          email: Email,
          alamat: Alamat,
          id_role: Role,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/user");
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.err || err.response.data;
        setError(errorMessage);
      } else {
        setError('An error occurred.');
      }
    }
  };

  return (
    <div>
      <div className="title mb-6">
        <div className="bg-white p-6 shadow-b-lg">
          <h1 className="text-black font-bold text-md">Data Pengguna</h1>
        </div>
      </div>

      <div className="mx-2 md:mx-4">
        <h1 className="text-black font-bold text-xl">Tambah Pengguna</h1>
      </div>

      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveUser}>
            {currentUser.role !== 3 && (
              <div className="py-2 mx-4">
                <div className={`py-2 ${error ? "block" : "hidden"}`}>
                  <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                    {error}
                  </p>
                </div>
                <label className="label py-8 pb-3 text-xs">
                  Role<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <select
                    value={Role}
                    required
                    onChange={(e) => setRole(e.target.value)}
                    className="text-xs p-3 pr-10 flex w-full rounded-lg border-solid border border-gray-400"
                  >
                    <option value="" disabled>
                      Pilih Role
                    </option>
                    <option value="4">Pelanggan</option>
                    <option value="1">Manajer</option>
                    <option value="2">Petugas Toko</option>
                    <option value="3">Kasir</option>
                  </select>
                </div>
              </div>
            )}
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nama_Lengkap}
                  onChange={(e) => setNama_Lengkap(e.target.value)}
                  placeholder="Nama Lengkap Anda"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Username<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Password<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="password"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="******"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Email<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="email"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Aktif Anda"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No. Telepon<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={No_Telepon}
                  onChange={(e) => setNo_Telepon(e.target.value)}
                  placeholder="62***********"
                  pattern="[0-9]*"
                  title="Nomor telepon hanya boleh terdiri dari angka."
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Alamat<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Alamat}
                  onChange={(e) => setAlamat(e.target.value)}
                  placeholder="Alamat Anda"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
