import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../context/url.js"

const EditPegawai = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [Nama, setNama] = useState("");
  const [Tanggal_Lahir, setTanggal_Lahir] = useState("");
  const [Ijazah, setIjazah] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const [Alamat, setAlamat] = useState("");
  const [Status, setStatus] = useState("");
  const { id } = useParams();
  const [GetStatus, setGetStatus] = useState([]);
  const [GetIjazah, setGetIjazah] = useState([]);

  useEffect(() => {
    getStatus();
    getIjazah();
  }, []);

  useEffect(() => {
    const getPegawaiById = async () => {
      try {
        const response = await axios.get(
          `${url}/pegawai/${id}`,
          {
            headers: { access_token: localStorage.getItem("access_token") },
          }
        );
        setNama(response.data.nama);
        setTanggal_Lahir(response.data.tanggal_lahir);
        setIjazah(response.data.id_ijazah);
        setNo_Telepon(response.data.no_hp);
        setAlamat(response.data.alamat);
        setStatus(response.data.id_status);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getPegawaiById();
  }, [id]);

  const updatePegawai = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/pegawai/${id}`,
        {
          nama: Nama,
          tgl_lahir: Tanggal_Lahir,
          ijazah: Ijazah,
          no_hp: No_Telepon,
          alamat: Alamat,
          status: Status,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/pegawai");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getIjazah = async () => {
    try {
      const response = await axios.get(`${url}/ijazah`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setGetIjazah(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  
  const getStatus = async () => {
    try {
      const response = await axios.get(`${url}/status/pegawai`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setGetStatus(response.data);
    } catch (error) {
      setError(error.response.data || "An error occurred." );
    }
  };

  return (
    <div>
      <div>
        <div className="title mb-6">
          <div className="bg-white p-6 shadow-b-lg">
            <h1 className="text-black font-bold text-md">Data Pegawai</h1>
          </div>
        </div>

        <div className="mx-2 md:mx-4">
          <h1 className="text-black font-bold text-xl">Edit Pegawai</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
            <form onSubmit={updatePegawai}>
              <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
                <p
                  className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
                >
                  {error}
                </p>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Nama<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Nama}
                    required
                    onChange={(e) => setNama(e.target.value)}
                    placeholder="Nama Pegawai"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Tanggal Lahir<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="date"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Tanggal_Lahir}
                    required
                    onChange={(e) => setTanggal_Lahir(e.target.value)}
                    placeholder="Tanggal Lahir"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">Ijazah<sup className="text-[#E62129]">*</sup></label>
                <div className="py-2">
                <select
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Ijazah}
                  required
                  onChange={(e) => setIjazah(e.target.value)}
                  placeholder="Ijazah Terakhir"
                >
                  <option value="" disabled>
                    Pilih Ijazah
                  </option>
                  {GetIjazah.map((ijazah) => (
                    <option
                      key={ijazah.id_ijazah}
                      value={ijazah.id_ijazah}
                    >
                      {ijazah.ijazah}
                    </option>
                  ))}
                </select>
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  No. Telepon<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <input
                    type="text"
                    required
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={No_Telepon}
                    onChange={(e) => setNo_Telepon(e.target.value)}
                    placeholder="08***********"
                    pattern="[0-9]*"
                    title="Nomor telepon hanya boleh terdiri dari angka."
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">Alamat<sup className="text-[#E62129]">*</sup></label>
                <div className="py-2">
                  <input
                    type="text"
                    required
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={Alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Alamat Pegawai"
                  />
                </div>
              </div>
              <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Status Pegawai<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <select
                  name="id_status"
                  required
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Status Pegawai"
                >
                  <option value="" disabled>
                    Pilih Status
                  </option>
                  {GetStatus.map((status) => (
                    <option
                      key={status.id_status}
                      value={status.id_status}
                    >
                      {status.status}
                    </option>
                  ))}
                </select>
              </div>
              </div>
              <div className="py-2 mx-4">
                <div className="py-2 flex justify-center">
                  <button
                    type="submit"
                    className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPegawai;
