import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../context/url.js"

const AddTransaksiGaransi = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");

  const [idTransGaransi, setIdTransGaransi] = useState(id || "");
  const [GetSukuCadang, setGetSukuCadang] = useState([]);
  const [sukuCadangList, setSukuCadangList] = useState([
    { idSukuCadang: "", jumlah: "" },
  ]);

  const handleAddFields = () => {
    setSukuCadangList([...sukuCadangList, { idSukuCadang: "", jumlah: "" }]);
  };

  const handleRemoveFields = (index) => {
    const list = [...sukuCadangList];
    list.splice(index, 1);
    setSukuCadangList(list);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...sukuCadangList];
    list[index][name] = value;
    setSukuCadangList(list);
  };

  const saveTransaksiGaransi = async (e) => {
    e.preventDefault();
    try {
      for (const sukuCadang of sukuCadangList) {
        await axios.post(
          `${url}/transaksi/garansi`,
          {
            id_transaksi_garansi: idTransGaransi,
            id_suku_cadang: sukuCadang.idSukuCadang,
            jumlah: sukuCadang.jumlah,
          },
          {
            headers: { access_token: localStorage.getItem("access_token") },
          }
        );
      }
      navigate("/transaksi/garansi");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  useEffect(() => {
    const getSukuCadang = async () => {
      try {
        const response = await axios.get(`${url}/skc/aktif`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setGetSukuCadang(response.data);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getSukuCadang();
  }, [id]);

  return (
    <div>
      <div className="title mb-6">
        <div className="bg-white p-6 shadow-b-lg">
          <h1 className="text-black font-bold text-md">Transaksi Garansi</h1>
        </div>
      </div>

      <div className="mx-2 md:mx-4">
        <h1 className="text-black font-bold text-xl">
          Form Tambah Transaksi Garansi
        </h1>
      </div>

      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveTransaksiGaransi}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nomor Transaksi<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  required
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={idTransGaransi}
                  onChange={(e) => setIdTransGaransi(e.target.value)}
                  placeholder="Nomor Transaksi"
                />
              </div>
            </div>
            {sukuCadangList.map((item, index) => (
              <div key={index} className="py-2 mx-4">
                <label className="label py-8 pb-3 text-xs">
                  Pilih Suku Cadang<sup className="text-[#E62129]">*</sup>
                </label>
                <div className="py-2">
                  <select
                  required
                    type="input"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    name="idSukuCadang"
                    value={item.idSukuCadang}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder="Pilih Suku Cadang"
                  >
                    <option value="" disabled>
                      Pilih Suku Cadang
                    </option>
                    {GetSukuCadang.map((sc) => (
                      <option key={sc.id_suku_cadang} value={sc.id_suku_cadang}>
                        <span>
                        {sc.nama_produk} - {sc.stok}
                        </span>
                      </option>
                    ))}
                  </select>
                </div>
                <label className="label py-8 pb-3 text-xs">Jumlah<sup className="text-[#E62129]">*</sup></label>
                <div className="py-2">
                  <input
                  required
                    type="number"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    name="jumlah"
                    value={item.jumlah}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder="Jumlah"
                  />
                </div>
                <div className="py-2 flex justify-between">
                  {sukuCadangList.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                      className="button bg-red-500 font-bold text-white text-xs p-3 border-none rounded-lg"
                    >
                      Hapus
                    </button>
                  )}
                  {index === sukuCadangList.length - 1 && (
                    <button
                      type="button"
                      onClick={handleAddFields}
                      className="button bg-[#2AB74A] font-bold text-white text-xs p-3 border-none rounded-lg"
                    >
                      Tambah Suku Cadang
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTransaksiGaransi;