import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../context/url.js";

const AddBookingOffline = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [jamBooking, setJamBooking] = useState([]); // State to store booking times
  const [selectedJamBooking, setSelectedJamBooking] = useState(""); // State to store selected booking time
  const [Nama, setNama] = useState("");
  const [No_Telepon, setNo_Telepon] = useState("");
  const [Plat, setPlat] = useState("");
  const [Deskripsi, setDeskripsi] = useState("");
  const IdJenisDefault = 2;
  const kategoriBooking = "Offline";

  const saveBooking = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/bookingoffline`,
        {
          nama_lengkap: Nama,
          no_telepon: No_Telepon,
          plat_nomor: Plat,
          deskripsi: Deskripsi,
          kategoriBooking: IdJenisDefault,
          id_jam: selectedJamBooking
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      navigate("/booking");
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.err || err.response.data;
        setError(errorMessage);
      } else {
        setError('An error occurred.');
      }
    }
  };
  

  useEffect(() => {
    const fetchJamBooking = async () => {
      try {
        const response = await axios.get(`${url}/booking/jamBooking/2`, {
          headers: { access_token: localStorage.getItem("access_token") }
        });
        setJamBooking(response.data);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setError(err.response.data.err || "An error occurred.");
        } else {
          setError("An error occurred.");
        }
      }
    };

    fetchJamBooking();
  }, []);

  return (
    <div>
         <div className={`mx-2 md:mx-4 px-2 md:px-4 mt-6  `}>
        <h1 className="text-black font-bold text-xl">Form Layanan Booking</h1>
      </div>

      <div className={`flex justify-center  `}>
        <div className="my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveBooking}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Kategori Booking<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={kategoriBooking}
                  required
                  readOnly
                  placeholder="Kategori Booking"
                />
              </div>
            </div>
            <div className="py-1 mx-4">
            <label className="label py-6 pb-3 text-xs">
              Pilih Jam Booking<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-1">
              <select
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={selectedJamBooking}
                required
                onChange={(e) => setSelectedJamBooking(e.target.value)}
              >
                <option value="">Pilih Jam Booking</option>
                {jamBooking.map((jam) => (
                  <option key={jam.id_jam_booking} value={jam.id_jam_booking}>
                    {jam.jam_booking} 
                  </option>
                ))}
              </select>
            </div>
          </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nama}
                  onChange={(e) => setNama(e.target.value)}
                  placeholder="Nama Lengkap Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No Handphone<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="number"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={No_Telepon}
                  onChange={(e) => setNo_Telepon(e.target.value)}
                  placeholder="No Telepon Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Plat Nomor<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  name="Plat"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Plat}
                  onChange={(e) => setPlat(e.target.value)}
                  placeholder="Plat Nomor Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Deskripsi Keluhan<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <textarea
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Deskripsi}
                  required
                  onChange={(e) => setDeskripsi(e.target.value)}
                  placeholder="Tulis Deskripsi Keluhan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBookingOffline;
