import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js"

const TransaksiGaransi = () => {
  const [transaksiGaransi, setTransaksiGaransi] = useState([]);
  const [FilteredTransaksiGaransi, setFilteredTransaksiGaransi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredTransaksiGaransi.length / perpage);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    getTransaksiGaransi();
  }, []);

  useEffect(() => {
    filterTransaksiGaransi();
  }, [transaksiGaransi, searchTerm]);

  const getTransaksiGaransi = async () => {
    try {
      const response = await axios.get(`${url}/transaksi/garansi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setTransaksiGaransi(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const filterTransaksiGaransi = () => {
    const filtered = transaksiGaransi.filter((transaksiGaransi) => {
      return (
        transaksiGaransi.nama_produk.toLowerCase().includes(searchTerm) ||
        transaksiGaransi.id_nota
          .toString()
          .toLowerCase()
          .includes(searchTerm) ||
        transaksiGaransi.jumlah.toString().toLowerCase().includes(searchTerm)
      );
    });
    setFilteredTransaksiGaransi(filtered);
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Data Transaksi Garansi</p>
        </div>
      </div>

      <div className="flex mx-2 md:mx-6 p2-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative align-middle items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perpage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari"
            className="lg:w-[25%] w-[50%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nomor Nota
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Suku Cadang
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Jumlah
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredTransaksiGaransi.length > 0 ?
                FilteredTransaksiGaransi.slice(
                  pageclick,
                  pageclick + perpage
                ).map((transaksiGaransi, index) => (
                  <tr
                    key={transaksiGaransi.id_transaksi}
                    className="odd:bg-white even:bg-[#F9F9F9] border-b"
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {transaksiGaransi.id_nota}
                    </td>
                    <td className="px-6 py-4">
                      {transaksiGaransi.nama_produk}
                    </td>
                    <td className="px-6 py-4">{transaksiGaransi.jumlah}</td>
                  </tr>
                )): (
                  <tr>
                    <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                      Belum Ada Transaksi Garansi
                    </td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex text-xs justify-end mt-4 mx-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      </div>
    </div>
  );
};

export default TransaksiGaransi;