import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { IoReceipt } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { url } from "../context/url.js";

const Transaksi = () => {
  const [transaksi, setTransaksi] = useState([]);
  const [Status, setStatus] = useState([]);
  const [FilteredTransaksi, setFilteredTransaksi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredTransaksi.length / perpage);

  useEffect(() => {
    getTransaksi();
    getStatus();
  }, []);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    filterTransaksi();
  }, [transaksi, Status, searchTerm, startDate, endDate]);

  const getTransaksi = async () => {
    try {
      const response = await axios.get(`${url}/transaksi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        if (Array.isArray(response.data)) {
          setTransaksi(response.data);
          //console.log(response.data);
        } else {
          console.error("Data transaksi bukan array:", response.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(`${url}/status/transaksi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatus(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusName = (id_status) => {
    const status = Status.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setPagenumber(0);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setPagenumber(0);
  };

  const filterTransaksi = () => {
    if (!Array.isArray(transaksi)) {
      console.error("transaksi bukan array");
      return;
    }

    const filtered = transaksi.filter((transaksi) => {
      const statusName = getStatusName(transaksi.id_status).toLowerCase();
      const transaksiDate = new Date(transaksi.tanggal);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (
        (transaksi.nama_lengkap.toLowerCase().includes(searchTerm) ||
          transaksi.deskripsi.toLowerCase().includes(searchTerm) ||
          transaksi.plat_nomor.toString().toLowerCase().includes(searchTerm) ||
          transaksi.tanggal.toString().toLowerCase().includes(searchTerm) ||
          statusName.includes(searchTerm)) &&
        (!start || transaksiDate >= start) &&
        (!end || transaksiDate <= end)
      );
    });
    setFilteredTransaksi(filtered);
  };

  const calculateDaysDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInTime = endDate - startDate;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };

  const calculateDaysFromNow = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const differenceInTime = now.getTime() - start.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Manajemen Layanan</p>
        </div>
      </div>

      <div className="m-2 md:m-6 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Periode</h1>

        <div className="flex justify-start gap-4 my-2 items-center align-middle text-center">
          <div className="start-date">
            <div className="flex-grow relative flex justify-end">
              <input
                type="date"
                className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-7 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>

          <span className="text-gray-400"> - </span>

          <div className="end-date">
            <input
              type="date"
              className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-7 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      </div>

      <div className="flex mx-2 md:mx-6 p2-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative align-middle items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perpage}
            onChange={handlePerPageChange}
          >
            <option value="1">1</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari"
            className="lg:w-[25%] w-[50%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      {/* <div className="text-xs pt-6">
        <Link
          to="/transaksi/add"
          className="button bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
        >
          Tambah
        </Link>
      </div> */}

      <div className="w-full bg-[#F7F7F7]">
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal Pengerjaan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No. Handphone
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Plat Nomor
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Deskripsi Keluhan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredTransaksi.length > 0 ? (
                  FilteredTransaksi.slice(pageclick, pageclick + perpage).map(
                    (transaksi, index) => (
                      <tr
                        key={transaksi.id_transaksi}
                        className="odd:bg-white even:bg-[#F9F9F9] border-b"
                      >
                        <td className="px-6 py-4">{index + 1}</td>
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                          {transaksi.nama_lengkap}
                        </td>
                        <td className="px-6 py-4">{transaksi.tanggal}</td>
                        <td className="px-6 py-4">
                          { transaksi.id_status === 2 ? 
			"Sudah Selesai" :
transaksi.tanggal_target
                            ? `${calculateDaysFromNow(
                                transaksi.tanggal
                              )} Hari dari ${calculateDaysDifference(
                                transaksi.tanggal,
                                transaksi.tanggal_target
                              )} Hari`
                            : "Belum Selesai"}
                        </td>
                        <td className="px-6 py-4">{transaksi.no_telepon}</td>
                        <td className="px-6 py-4">{transaksi.plat_nomor}</td>
                        <td className="px-6 py-4">{transaksi.deskripsi}</td>
                        <td className="px-6 py-4">
                          {transaksi.id_status === 1 && (
                            <div className="flex justify-center items-center bg-[#F08519] p-2 text-xs rounded-lg text-white">
                              <h1 className="text-xs uppercase">
                                {getStatusName(transaksi.id_status)}
                              </h1>
                            </div>
                          )}
                          {transaksi.id_status === 2 && (
                            <div className="flex justify-center items-center bg-[#2AB74A] p-2 text-xs rounded-lg text-white">
                              <h1 className="text-xs uppercase">
                                {getStatusName(transaksi.id_status)}
                              </h1>
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center space-x-2">
                            {transaksi.id_status === 1 && (
                              <Link
                                to={`/transaksi/edit/${transaksi.id_transaksi}`}
                                title="Edit"
                              >
                                <MdEdit size={20} className="text-[#F08519]" />
                              </Link>
                            )}
                            {transaksi.id_status === 2 && (
                              <Link
                                to={`/transaksi/edit/${transaksi.id_transaksi}`}
                                title="View"
                              >
                                <IoMdEye size={20} className="text-[#F08519]" />
                              </Link>
                            )}
                            <Link
                              to={`/nota/${transaksi.id_transaksi}`}
                              title="Nota"
                            >
                              <IoReceipt size={20} className="text-[#E62129]" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      Belum Melakukan Transaksi
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end mt-4 mx-6 text-xs">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaksi;
