import React, { useContext } from "react";
import background from "../img/Background.png";
import img1 from "../img/racing.png";
import img2 from "../img/aplikasi.png";
import kImg1 from "../img/kk-1.png";
import kImg2 from "../img/kk-2.jpg";
import kImg3 from "../img/kk-3.png";
import kImg4 from "../img/kk-4.png";
import lImg1 from "../img/layanan-1.png";
import lImg2 from "../img/Layanan-21.png";
import lkImg1 from "../img/lk-1.png";
import lkImg2 from "../img/lk-2.png";
import lkImg3 from "../img/lk-3.png";
import lkImg4 from "../img/lk-4.png";
import kIcon1 from "../img/Workers.png";
import kIcon2 from "../img/Tools.png";
import kIcon3 from "../img/Money Bag.png";
import kIcon4 from "../img/Service.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcContext";

const Home = () => {
  const navigate = useNavigate();
  const regis = () => {
    navigate("/register");
  };
  const { currentUser } = useContext(AuthContext);


  return (
    <div>
      <div
        style={{ "--image-url": `url(${background})` }}
        className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-center items-center h-screen w-full py-16 px-4"
      >
        <div className="max-w-screen-xl mx-auto grid ">
          <h1 className="text-white font-extrabold p-6 text-center text-5xl sm:text-6xl md:text-7xl">
            CrazyFast SpeedShop
          </h1>
          <p className="text-white font-normal p-6 text-center text-lg md:text-xl">
            Solusi Lengkap untuk Custom Breket & Part Motor Anda
          </p>
          { !currentUser && 
          <button
            onClick={regis}
            className="bg-[#F08519] w-[200px] rounded-md font-medium p-2 mx-auto my-6 py-3 text-white"
          >
            Daftar Sekarang
          </button>
          }
        </div>
      </div>

      <div className="w-full py-16 px-4">
        <div className="max-w-screen-xl mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center py-6">
            <h1 className="font-bold md:text-4xl sm:text-3xl text-2xl py-2">
              Racing Parts-Accessories-Consulting-Coffee Shop
            </h1>
            <p className="text-lg py-2">
              Bengkel terpercaya untuk segala kebutuhan perawatan dan aksesoris
              kendaraan Anda.
            </p>
          </div>
          <img
            src={img1}
            alt="home-banner"
            className="w-[500px] mx-auto my-4"
          />
        </div>
      </div>

      <div className="w-full py-16 px-4 bg-gray-100">
  <h1 className="font-bold md:text-4xl sm:text-3xl text-3xl py-9 mx-auto flex justify-center">
    Keunggulan Kami
  </h1>
  <div className="max-w-[1240px] mx-auto grid md:grid-cols-4 gap-8 justify-center items-center">
    <div
      style={{ backgroundImage: `url(${kImg1})` }}
      className="bg-cover bg-center flex flex-col justify-center items-center h-72 py-16 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
    >
      <img
        className="w-14 mx-auto bg-white rounded-md p-2"
        src={kIcon1}
        alt="Keunggulan Kami"
      />
      <h2 className="text-xl font-bold text-white text-center p-2">
        Teknisi Berpengalaman
      </h2>
    </div>

    <div
      style={{ backgroundImage: `url(${kImg2})` }}
      className="bg-cover bg-center flex flex-col justify-center items-center h-72 py-16 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
    >
      <img
        className="w-14 mx-auto bg-white rounded-md p-2"
        src={kIcon2}
        alt="Keunggulan Kami"
      />
      <h2 className="text-xl font-bold text-white text-center p-2">
        Peralatan Modern
      </h2>
    </div>

    <div
      style={{ backgroundImage: `url(${kImg3})` }}
      className="bg-cover bg-center flex flex-col justify-center items-center h-72 py-16 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
    >
      <img
        className="w-14 mx-auto bg-white rounded-md p-2"
        src={kIcon3}
        alt="Keunggulan Kami"
      />
      <h2 className="text-xl font-bold text-white text-center p-2">
        Harga Terjangkau
      </h2>
    </div>

    <div
      style={{ backgroundImage: `url(${kImg4})` }}
      className="bg-cover bg-center flex flex-col justify-center items-center h-72 py-16 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
    >
      <img
        className="w-14 mx-auto bg-white rounded-md p-2"
        src={kIcon4}
        alt="Keunggulan Kami"
      />
      <h2 className="text-xl font-bold text-white text-center p-2">
        Garansi dan Layanan Purna Jual
      </h2>
    </div>
  </div>
</div>


      <div className="w-full py-16 px-4">
        <h1 className="font-bold md:text-4xl sm:text-3xl text-3xl py-9 mx-auto flex justify-center">
          Layanan Andalan
        </h1>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8 justify-center items-center">
          <div>
            <div
              style={{ "--image-url": `url(${lImg1})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
            >
              <h2 className="text-2xl font-bold text-white p-2">
                Painting Body & Velg
              </h2>
            </div>
            <p className="text-center text-lg py-6">
              Pengecatan ulang, perbaikan body dan velg yang lecet atau rusak,
              dan detailing untuk hasil maksimal.
            </p>
          </div>

          <div>
            <div
              style={{ "--image-url": `url(${lImg2})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72 py-8 px-4 shadow-xl rounded-lg hover:scale-105 duration-300"
            >
              <h2 className="text-2xl font-bold text-white p-2">
                Custom Lampu
              </h2>
            </div>
            <p className="text-center text-lg py-6">
              Modifikasi lampu kendaraan Anda dengan berbagai pilihan model dan
              warna yang menarik.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full py-16 px-4 bg-[#F7F7F7]">
        <h1 className="font-bold md:text-4xl sm:text-3xl text-3xl py-6 mx-auto flex justify-center">
          Layanan Kami
        </h1>
        <p className="text-center text-lg pt-2 pb-10">
          CrazyFast SpeedShop juga menyediakan berbagai layanan untuk menjaga
          performa dan tampilan kendaraan Anda
        </p>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-4 gap-10">
          <div className="shadow-xl hover:scale-105 duration-300 flex flex-col">
            <div
              style={{ "--image-url": `url(${lkImg1})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72"
            ></div>
            <div className="bg-white flex-1 flex flex-col">
              <h1 className="text-center font-bold text-xl py-3 px-6">
                Service Ringan & Berat
              </h1>
              <p className="text-center pt-3 pb-8 px-3 flex-1">
                Pengecatan ulang, perbaikan body dan velg yang lecet atau rusak,
                dan detailing untuk hasil maksimal.
              </p>
            </div>
          </div>
          <div className="shadow-xl hover:scale-105 duration-300 flex flex-col">
            <div
              style={{ "--image-url": `url(${lkImg2})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72"
            ></div>
            <div className="bg-white flex-1 flex flex-col">
              <h1 className="text-center font-bold text-xl py-3 px-6">
                Pemasangan Part Standar & Aksesoris
              </h1>
              <p className="text-center pt-3 pb-8 px-3 flex-1">
                Suku cadang asli dan aksesoris berkualitas untuk meningkatkan
                performa dan gaya kendaraan Anda.
              </p>
            </div>
          </div>
          <div className="shadow-xl hover:scale-105 duration-300 flex flex-col">
            <div
              style={{ "--image-url": `url(${lkImg3})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72"
            ></div>
            <div className="bg-white flex-1 flex flex-col">
              <h1 className="text-center font-bold text-xl py-3 px-6">
                Mapping ECU Motor Racing & Standar
              </h1>
              <p className="text-center pt-3 pb-8 px-3 flex-1">
                Optimasi performa mesin dengan setting ECU yang tepat untuk
                kebutuhan Anda.
              </p>
            </div>
          </div>
          <div className="shadow-xl hover:scale-105 duration-300 flex flex-col">
            <div
              style={{ "--image-url": `url(${lkImg4})` }}
              className="bg-cover bg-center bg-[image:var(--image-url)] flex flex-col justify-end items-start h-72"
            ></div>
            <div className="bg-white flex-1 flex flex-col">
              <h1 className="text-center font-bold text-xl py-3 px-6">
                Cuci Motor
              </h1>
              <p className="text-center pt-3 pb-8 px-3 flex-1">
                Layanan cuci motor yang profesional dan berkualitas untuk
                menjaga kebersihan dan kilau kendaraan Anda.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-16 px-4 bg-white">
        <div className="max-w-screen-xl mx-auto grid md:grid-cols-2">
          <img
            src={img2}
            alt="Crazy Fast App"
            className="w-[500px] mx-auto my-4"
          />
          <div className="flex flex-col justify-center py-6">
            <h1 className="font-bold md:text-4xl sm:text-3xl text-2xl py-6">
              CrazyFast SpeedShop Hadir Dalam Bentuk Aplikasi{" "}
            </h1>
            <p className="text-lg py-3">
              Nikmati kemudahan akses dan berbagai keuntungan dengan menggunakan
              aplikasi bengkel kami.
            </p>
            <div className="flex justify-between pr-8">
              <div className="text-lg py-3">
                <ul>
                  <li>● Booking Service Online</li>
                  <li>● Akses Riwayat Service</li>
                  <li>● Klaim Garansi</li>
                </ul>
              </div>
              <div className="text-lg py-3">
                <ul>
                  <li>● Lihat Antrian Booking</li>
                  <li>● Katalog Sparepart</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

