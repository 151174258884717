export const formatCurrency = (value) => {
    if (isNaN(value) || value === "") return "";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Remove decimals
      maximumFractionDigits: 0, // Remove decimals
    }).format(value);
  };
  
  export const parseCurrency = (value) => {
    // Remove non-numeric characters and parse to float
    return value.replace(/[^0-9,-]+/g, '').replace(',', '.');
};

export const handleChange = (setter) => (e) => {
  const value = e.target.value;
  setter(formatCurrency(parseFloat(parseCurrency(value)) || 0));
};