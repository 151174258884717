import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { url } from "../context/url.js";
import { formatCurrency, parseCurrency } from "../context/formatCurrency.js";

const EditTransaksi = () => {
  const [error, setError] = useState("");
  const [transaksi, setTransaksi] = useState({
    id_booking: "",
    tanggal: "",
    id_transaksi_servis: "",
    total_harga_servis: "",
    id_transaksi_sc: "",
    total_suku_cadang: "",
    total_harga_suku_cadang: "",
    total_diskon_suku_cadang: "",
    id_metode: "",
    id_bank: "",
    id_lunas: "",
    id_transaksi_jasa: "",
    total_harga_jasa: "",
    total_diskon_jasa: "",
    id_pegawai: "",
    total: "",
    id_status: "",
    id_invoice: "",
    id_nota: "",
    transaksi_servis: [],
    transaksi_suku_cadang: [],
    transaksi_jasa: [],
  });
  const [info, setInfo] = useState([]);
  const [servis, setServis] = useState([]);
  const [sukucadang, setSukuCadang] = useState([]);
  const [jasa, setJasa] = useState([]);
  const [bank, setBank] = useState([]);
  const [metode, setMetode] = useState([]);
  const [pegawai, setPegawai] = useState([]);
  const [newServis, setNewServis] = useState([]);
  const [AddServis, setAddServis] = useState("");
  const [showAddServisInput, setShowAddServisInput] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableServisId, setEditableServisId] = useState(null);
  const [newSukuCadang, setNewSukuCadang] = useState([]);
  const [AddJasa, setAddJasa] = useState("");
  const [showAddJasaInput, setShowAddJasaInput] = useState(false);
  const [editableSukuCadangId, setEditableSukuCadangId] = useState(null);
  const [newJasa, setNewJasa] = useState([]);
  const [editableJasaId, setEditableJasaId] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTransaksiById();
    getTransaksiServis();
    getTransaksiSukuCadang();
    getTransaksiJasa();
    getInfoTransaksi();
    getBank();
    getPegawai();
    getMetode();
  }, [id]);

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
    }
  }, [error]);

  useEffect(() => {
    const radioButton = document.querySelector(
      `input[name="id_status"][value="${transaksi.id_status}"]`
    );
    if (radioButton) {
      radioButton.checked = true;
    }
  }, [transaksi.id_status]);

  const getTransaksiById = async () => {
    try {
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      console.log(response.data);
      setTransaksi(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getTransaksiServis = async () => {
    try {
      const response = await axios.get(`${url}/servis`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setServis(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getTransaksiSukuCadang = async () => {
    try {
      const response = await axios.get(`${url}/skc/aktif`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setSukuCadang(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getTransaksiJasa = async () => {
    try {
      const response = await axios.get(`${url}/jasa`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setJasa(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getInfoTransaksi = async () => {
    try {
      const response = await axios.get(`${url}/transaksi/info/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setInfo(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getBank = async () => {
    try {
      const response = await axios.get(`${url}/bank`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setBank(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getPegawai = async () => {
    try {
      const response = await axios.get(`${url}/pegawai/aktif`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setPegawai(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };
  
  const getMetode = async () => {
    try {
      const response = await axios.get(`${url}/metode`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setMetode(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handleChange = (e) => {
    setTransaksi({ ...transaksi, [e.target.name]: e.target.value });
  };

  const updateTransaksi = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${url}/transaksi/${id}`, transaksi, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setIsEditable(false);
      navigate("/transaksi")
    } catch (err) {
      console.error(err);
    if (err.response && err.response.data) {
      // Check for different formats of error messages and set the error state accordingly
      const errorMessage = err.response.data.message || err.response.data.error || err.response.data.err || "An error occurred.";
      setError(errorMessage);
    } else {
      setError("An error occurred.");
    }
    }
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditable(true);
  };

  //TRANSAKSI SERVIS API
  const deleteTransaksiServis = async (id_transaksi_servis) => {
    try {
      await axios.delete(`${url}/transaksi/servis/${id_transaksi_servis}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi({
        ...transaksi,
        transaksi_servis: transaksi.transaksi_servis.filter(
          (transaksi_servis) =>
            transaksi_servis.id_transaksi !== id_transaksi_servis
        ),
      });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const editTransaksiServis = (id_transaksi) => {
    setEditableServisId(id_transaksi);
  };

  const handleAddServis = () => {
    setNewServis([...newServis, { id_servis: "", harga: "" }]);
  };

  // const handleNewServisChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const newServisList = [...newServis];
  //   newServisList[index][name] = value;
  //   setNewServis(newServisList);
  // };

  const handleNewServisChange = (index, e) => {
    const { name, value } = e.target;
    const newServisList = [...newServis];
    if (name === "harga") {
      newServisList[index][name] = formatCurrency(
        parseFloat(parseCurrency(value)) || 0
      );
    } else {
      newServisList[index][name] = value;
    }
    setNewServis(newServisList);
  };

  const handleSaveNewServis = async () => {
    // Validasi input
    const invalidItems = newServis.filter(
      (transaksi_servis) =>
        !transaksi_servis.id_servis || !transaksi_servis.harga
    );

    if (invalidItems.length > 0) {
      setError("Semua kolom harus diisi untuk setiap servis.");
      return;
    }

    try {
      await Promise.all(
        newServis.map((transaksi_servis) =>
          axios.post(
            `${url}/transaksi/servis`,
            {
              ...transaksi_servis,
              harga: parseCurrency(transaksi_servis.harga),
              id_transaksi_servis: transaksi.id_transaksi_servis,
            },
            {
              headers: { access_token: localStorage.getItem("access_token") },
            }
          )
        )
      );
      setNewServis([]);
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handleServisChange = (e, transaksiServisId) => {
    const { name, value } = e.target;
    setTransaksi({
      ...transaksi,
      transaksi_servis: transaksi.transaksi_servis.map((transaksi_servis) =>
        transaksi_servis.id_transaksi === transaksiServisId
          ? {
              ...transaksi_servis,
              [name]:
                name === "harga"
                  ? formatCurrency(parseFloat(parseCurrency(value)) || 0)
                  : value,
            }
          : transaksi_servis
      ),
    });
  };

  const updateServis = async (transaksiServisId) => {
    const servis = transaksi.transaksi_servis.find(
      (transaksi_servis) => transaksi_servis.id_transaksi === transaksiServisId
    );

    // Validasi input
    if (!servis || !servis.id_servis || !servis.harga) {
      setError("Semua kolom harus diisi untuk setiap servis.");
      return;
    }

    try {
      await axios.patch(
        `${url}/transaksi/servis/${transaksiServisId}`,
        {
          ...servis,
          harga: parseCurrency(servis.harga),
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
      setEditableServisId(null);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  //TRANSAKSI SUKU CADANG API
  const deleteTransaksiSukuCadang = async (id_transaksi_sc) => {
    try {
      await axios.delete(`${url}/transaksi/sukucadang/${id_transaksi_sc}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi({
        ...transaksi,
        transaksi_suku_cadang: transaksi.transaksi_suku_cadang.filter(
          (transaksi_suku_cadang) =>
            transaksi_suku_cadang.id_transaksi !== id_transaksi_sc
        ),
      });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const editTransaksiSukuCadang = (id_transaksi) => {
    setEditableSukuCadangId(id_transaksi);
  };

  const handleAddSukuCadang = () => {
    setNewSukuCadang([
      ...newSukuCadang,
      { id_suku_cadang: "", jumlah_suku_cadang: "", harga: "", diskon: "" },
    ]);
  };

  const handleNewSukuCadangChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSukuCadang = [...newSukuCadang];

    if (name === "id_suku_cadang") {
      updatedSukuCadang[index][name] = value;

      const selectedSukuCadang = sukucadang.find(
        (sc) => sc.id_suku_cadang === parseInt(value)
      );
      if (selectedSukuCadang) {
        updatedSukuCadang[index].harga = selectedSukuCadang.harga_jual;
      } else {
        updatedSukuCadang[index].harga = "";
      }
    } else if (name === "diskon") {
      newSukuCadang[index][name] = formatCurrency(
        parseFloat(parseCurrency(value)) || 0
      );
    } else {
      updatedSukuCadang[index][name] = value;
    }

    setNewSukuCadang(updatedSukuCadang);
  };

  const handleSaveNewSukuCadang = async () => {
    // Validasi input
    const invalidItems = newSukuCadang.filter(
      (transaksi_suku_cadang) =>
        !transaksi_suku_cadang.id_suku_cadang ||
        !transaksi_suku_cadang.harga ||
        !transaksi_suku_cadang.diskon
    );

    if (invalidItems.length > 0) {
      setError("Semua kolom harus diisi untuk setiap suku cadang.");
      return;
    }
    try {
      await Promise.all(
        newSukuCadang.map((transaksi_suku_cadang) =>
          axios.post(
            `${url}/transaksi/sukucadang`,
            {
              ...transaksi_suku_cadang,
              diskon: parseCurrency(transaksi_suku_cadang.diskon),
              id_transaksi_sc: transaksi.id_transaksi_sc,
            },
            {
              headers: { access_token: localStorage.getItem("access_token") },
            }
          )
        )
      );
      setNewSukuCadang([]);
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handleSukuCadangChange = (e, transaksiSukuCadangId) => {
    const { name, value } = e.target;
    setTransaksi((prevTransaksi) => {
      const updatedTransaksiSukuCadang =
        prevTransaksi.transaksi_suku_cadang.map((transaksi_suku_cadang) => {
          if (transaksi_suku_cadang.id_transaksi === transaksiSukuCadangId) {
            const updatedItem = { ...transaksi_suku_cadang, [name]: value };

            if (name === "id_suku_cadang") {
              const selectedSukuCadang = sukucadang.find(
                (sc) => sc.id_suku_cadang === parseInt(value)
              );
              if (selectedSukuCadang) {
                updatedItem.harga = selectedSukuCadang.harga_jual;
              }
            } else if (name === "diskon") {
              // Handle diskon formatting similarly
              updatedItem.diskon = formatCurrency(
                parseFloat(parseCurrency(value)) || 0
              );
            }

            return updatedItem;
          }
          return transaksi_suku_cadang;
        });

      return {
        ...prevTransaksi,
        transaksi_suku_cadang: updatedTransaksiSukuCadang,
      };
    });
  };

  const updateSukuCadang = async (transaksiSukuCadangId) => {
    const suku_cadang = transaksi.transaksi_suku_cadang.find(
      (transaksi_suku_cadang) =>
        transaksi_suku_cadang.id_transaksi === transaksiSukuCadangId
    );

    // Validasi input
    if (
      !suku_cadang ||
      !suku_cadang.id_suku_cadang ||
      !suku_cadang.harga ||
      !suku_cadang.diskon
    ) {
      setError("Semua kolom harus diisi untuk setiap suku cadang.");
      return;
    }
    try {
      await axios.patch(
        `${url}/transaksi/sukucadang/${transaksiSukuCadangId}`,
        { ...suku_cadang, diskon: parseCurrency(suku_cadang.diskon) },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
      setEditableSukuCadangId(null);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  //TRANSAKSI JASA API
  const deleteTransaksiJasa = async (id_transaksi_jasa) => {
    try {
      await axios.delete(`${url}/transaksi/jasa/${id_transaksi_jasa}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi({
        ...transaksi,
        transaksi_jasa: transaksi.transaksi_jasa.filter(
          (transaksi_jasa) => transaksi_jasa.id_transaksi !== id_transaksi_jasa
        ),
      });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const editTransaksiJasa = (id_transaksi) => {
    setEditableJasaId(id_transaksi);
  };

  const handleAddJasa = () => {
    setNewJasa([...newJasa, { id_jasa: "", harga: "", diskon: "" }]);
  };

  const handleNewJasaChange = (index, e) => {
    const { name, value } = e.target;
    const newJasaList = [...newJasa];
    if (name === "harga" || name === "diskon") {
      newJasaList[index][name] = formatCurrency(
        parseFloat(parseCurrency(value)) || 0
      );
    } else {
      newJasaList[index][name] = value;
    }
    setNewJasa(newJasaList);
  };

  const handleSaveNewJasa = async () => {
    // Validasi input
    const invalidItems = newJasa.filter(
      (transaksi_jasa) =>
        !transaksi_jasa.id_jasa ||
        !transaksi_jasa.harga ||
        !transaksi_jasa.diskon
    );

    if (invalidItems.length > 0) {
      setError("Semua kolom harus diisi untuk setiap jasa.");
      return;
    }
    try {
      await Promise.all(
        newJasa.map((transaksi_jasa) =>
          axios.post(
            `${url}/transaksi/jasa`,
            {
              ...transaksi_jasa,
              harga: parseCurrency(transaksi_jasa.harga),
              diskon: parseCurrency(transaksi_jasa.diskon),
              id_transaksi_jasa: transaksi.id_transaksi_jasa,
            },
            {
              headers: { access_token: localStorage.getItem("access_token") },
            }
          )
        )
      );
      setNewJasa([]);
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handleJasaChange = (e, transaksiJasaId) => {
    const { name, value } = e.target;
    setTransaksi((prevTransaksi) => ({
      ...prevTransaksi,
      transaksi_jasa: prevTransaksi.transaksi_jasa.map((transaksi_jasa) =>
        transaksi_jasa.id_transaksi === transaksiJasaId
          ? {
              ...transaksi_jasa,
              [name]:
                name === "harga" || name === "diskon"
                  ? formatCurrency(
                      parseFloat(parseCurrency(String(value))) || 0
                    )
                  : value,
            }
          : transaksi_jasa
      ),
    }));
  };

  const updateJasa = async (transaksiJasaId) => {
    const jasa = transaksi.transaksi_jasa.find(
      (transaksi_jasa) => transaksi_jasa.id_transaksi === transaksiJasaId
    );

    // Validasi input
    if (!jasa || !jasa.id_jasa || !jasa.harga || !jasa.diskon) {
      setError("Semua kolom harus diisi.");
      return;
    }
    try {
      await axios.patch(
        `${url}/transaksi/jasa/${transaksiJasaId}`,
        {
          ...jasa,
          harga: parseCurrency(jasa.harga), // Convert to raw value
          diskon: parseCurrency(jasa.diskon), // Convert to raw value
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      const response = await axios.get(`${url}/transaksi/${id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setTransaksi(response.data);
      setEditableJasaId(null);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const saveServis = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/servis`,
        {
          servis: AddServis,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      setShowAddServisInput(false);
      setAddServis("");
      const response = await axios.get(`${url}/servis`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setServis(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const saveJasa = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/jasa`,
        {
          jasa: AddJasa,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      setShowAddJasaInput(false);
      setAddJasa("");
      const response = await axios.get(`${url}/jasa`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setJasa(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div className="title mb-6">
        <div className="bg-white p-6 shadow-b-lg">
          <h1 className="text-black font-bold text-md">Manajemen Layanan</h1>
        </div>
      </div>
      <div className="mx-2 md:mx-4">
        {transaksi.id_status === 1 ? (
          <h1 className="text-black font-bold text-xl">Edit Transaksi</h1>
        ) : (
          <h1 className="text-black font-bold text-xl">Detail Transaksi</h1>
        )}
      </div>
      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
            <p
              className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
            >
              {error}
            </p>
          </div>

          <div className="informasi-diri">
            <h1 className="uppercase text-lg py-2 mx-4 font-bold">
              Informasi Transaksi
            </h1>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No. Booking<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="id_booking"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={transaksi.id_booking}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Tanggal<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="date"
                  name="tanggal"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={transaksi.tanggal}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Tanggal Target<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="date"
                  name="tanggal_target"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={transaksi.tanggal_target}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
            </div>

            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama
                <sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="nama_lengkap"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={info.nama_lengkap}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No Telepon
                <sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="no_telepon"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={info.no_telepon}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Plat Nomor
                <sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="plat_nomor"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={info.plat_nomor}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Deskripsi
                <sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <textarea
                  type="text"
                  name="deskripsi"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={info.deskripsi}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          {/* TRANSAKSI SERVIS */}
          <div className="mx-4 my-2 text-sm">
            <h1 className="uppercase text-lg font-bold">Transaksi Servis</h1>
            <button
              className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
              onClick={handleAddServis}
              disabled={!isEditable}
            >
              Tambah Servis
            </button>
            <div className="mt-2 w-full">
              {newServis.length > 0 &&
                newServis.map((servisItem, index) => (
                  <div key={index} className="flex flex-col gap-2 mb-2 w-full">
                    <div className="flex justify-between items-center gap-2 w-full">
                      <select
                        name="id_servis"
                        required
                        value={servisItem.id_servis}
                        onChange={(e) => handleNewServisChange(index, e)}
                        placeholder="Pilih Servis"
                        className="input w-full text-xs p-2 rounded-lg border-solid border border-gray-400"
                      >
                        <option value="" disabled>
                          Pilih Servis
                        </option>
                        {servis.map((s) => (
                          <option key={s.id_servis} value={s.id_servis}>
                            {s.servis}
                          </option>
                        ))}
                      </select>
                      <IoIosAddCircle
                        size={30}
                        className="text-[#F08519] cursor-pointer"
                        onClick={() => setShowAddServisInput(true)}
                        disabled={!isEditable}
                      >
                        Tambah Servis Baru
                      </IoIosAddCircle>
                    </div>
                    <div className="flex items-center gap-2 w-full">
                      <input
                        type="text"
                        required
                        name="harga"
                        value={servisItem.harga}
                        onChange={(e) => handleNewServisChange(index, e)}
                        placeholder="Harga"
                        className="input w-full text-xs p-2 rounded-lg border-solid border border-gray-400"
                      />

                      <button
                        className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
                        onClick={handleSaveNewServis}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                ))}

              {showAddServisInput && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-bold">Tambah Servis Baru</h2>
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={() => setShowAddServisInput(false)}
                      >
                        <IoMdClose size={24} />
                      </button>
                    </div>
                    <div className="py-2 flex gap-2">
                      <input
                        type="text"
                        required
                        className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                        value={AddServis}
                        onChange={(e) => setAddServis(e.target.value)}
                        placeholder="Nama Servis Baru"
                      />
                      <button
                        className="bg-[#F08519] px-4 py-3 text-xs text-center rounded-lg font-bold text-white"
                        onClick={saveServis}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {transaksi.transaksi_servis.map((transaksi_servis) => (
              <div key={transaksi_servis.id_transaksi} className="py-2">
                {editableServisId === transaksi_servis.id_transaksi ? (
                  <div className="flex gap-2 w-full">
                    <select
                      type="text"
                      name="id_servis"
                      required
                      value={transaksi_servis.id_servis}
                      onChange={(e) =>
                        handleServisChange(e, transaksi_servis.id_transaksi)
                      }
                      placeholder="Pilih Servis"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    >
                      <option value="" disabled>
                        Pilih Servis
                      </option>
                      {servis.map((s) => (
                        <option key={s.id_servis} value={s.id_servis}>
                          {s.servis}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      required
                      name="harga"
                      value={transaksi_servis.harga}
                      onChange={(e) =>
                        handleServisChange(e, transaksi_servis.id_transaksi)
                      }
                      placeholder="Harga"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    />
                    <div className="w-1/5 flex items-center justify-start">
                      <button
                        className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
                        onClick={() =>
                          updateServis(transaksi_servis.id_transaksi)
                        }
                        disabled={!isEditable}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center w-full">
                    <span className="w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400">
                      Servis:{" "}
                      {servis.find(
                        (s) => s.id_servis === transaksi_servis.id_servis
                      )?.servis || "Tidak ditemukan"}
                    </span>
                    <span className="w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400">
                      Harga: {formatCurrency(transaksi_servis.harga)}
                    </span>
                    <div className="flex space-x-2 w-1/5">
                      <MdEdit
                        className="text-[#F08519] cursor-pointer"
                        disabled={!isEditable}
                        onClick={() =>
                          editTransaksiServis(transaksi_servis.id_transaksi)
                        }
                      />
                      <MdDelete
                        className="text-red-500 cursor-pointer"
                        disabled={!isEditable}
                        onClick={() =>
                          deleteTransaksiServis(transaksi_servis.id_transaksi)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* TRANSAKSI SUKU CADANG*/}
          <div className="mx-4 my-2 text-sm">
            <h1 className="uppercase text-lg font-bold">
              Transaksi Suku Cadang
            </h1>
            <button
              className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
              onClick={handleAddSukuCadang}
              disabled={!isEditable}
            >
              Tambah Suku Cadang
            </button>
            {newSukuCadang.map((suku_cadang, index) => (
              <div key={index} className="py-2">
                <div className="flex items-center my-2">
                  <select
                    name="id_suku_cadang"
                    value={suku_cadang.id_suku_cadang}
                    onChange={(e) => handleNewSukuCadangChange(index, e)}
                    className="input text-xs p-2 rounded-lg border-solid border border-gray-400 flex-1 mr-2"
                  >
                    <option value="" disabled>
                      Pilih Suku Cadang
                    </option>
                    {sukucadang.map((sc) => (
                      <option key={sc.id_suku_cadang} value={sc.id_suku_cadang}>
                        {sc.nama_produk} - {sc.stok}
                      </option>
                    ))}
                  </select>
                  {suku_cadang.id_suku_cadang ? (
                    <p className="input text-xs p-2 rounded-lg border-solid border border-gray-400 flex-1 mr-2">
                      Harga: {formatCurrency(suku_cadang.harga)}
                    </p>
                  ) : (
                    <p className="input text-xs p-2 rounded-lg border-solid border border-gray-400 flex-1 mr-2">
                      Harga:{" "}
                    </p>
                  )}
                </div>
                <div className="flex items-center my-2">
                  <input
                    type="text"
                    name="jumlah_suku_cadang"
                    value={suku_cadang.jumlah_suku_cadang}
                    onChange={(e) => handleNewSukuCadangChange(index, e)}
                    placeholder="Jumlah Suku Cadang"
                    className="input text-xs p-2 rounded-lg border-solid border border-gray-400 w-full mr-2"
                  />
                  <input
                    type="text"
                    name="diskon"
                    value={suku_cadang.diskon}
                    onChange={(e) => handleNewSukuCadangChange(index, e)}
                    placeholder="Diskon Suku Cadang"
                    className="input text-xs p-2 rounded-lg border-solid border border-gray-400 w-full mr-2"
                  />
                </div>
              </div>
            ))}
            {newSukuCadang.length > 0 && (
              <div className="flex items-center w-full">
                <button
                  className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
                  onClick={handleSaveNewSukuCadang}
                >
                  Simpan
                </button>
              </div>
            )}
            {transaksi.transaksi_suku_cadang.map((transaksi_suku_cadang) => (
              <div key={transaksi_suku_cadang.id_transaksi} className="py-2">
                {editableSukuCadangId === transaksi_suku_cadang.id_transaksi ? (
                  <div className="flex gap-2 w-full">
                    <select
                      type="text"
                      name="id_suku_cadang"
                      value={transaksi_suku_cadang.id_suku_cadang}
                      onChange={(e) =>
                        handleSukuCadangChange(
                          e,
                          transaksi_suku_cadang.id_transaksi
                        )
                      }
                      placeholder="Pilih Suku Cadang"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    >
                      <option value="" disabled>
                        Pilih Suku Cadang
                      </option>
                      {sukucadang.map((sc) => (
                        <option
                          key={sc.id_suku_cadang}
                          value={sc.id_suku_cadang}
                        >
                          {sc.nama_produk} - {sc.stok}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      name="jumlah_suku_cadang"
                      value={transaksi_suku_cadang.jumlah_suku_cadang}
                      onChange={(e) =>
                        handleSukuCadangChange(
                          e,
                          transaksi_suku_cadang.id_transaksi
                        )
                      }
                      placeholder="Jumlah Suku Cadang"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    />
                    <input
                      type="text"
                      name="harga"
                      value={
                        sukucadang.find(
                          (s) =>
                            s.id_suku_cadang ===
                            transaksi_suku_cadang.id_suku_cadang
                        )?.harga_jual ||
                        formatCurrency(transaksi_suku_cadang.harga)
                      }
                      readOnly
                      placeholder="Harga"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    />
                    <input
                      type="text"
                      name="diskon"
                      value={transaksi_suku_cadang.diskon}
                      onChange={(e) =>
                        handleSukuCadangChange(
                          e,
                          transaksi_suku_cadang.id_transaksi
                        )
                      }
                      placeholder="Diskon Suku Cadang"
                      className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                    />
                    <div className="w-1/5 flex items-center justify-start">
                      <button
                        className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
                        onClick={() =>
                          updateSukuCadang(transaksi_suku_cadang.id_transaksi)
                        }
                        disabled={!isEditable}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center w-full">
                    <span className="w-4/5 text-xs py-2 px-1 rounded-lg border-solid border border-gray-400">
                      Suku Cadang:{" "}
                      {sukucadang.find(
                        (s) =>
                          s.id_suku_cadang ===
                          transaksi_suku_cadang.id_suku_cadang
                      )?.nama_produk || "Tidak ditemukan"}
                    </span>
                    <span className="w-4/5 text-xs py-2 px-1 rounded-lg border-solid border border-gray-400">
                      Jumlah Suku Cadang:{" "}
                      {transaksi_suku_cadang.jumlah_suku_cadang}
                    </span>
                    <span className="w-4/5 text-xs py-2 px-1 rounded-lg border-solid border border-gray-400">
                      Harga:{" "}
                      {sukucadang.find(
                        (s) =>
                          s.id_suku_cadang ===
                          transaksi_suku_cadang.id_suku_cadang
                      )
                        ? formatCurrency(
                            transaksi_suku_cadang.harga_jual ||
                              transaksi_suku_cadang.harga
                          )
                        : ""}
                    </span>

                    <span className="w-4/5 text-xs py-2 px-1 rounded-lg border-solid border border-gray-400">
                      Diskon: {formatCurrency(transaksi_suku_cadang.diskon)}
                    </span>
                    <div className="flex space-x-2 w-1/5">
                      <MdEdit
                        className="text-[#F08519] cursor-pointer"
                        disabled={!isEditable}
                        onClick={() =>
                          editTransaksiSukuCadang(
                            transaksi_suku_cadang.id_transaksi
                          )
                        }
                      />
                      <MdDelete
                        className="text-[#E62129] cursor-pointer"
                        disabled={!isEditable}
                        onClick={() =>
                          deleteTransaksiSukuCadang(
                            transaksi_suku_cadang.id_transaksi
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* TRANSAKSI JASA */}
          <div className="mx-4 my-2 text-sm">
            <h1 className="uppercase text-lg font-bold">Transaksi Jasa</h1>
            <button
              className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
              onClick={handleAddJasa}
              disabled={!isEditable}
            >
              Tambah Jasa
            </button>
            <div className="mt-2 w-full">
              {newJasa.length > 0 &&
                newJasa.map((item, index) => (
                  <div key={index} className="mb-2 w-full gap-2">
                    <div className="flex justify-between items-center gap-2 pb-2 w-full">
                      <select
                        name="id_jasa"
                        value={item.id_jasa}
                        onChange={(e) => handleNewJasaChange(index, e)}
                        placeholder="Pilih Jasa"
                        className="input w-full text-xs p-2 rounded-lg border-solid border border-gray-400"
                      >
                        <option value="" disabled>
                          Pilih Jasa
                        </option>
                        {jasa.map((j) => (
                          <option key={j.id_jasa} value={j.id_jasa}>
                            {j.jasa}
                          </option>
                        ))}
                      </select>
                      <IoIosAddCircle
                        size={30}
                        className="text-[#F08519] cursor-pointer"
                        onClick={() => setShowAddJasaInput(true)}
                        disabled={!isEditable}
                      >
                        Tambah Jasa Baru
                      </IoIosAddCircle>
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <input
                        type="text"
                        name="harga"
                        value={item.harga}
                        onChange={(e) => handleNewJasaChange(index, e)}
                        placeholder="Harga"
                        className="input w-full text-xs p-2 rounded-lg border-solid border border-gray-400"
                      />
                      <input
                        type="text"
                        name="diskon"
                        value={item.diskon}
                        onChange={(e) => handleNewJasaChange(index, e)}
                        placeholder="Diskon Jasa"
                        className="input w-full text-xs p-2 rounded-lg border-solid border border-gray-400"
                      />
                      <button
                        className="bg-[#2AB74A] text-xs text-white w-fit p-1 rounded-md"
                        onClick={handleSaveNewJasa}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                ))}
              {showAddJasaInput && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-bold">Tambah Jasa Baru</h2>
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={() => setShowAddJasaInput(false)}
                      >
                        <IoMdClose size={24} />
                      </button>
                    </div>
                    <div className="py-2 flex gap-2">
                      <input
                        type="text"
                        className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                        value={AddJasa}
                        onChange={(e) => setAddJasa(e.target.value)}
                        placeholder="Nama Jasa Baru"
                      />
                      <button
                        className="bg-[#F08519] px-4 py-3 text-xs text-center rounded-lg font-bold text-white"
                        onClick={saveJasa}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {transaksi.transaksi_jasa.map((transaksi_jasa) => (
                <div key={transaksi_jasa.id_transaksi} className="py-2">
                  {editableJasaId === transaksi_jasa.id_transaksi ? (
                    <div className="flex gap-2 w-full">
                      <select
                        name="id_jasa"
                        value={transaksi_jasa.id_jasa}
                        onChange={(e) =>
                          handleJasaChange(e, transaksi_jasa.id_transaksi)
                        }
                        placeholder="Pilih Jasa"
                        className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                      >
                        <option value="" disabled>
                          Pilih Jasa
                        </option>
                        {jasa.map((j) => (
                          <option key={j.id_jasa} value={j.id_jasa}>
                            {j.jasa}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        name="harga"
                        value={transaksi_jasa.harga}
                        onChange={(e) =>
                          handleJasaChange(e, transaksi_jasa.id_transaksi)
                        }
                        placeholder="Harga"
                        className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                      />
                      <input
                        type="text"
                        name="diskon"
                        value={transaksi_jasa.diskon}
                        onChange={(e) =>
                          handleJasaChange(e, transaksi_jasa.id_transaksi)
                        }
                        placeholder="Diskon Jasa"
                        className="input w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400"
                      />
                      <div className="w-1/5 flex items-center justify-start">
                        <button
                          className="bg-[#2AB74A] text-xs text-white p-1 rounded-md"
                          onClick={() =>
                            updateJasa(transaksi_jasa.id_transaksi)
                          }
                          disabled={!isEditable}
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center w-full">
                      <span className="w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400">
                        Jasa:{" "}
                        {jasa.find((j) => j.id_jasa === transaksi_jasa.id_jasa)
                          ?.jasa || "Tidak ditemukan"}
                      </span>
                      <span className="w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400">
                        Harga: {formatCurrency(transaksi_jasa.harga)}
                      </span>
                      <span className="w-4/5 text-xs p-2 rounded-lg border-solid border border-gray-400">
                        Diskon: {formatCurrency(transaksi_jasa.diskon)}
                      </span>
                      <div className="flex space-x-2 w-1/5">
                        <MdEdit
                          className="text-[#F08519] cursor-pointer"
                          onClick={() =>
                            editTransaksiJasa(transaksi_jasa.id_transaksi)
                          }
                        />
                        <MdDelete
                          className="text-[#E62129] cursor-pointer"
                          onClick={() =>
                            deleteTransaksiJasa(transaksi_jasa.id_transaksi)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Pegawai<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_pegawai"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_pegawai}
                onChange={handleChange}
                placeholder="Pegawai yang mengerjakan"
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Pilih Pegawai
                </option>
                {pegawai.map((p) => (
                  <option key={p.id_pegawai} value={p.id_pegawai}>
                    {p.nama}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* DETAIL TRANSAKSI */}
          <h1 className="uppercase text-lg py-2 mx-4 font-bold">
            Detail Transaksi
          </h1>
          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Total<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <input
                type="text"
                name="total"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={formatCurrency(transaksi.total)}
                onChange={handleChange}
                placeholder="Total"
                disabled={!isEditable}
              />
            </div>
          </div>

          {/* <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Metode<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_metode"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_metode}
                onChange={handleChange}
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Pilih Metode Pembayaran
                </option>
                <option value="1">Tunai</option>
                <option value="2">Non Tunai</option>
              </select>
            </div>
          </div> */}

          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Metode<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_metode"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_metode}
                onChange={handleChange}
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Pilih Metode
                </option>
                {metode.map((m) => (
                  <option key={m.id_metode} value={m.id_metode}>
                    {m.metode}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Bank<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_bank"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_bank}
                onChange={handleChange}
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Pilih Bank
                </option>
                {bank.map((b) => (
                  <option key={b.id_bank} value={b.id_bank}>
                    {b.bank}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Lunas<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_lunas"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_lunas}
                onChange={handleChange}
                placeholder="Lunas"
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Kelunasan
                </option>
                <option value="1">Lunas</option>
                <option value="2">Belum Lunas</option>
              </select>
            </div>
          </div>
          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              Status<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <select
                name="id_status"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_status}
                onChange={handleChange}
                placeholder="Lunas"
                disabled={!isEditable}
              >
                <option value="" disabled>
                  Pilih Status
                </option>
                <option value="1">On Progress</option>
                <option value="2">Finish</option>
              </select>
            </div>
          </div>

          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              No. Invoice<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <input
                type="text"
                name="id_invoice"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_invoice}
                readOnly={true}
              />
            </div>
          </div>
          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">
              No. Nota<sup className="text-[#E62129]">*</sup>
            </label>
            <div className="py-2">
              <input
                type="text"
                name="id_nota"
                className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                value={transaksi.id_nota}
                placeholder="Nomor Nota"
                readOnly={true}
              />
            </div>
          </div>
          {isEditable ? (
            <div className="py-4 px-4">
              <button
                type="submit"
                className="w-full p-2 bg-[#F08519] text-white font-semibold rounded-lg"
                onClick={updateTransaksi}
              >
                Update Transaksi
              </button>
            </div>
          ) : (
            transaksi &&
            transaksi.id_status === 1 && (
              <div className="py-4 px-4">
                <button
                  type="button"
                  onClick={toggleEdit}
                  className="w-full p-2 bg-[#F08519] text-white font-semibold rounded-lg"
                >
                  Edit Transaksi
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTransaksi;
