import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js"

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(filteredUsers.length / perpage);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [users, roles, searchTerm]);

  const getUsers = async () => {
    try {
      const response = await axios.get(`${url}/user`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setUsers(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(`${url}/role`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setRoles(response.data);
      }
    } catch (err) {
      console.error(err);    }
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const getRoleName = (id_role) => {
    const role = roles.find((role) => role.id_role === id_role);
    return role ? role.role : "Unknown";
  };

  const filterUsers = () => {
    const filtered = users.filter((user) => {
      const roleName = getRoleName(user.id_role) || "";

      return (
        (user.nama_lengkap || "").toLowerCase().includes(searchTerm) ||
        (user.username || "").toLowerCase().includes(searchTerm) ||
        (user.email || "").toLowerCase().includes(searchTerm) ||
        (user.no_telepon
          ? user.no_telepon.toString().toLowerCase()
          : ""
        ).includes(searchTerm) ||
        roleName.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredUsers(filtered);
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Data Pengguna</p>
        </div>
      </div>

      <div className="flex mx-2 md:mx-6 p2-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative align-middle items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perpage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari"
            className="lg:w-[25%] w-[50%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
        <div className="text-xs pt-6">
          <Link
            to="/user/add"
            className="button bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
          >
            Tambah
          </Link>
        </div>
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Username
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No. Telepon
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Alamat
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.length > 0 ?
                filteredUsers
                  .slice(pageclick, pageclick + perpage)
                  .map((user, index) => (
                    <tr
                      key={user.id_user}
                      className="odd:bg-white even:bg-[#F9F9F9] border-b"
                    >
                      <td className="px-6 py-4">{index + 1 + pageclick}</td>
                      <td className="px-6 py-4">{getRoleName(user.id_role)}</td>
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {user.nama_lengkap}
                      </td>
                      <td className="px-6 py-4">{user.username}</td>
                      <td className="px-6 py-4">{user.email}</td>
                      <td className="px-6 py-4">{user.no_telepon}</td>
                      <td className="px-6 py-4">{user.alamat}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center justify-center">
                          <Link to={`/user/edit/${user.id_user}`} title="Edit">
                            <MdEdit size={20} className="text-[#F08519]" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )): (
                    <tr>
                      <td colSpan="8" className="px-6 py-4 text-center text-gray-500">
                        Belum Ada Pengguna
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end mt-4 mx-6 text-xs">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;