import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from "../context/url.js";
import { formatCurrency, parseCurrency, handleChange } from "../context/formatCurrency.js"; 

const AddManajemenSD = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [Tanggal, setTanggal] = useState("");
  const [Bensin, setBensin] = useState("");
  const [Listrik, setListrik] = useState("");
  const [SukuCadang, setSukuCadang] = useState("");
  const [Kebersihan, setKebersihan] = useState("");
  const [Gaji, setGaji] = useState("");
  const [Total, setTotal] = useState(0);

  useEffect(() => {
    const calculateTotal = () => {
      const total =
        parseFloat(parseCurrency(Bensin) || 0) +
        parseFloat(parseCurrency(Listrik) || 0) +
        parseFloat(parseCurrency(SukuCadang) || 0) +
        parseFloat(parseCurrency(Kebersihan) || 0) +
        parseFloat(parseCurrency(Gaji) || 0);
      setTotal(total);
    };

    calculateTotal();
  }, [Bensin, Listrik, SukuCadang, Kebersihan, Gaji]);

  const saveSumberDaya = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/msd`, {
        tanggal: Tanggal,
        pembelian_bensin: parseCurrency(Bensin),
        listrik: parseCurrency(Listrik),
        pembelian_suku_cadang: parseCurrency(SukuCadang),
        kebersihan: parseCurrency(Kebersihan),
        gaji: parseCurrency(Gaji),
      }, {
        headers: { access_token: localStorage.getItem('access_token') },
      });
      navigate('/sumber-daya');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };
  
  return (
    <div>
      <div className="title mb-6">
        <div className="bg-white p-6 shadow-b-lg">
          <h1 className="text-black font-bold text-md">Manajemen Sumber Daya</h1>
        </div>
      </div>

      <div className="mx-2 md:mx-4">
        <h1 className="text-black font-bold text-xl">Tambah Sumber Daya</h1>
      </div>

      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveSumberDaya}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Tanggal<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="date"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Harga Pembelian Bensin<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Bensin}
                  required
                  onChange={handleChange(setBensin)}
                  placeholder="Harga Pembelian Bensin"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Harga Listrik<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  required
                  value={Listrik}
                  onChange={handleChange(setListrik)}
                  placeholder="Biaya Listrik"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Harga Pembelian Suku Cadang<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  required
                  value={SukuCadang}
                  onChange={handleChange(setSukuCadang)}
                  placeholder="Harga Pembelian Suku Cadang"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Lain - lain<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  required
                  value={Kebersihan}
                  onChange={handleChange(setKebersihan)}
                  placeholder="Harga Peralatan Kebersihan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Gaji<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  required
                  value={Gaji}
                  onChange={handleChange(setGaji)}
                  placeholder="Total Gaji"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Total<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={formatCurrency(Total)}
                  readOnly
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddManajemenSD;
