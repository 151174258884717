import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { url } from "../context/url.js";
import { formatCurrency } from "../context/formatCurrency.js"; 

const ManajemenSD = () => {
  const [sumberDaya, setSumberDaya] = useState([]);
  const [filteredSumberDaya, setFilteredSumberDaya] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const pageClick = pageNumber * perPage;
  const pageCount = Math.ceil(filteredSumberDaya.length / perPage);

  useEffect(() => {
    getSumberDaya();
  }, []);

  useEffect(() => {
    filterSumberDaya();
  }, [sumberDaya, searchTerm]);

  const getSumberDaya = async () => {
    try {
      const response = await axios.get(`${url}/msd/tanggal`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setSumberDaya(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const filterSumberDaya = () => {
    const filtered = sumberDaya.filter((item) =>
      item.tanggal.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSumberDaya(filtered);
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handlePerPageChange = (e) => {
    setPerPage(Number(e.target.value));
    setPageNumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPageNumber(0);
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Manajemen Sumber Daya</p>
        </div>
      </div>

      <div className="flex mx-2 md:mx-6 p-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari Tanggal..."
            className="w-[25%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
        <div className="text-xs pt-6">
          <Link
            to="/sumber-daya/add"
            className="bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
          >
            Tambah
          </Link>
        </div>
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">No</th>
                  <th scope="col" className="px-6 py-3">Tanggal</th>
                  <th scope="col" className="px-6 py-3">Pembelian Bensin</th>
                  <th scope="col" className="px-6 py-3">Listrik</th>
                  <th scope="col" className="px-6 py-3">Pembelian Suku Cadang</th>
                  <th scope="col" className="px-6 py-3">Lain - lain</th>
                  <th scope="col" className="px-6 py-3">Total Gaji</th>
                  <th scope="col" className="px-6 py-3">Total</th>
                  <th scope="col" className="px-6 py-3">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {filteredSumberDaya.length > 0 ? (
                  filteredSumberDaya.slice(pageClick, pageClick + perPage).map((item, index) => (
                    <tr key={item.id_msd} className="odd:bg-white even:bg-[#F9F9F9] border-b">
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{item.tanggal}</td>
                      <td className="px-6 py-4">{formatCurrency(item.pembelian_bensin)}</td>
                      <td className="px-6 py-4">{formatCurrency(item.listrik)}</td>
                      <td className="px-6 py-4">{formatCurrency(item.pembelian_suku_cadang)}</td>
                      <td className="px-6 py-4">{formatCurrency(item.kebersihan)}</td>
                      <td className="px-6 py-4">{formatCurrency(item.gaji)}</td>
                      <td className="px-6 py-4">{formatCurrency(item.total)}</td>
                      <td className="px-6 py-4">
                        <div className="flex">
                          <Link to={`/sumber-daya/detail/${item.id_msd}`} title="Detail">
                            <IoMdEye size={20} className="text-[#E62129]" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="px-6 py-4 text-center text-gray-500">Belum Ada Data Sumber Daya</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end mt-4 mx-6 text-xs">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={"px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"}
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={"px-3 py-1 border border-gray-300 rounded-md bg-white"}
            nextClassName={"px-3 py-1 border border-gray-300 rounded-md bg-white"}
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      </div>
    </div>
  );
};

export default ManajemenSD;
