import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from "../context/url.js"

const KlaimGaransi = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [Nota, setNota] = useState("");
  const [Deskripsi, setDeskripsi] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const saveGaransi = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/garansi/tambah`, {
        id_user: selectedUser,
        id_nota: Nota,
        deskripsi: Deskripsi,
      }, {
        headers: { access_token: localStorage.getItem('access_token') },
      });
      navigate('/garansi');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${url}/user`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setUsers(response.data);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getUser();
  }, []);

  return (
    <div>
      <div className="mx-2 md:mx-4 px-2 md:px-4 mt-6">
        <h1 className="text-black font-bold text-xl">Form Pengajuan Klaim Garansi</h1>
      </div>

      <div className="flex justify-center">
        <div className="mx-2 md:mx-4 my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveGaransi}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <select
                  name="id_user"
                  value={selectedUser}
                  required
                  onChange={(e) => setSelectedUser(e.target.value)}
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  placeholder="Pelanggan"
                >
                  <option value="" disabled>Pilih Pelanggan</option>
                  {users.map((user) => (
                    <option key={user.id_user} value={user.id_user}>
                      {user.nama_lengkap}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Nota<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="number"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nota}
                  required
                  onChange={(e) => setNota(e.target.value)}
                  placeholder="Nomor Nota"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Deskripsi Kerusakan<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <textarea
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Deskripsi}
                  required
                  onChange={(e) => setDeskripsi(e.target.value)}
                  placeholder="Tulis Kerusakan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KlaimGaransi;