import React, { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import Register from './pages/Register';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import User from './pages/User';
import { AuthContext } from './context/authcContext';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import DashboardEx from './pages/DashboardEx';
import DashboardIn from './pages/DashboardIn';
import UserProfile from './pages/UserProfile';
import AddSukuCadang from './pages/AddSukuCadang';
import EditSukuCadang from './pages/EditSukuCadang';
import EditTransaksi from './pages/EditTransaksi';
import Sidebar from './components/Sidebar';
import SukuCadang from './pages/SukuCadang';
import Supplier from './pages/Supplier';
import AddSupplier from './pages/AddSupplier';
import EditSupplier from './pages/EditSupplier';
import Pegawai from './pages/Pegawai';
import AddPegawai from './pages/AddPegawai';
import EditPegawai from './pages/EditPegawai';
import Gaji from './pages/Gaji';
import AddGaji from './pages/AddGaji';
import KlaimGaransi from './pages/KlaimGaransi';
import Garansi from './pages/Garansi';
import AddGaransi from './pages/AddGaransi';
import AddTransaksiGaransi from './pages/AddTransaksiGaransi';
import TransaksiGaransi from './pages/TransaksiGaransi';
import AntrianBooking from './pages/AntrianBooking';
import Booking from './pages/Booking';
import Transaksi from './pages/Transaksi';
import AntrianBookingUser from './pages/AntrianBookingUser';
import AddTransaksiOffline from './pages/AddTransaksiOffline';
import AddManajemenSD from './pages/AddManajemenSD';
import ManajemenSD from './pages/ManajemenSD';
import Riwayat from './pages/Riwayat';
import RiwayatUser from './pages/RiwayatUser';
import LaporanKeuangan from './pages/LaporanKeuangan';
import DetailLaporanKeuangan from './pages/DetailLaporanKeuangan';
import BookingUser from './pages/BookingUser';
import Nota from './pages/Nota';
import Katalog from './pages/Katalog';
import ProtectedRoute from './context/ProtectedRoute';
import PageNotFound from './pages/PageNotFound';
import AddBookingOffline from './pages/AddBookingOffline';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import DetailGaji from './pages/DetailGaji';
import DetailManajemenSD from './pages/DetailManajemenSD';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

const App = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className='app'>
      <div className='w-full'>
          <Routes>
            <Route path="/" element={<Layout><Home /></Layout>}/>
            <Route path="*" element={<Layout><PageNotFound/></Layout>} />
            <Route path="/register" element={<Layout><Register /></Layout>} />
            <Route path="/login" element={<Layout><Login /></Layout>} />
            {/* <Route path="/reset" element={<Layout><ResetPassword /></Layout>} /> */}
            <Route path="/katalog" element={<Layout><Katalog /></Layout>} />
            <Route path="/forgot-password" element={<Layout><ForgotPassword /></Layout>} />
            <Route path="/forgot-password/reset" element={<Layout><ResetPassword /></Layout>} />
            {/* HARUS LOGIN */}
            <Route
            path={`/antrian/${currentUser?.id}`}
            element={<ProtectedRoute element={<Layout><AntrianBookingUser /></Layout>} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Layout><DashboardEx /></Layout>} />}
            />
            <Route
              path={`/profil/${currentUser?.id}`}
              element={<ProtectedRoute element={<Layout><UserProfile /></Layout>} />}
            />
            <Route
              path={`/klaim-garansi/${currentUser?.id}`}
              element={<ProtectedRoute element={<Layout><KlaimGaransi /></Layout>} />}
            />
            <Route
              path={`/booking/${currentUser?.id}`}
              element={<ProtectedRoute element={<Layout>< BookingUser /></Layout>} />}
            />
            <Route
              path={`/riwayat-aktivitas/${currentUser?.id}`}
              element={<ProtectedRoute element={<Layout>< RiwayatUser /></Layout>} />}
            />
            {/* INTERNAL */}
            {currentUser && ( currentUser.role === 1 || currentUser.role === 2 || currentUser.role === 3) && (
              <React.Fragment>
                <Route path="/dashboardinter" element={<Sidebar><DashboardIn/></Sidebar>}/>
                <Route path="/riwayat" element={<Sidebar><Riwayat/></Sidebar>}/>
              </React.Fragment>
            )}
            {/* MANAJER & KASIR */}
            {currentUser && ( currentUser.role === 1 || currentUser.role === 3) && (
              <React.Fragment>
                <Route path="/user" element={ <Sidebar><User/></Sidebar>} />
                <Route path="/user/add" element={ <Sidebar><AddUser/></Sidebar>} />
                <Route path="/user/edit/:id" element={ <Sidebar><EditUser/></Sidebar>} />
                <Route path="/transaksi" element={<Sidebar><Transaksi /></Sidebar>} />
                <Route path="/transaksi/add" element={<Sidebar><AddTransaksiOffline /></Sidebar>} />
                <Route path="/transaksi/edit/:id" element={<Sidebar><EditTransaksi /></Sidebar>} />
                <Route path="/nota/:id" element={<Sidebar><Nota /></Sidebar>} />
                <Route path="/antrian/booking" element={<Sidebar><AntrianBooking /></Sidebar>} />
                <Route path="/booking" element={<Sidebar><Booking /></Sidebar>} />
                <Route path="/booking/add" element={<Sidebar><AddBookingOffline /></Sidebar>} />
              </React.Fragment>
            )}
            {/* MANAJER & PETUGAS TOKO */}
            {currentUser && ( currentUser.role === 1 || currentUser.role === 2) && (
              <React.Fragment>
                <Route path="/suku-cadang" element={<Sidebar><SukuCadang /></Sidebar>} />
                <Route path="/suku-cadang/add" element={<Sidebar><AddSukuCadang /></Sidebar>} />
                <Route path="/suku-cadang/edit/:id" element={<Sidebar><EditSukuCadang /></Sidebar>} />
                <Route path="/supplier" element={<Sidebar><Supplier /></Sidebar>} />
                <Route path="/supplier/add" element={<Sidebar><AddSupplier /></Sidebar>} />
                <Route path="/supplier/edit/:id" element={<Sidebar><EditSupplier /></Sidebar>} />
              </React.Fragment>
            )}
            {/* MANAJER */}
            {currentUser && ( currentUser.role === 1 ) && (
              <React.Fragment>
                <Route path="/pegawai" element={<Sidebar><Pegawai /></Sidebar>} />
                <Route path="/pegawai/add" element={<Sidebar><AddPegawai /></Sidebar>} />
                <Route path="/pegawai/edit/:id" element={<Sidebar><EditPegawai /></Sidebar>} />
                <Route path="/gaji" element={<Sidebar><Gaji /></Sidebar>} />
                <Route path="/gaji/add" element={<Sidebar><AddGaji /></Sidebar>} />
                <Route path="/gaji/detail/:id" element={<Sidebar><DetailGaji /></Sidebar>} />
                <Route path="/garansi" element={<Sidebar><Garansi /></Sidebar>} />
                <Route path="/garansi/add" element={<Sidebar><AddGaransi /></Sidebar>} />
                <Route path="/transaksi/garansi" element={<Sidebar><TransaksiGaransi /></Sidebar>} />
                <Route path="/transaksi/garansi/add/:id" element={<Sidebar><AddTransaksiGaransi /></Sidebar>} />
                <Route path="/sumber-daya" element={<Sidebar><ManajemenSD /></Sidebar>} />
                <Route path="/sumber-daya/add" element={<Sidebar><AddManajemenSD /></Sidebar>} />
                <Route path="/sumber-daya/detail/:id" element={<Sidebar><DetailManajemenSD /></Sidebar>} />
                <Route path="/laporan" element={<Sidebar><LaporanKeuangan /></Sidebar>} />
                <Route path="/laporan/detail/:id" element={<Sidebar><DetailLaporanKeuangan /></Sidebar>} />
              </React.Fragment>
            )}
          </Routes>
      </div>
    </div>
  );
}

export default App;