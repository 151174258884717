import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js";

const DetailGaji = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [Nama, setNama] = useState("");
  const [Tgl_Gaji, setTgl_gaji] = useState("");
  const [Gaji, setGaji] = useState("");
  const { id } = useParams();
  const [GetNama, setGetNama] = useState([]);

  useEffect(() => {
    getNama();
  }, []);

  useEffect(() => {
    const getGajiById = async () => {
      try {
        const response = await axios.get(
          `${url}/gaji/${id}`,
          {
            headers: { access_token: localStorage.getItem("access_token") },
          }
        );
        setNama(response.data.id_pegawai);
        setTgl_gaji(response.data.tanggal_gaji);
        setGaji(response.data.gaji);
      } catch (err) {
        console.error(err)
      }
    };
    getGajiById();
  }, [id]);

  const updateGaji = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/gaji/${id}`,
        {
          id_pegawai: Nama,
          tgl_gaji: Tgl_Gaji,
          gaji_per_pegawai: Gaji,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );    
      navigate("/gaji");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getNama = async () => {
    try {
      const response = await axios.get(`${url}/pegawai/aktif`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setGetNama(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div>
        <div className="title mb-6">
          <div className="bg-white p-6 shadow-b-lg">
            <h1 className="text-black font-bold text-md">Data Gaji</h1>
          </div>
        </div>

        <div className="mx-2 md:mx-4">
          <h1 className="text-black font-bold text-xl">Detail Gaji</h1>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
            <form onSubmit={updateGaji}>
              <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
                <p
                  className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
                >
                  {error}
                </p>
              </div>
              <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Nama Pegawai<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <select
                  type="text"
                  readOnly
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Nama}
                  onChange={(e) => setNama(e.target.value)}
                  placeholder="Nama Pegawai"
                >
                  <option value="" disabled>
                    Pilih Pegawai
                  </option>
                  {GetNama.map((pegawai) => (
                    <option
                      key={pegawai.id_pegawai}
                      value={pegawai.id_pegawai}
                    >
                      {pegawai.nama}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Tanggal Gaji<sup className="text-[#E62129]">*</sup></label>
              <div className="py-2">
                <input
                  type="date"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={Tgl_Gaji}
                  readOnly
                  onChange={(e) => setTgl_gaji(e.target.value)}
                  placeholder="Tanggal Gaji"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">Gaji</label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={formatCurrency(Gaji)}
                  readOnly
                  onChange={(e) => setGaji(e.target.value)}
                  placeholder="Gaji untuk pegawai"
                />
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailGaji
