import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { url } from "../context/url.js"
import { formatCurrency } from "../context/formatCurrency.js";

const LaporanKeuangan = () => {
  const [laporanKeuangan, setLaporanKeuangan] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tanggalAwal, setTanggalAwal] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;

  useEffect(() => {
    if (tanggalAwal && tanggalAkhir) {
      getLaporanKeuangan();
    }
  }, [tanggalAwal, tanggalAkhir]);

  const getLaporanKeuangan = async () => {
    try {
      const response = await axios.post(
        `${url}/laporan/keuangan`,
        {
          tanggalawal: tanggalAwal,
          tanggalakhir: tanggalAkhir,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );

      if (response.data.err) {
        alert(response.data.err);
      } else {
        // Remove duplicates
        const uniqueLaporanKeuangan = response.data.reduce((acc, item) => {
          if (!acc.some((existingItem) => existingItem.id_lk === item.id_lk)) {
            acc.push(item);
          }
          return acc;
        }, []);
        setLaporanKeuangan(uniqueLaporanKeuangan);
      }
    } catch (err) {
      console.error(err);
        }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleTanggalAwalChange = (e) => {
    setTanggalAwal(e.target.value);
  };

  const handleTanggalAkhirChange = (e) => {
    setTanggalAkhir(e.target.value);
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchClick = () => {
    if (tanggalAwal && tanggalAkhir) {
      getLaporanKeuangan();
    } else {
      alert("Silakan pilih tanggal awal dan akhir.");
    }
  };

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  const filteredLaporanKeuangan = laporanKeuangan.filter((item) =>
    item.tanggal.toLowerCase().includes(searchTerm)
  );

  const countpage = Math.ceil(filteredLaporanKeuangan.length / perpage);

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Laporan Keuangan</p>
        </div>
      </div>

      <div className="m-2 md:m-6 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Periode</h1>
        <div className="flex flex-col md:flex-row md:justify-between  my-2">
          <div className="search">
            <div className="flex-grow relative flex justify-start md:justify-end">
              <input
                type="text"
                placeholder="Cari"
                className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hidden md:block">
                <IoMdSearch size={15} className="text-[#777777]" />
              </span>
            </div>
          </div>

          <div className="flex-grow relative flex justify-start md:justify-end">
            <h1 className="md:hidden flex p-2 text-base">Tanggal</h1>
          </div>


          <div className="tanggal flex items-center">
            <input
              type="date"
              className="bg-[#F7F7F7] border border-gray-400 text-gray-400 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={tanggalAwal}
              onChange={handleTanggalAwalChange}
              placeholder="Tanggal Awal"
            />
            <input
              type="date"
              className="bg-[#F7F7F7] border border-gray-400 text-gray-400 px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs ml-2"
              value={tanggalAkhir}
              onChange={handleTanggalAkhirChange}
              placeholder="Tanggal Akhir"
            />
            <button
              className="ml-2 px-4 py-2 bg-[#F08519] text-white text-xs rounded-lg hover:bg-[#d0740d]"
              onClick={handleSearchClick}
            >
              Cari
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-2 mx-2 md:mx-6 p2-4 mt-4 relative align-middle items-center">
        <p className="text-xs">Show</p>
        <select
          className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
          value={perpage}
          onChange={handlePerPageChange}
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <p className="text-xs">entries</p>
      </div>

      <div className="flex justify-center my-4">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
          <div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredLaporanKeuangan.length > 0 ? (
                  filteredLaporanKeuangan
                    .slice(pageclick, pageclick + perpage)
                    .map((item, index) => (
                      <tr
                        key={item.id_lk}
                        className="odd:bg-white even:bg-[#F9F9F9] border-b"
                      >
                        <td className="px-6 py-4">{index + 1 + pageclick}</td>
                        <td className="px-6 py-4">{item.tanggal}</td>
                        <td className="px-6 py-4">{formatCurrency(item.total)}</td>
                        <td className="px-6 py-4">
                          <div className="flex justify-start">
                            <Link
                              to={`/laporan/detail/${item.id_lk}`}
                              title="Lihat"
                              state={{
                                tanggal: item.tanggal,
                                idMSD: item.id_msd,
                              }}
                            >
                              <IoMdEye size={20} className="text-[#E62129]" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      Masukkan periode tanggal
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-4 mx-6 text-xs">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={countpage}
          onPageChange={changePage}
          containerClassName={"flex items-center space-x-2"}
          pageClassName={
            "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
          }
          pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
          previousClassName={
            "px-3 py-1 border border-gray-300 rounded-md bg-white"
          }
          nextClassName={"px-3 py-1 border border-gray-300 rounded-md bg-white"}
          activeClassName={"bg-[#F08519] text-white"}
          disabledClassName={"text-gray-300 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default LaporanKeuangan;