import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js";
import { formatCurrency } from "../context/formatCurrency.js";
import { Link } from "react-router-dom";

const Riwayat = () => {
  const [riwayat, setRiwayat] = useState([]);
  const [Status, setStatus] = useState([]);
  const [FilteredRiwayat, setFilteredRiwayat] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredRiwayat.length / perpage);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    getRiwayat();
    getStatus();
  }, []);

  useEffect(() => {
    filterRiwayat();
  }, [riwayat, Status, searchTerm]);

  const getRiwayat = async () => {
    try {
      const response = await axios.get(`${url}/riwayat/internal`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setRiwayat(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(`${url}/status/transaksi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatus(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const getStatusName = (id_status) => {
    const status = Status.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };

  const filterRiwayat = () => {
    const filtered = riwayat.filter((riwayat) => {
      const statusName = getStatusName(riwayat.id_status).toLowerCase();
      const tanggal = riwayat.tanggal
        ? riwayat.tanggal.toString().toLowerCase()
        : "";
      const idInvoice = riwayat.id_invoice
        ? riwayat.id_invoice.toString().toLowerCase()
        : "";
      const total = riwayat.total ? riwayat.total.toString().toLowerCase() : "";

      return (
        tanggal.includes(searchTerm) ||
        idInvoice.includes(searchTerm) ||
        total.includes(searchTerm) ||
        statusName.includes(searchTerm)
      );
    });
    setFilteredRiwayat(filtered);
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Riwayat Aktivitas</p>
        </div>
      </div>

      <div className="m-2 md:m-4 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Riwayat</h1>
        <div className="flex flex-col md:flex-row md:justify-between my-2 gap-3">
          <div className="search flex-grow md:flex-grow-0">
            <div className="relative flex justify-start md:justify-end">
              <input
                type="text"
                placeholder="Cari"
                className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-3 lg:px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hidden md:block">
                <IoMdSearch size={15} className="text-[#777777]" />
              </span>
            </div>
          </div>

          <div className="flex gap-2 items-center justify-start md:justify-end">
            <p className="text-xs">Show</p>
            <select
              className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={perpage}
              onChange={handlePerPageChange}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p className="text-xs">entries</p>
          </div>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
        <div className="flex justify-center my-4">
          <div className="my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No Invoice
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No Nota
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Target Pengerjaan
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredRiwayat.slice(pageclick, pageclick + perpage).map(
                  (riwayat, index) => (
                    <tr
                      key={riwayat.id_transaksi}
                      className="odd:bg-white even:bg-[#F9F9F9] border-b"
                    >
                      <td className="px-6 py-4">{index + 1 + pageclick}</td>
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {riwayat.tanggal}
                      </td>
                      <td className="px-6 py-4">
                        <a
                          href={`/transaksi/edit/${riwayat.id_transaksi}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Transaksi"
                          className="text-gray-500 underline hover:text-[#E62129]"
                        >
                          {riwayat.id_invoice}
                        </a>
                      </td>
                      {riwayat.id_nota ? (
                        <td className="px-6 py-4">{riwayat.id_nota}</td>
                      ) : (
                        <td className="px-6 py-4">Status On Progress</td>
                      )}
                      <td className="px-6 py-4">
                        {formatCurrency(riwayat.total)}
                      </td>
                      <td className="px-6 py-4 uppercase">
                        {getStatusName(riwayat.id_status)}
                      </td>
                      <td className="px-6 py-4">
                        {riwayat.id_nota
                          ? "Sudah Selesai"
                          : riwayat.hari_masuk < 0
                          ? "Sudah Melewati Target Hari"
                          : `${riwayat.hari_masuk} dari ${riwayat.target_hari} hari`}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end mt-4 text-xs mx-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>
      </div>
    </div>
  );
};

export default Riwayat;
