import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js";

const Booking = () => {
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const [idStatus, setIdStatus] = useState("");
  const [Status, setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [error, setError] = useState("");
  const [FilteredBooking, setFilteredBooking] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(FilteredBooking.length / perpage);

  useEffect(() => {
    getBooking();
    getStatus();
  }, []);

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    filterBooking();
  }, [booking, Status, searchTerm, startDate, endDate]);

  const getBooking = async () => {
    try {
      const response = await axios.get(`${url}/booking`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setBooking(response.data);
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(`${url}/status/booking`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatus(response.data);
      }
    } catch (error) {
      setError(error.response.data || "An error occurred.");
    }
  };

  const getStatusName = (id_status) => {
    const status = Status.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setPagenumber(0);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setPagenumber(0);
  };

  const filterBooking = () => {
    const filtered = booking.filter((booking) => {
      const statusName = getStatusName(booking.id_status).toLowerCase();
      const bookingDate = new Date(booking.tanggal_booking);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (
        (booking.nama_lengkap.toLowerCase().includes(searchTerm) ||
          booking.deskripsi.toLowerCase().includes(searchTerm) ||
          booking.plat_nomor.toString().toLowerCase().includes(searchTerm) ||
          booking.tanggal_booking
            .toString()
            .toLowerCase()
            .includes(searchTerm) ||
          booking.no_telepon.toString().toLowerCase().includes(searchTerm) ||
          statusName.includes(searchTerm)) &&
        (!start || bookingDate >= start) &&
        (!end || bookingDate <= end)
      );
    });
    setFilteredBooking(filtered);
  };

  const openModal = (booking) => {
    setSelectedBookingId(booking.id_booking);
    setIdStatus(booking.id_status);
    setShowModal(true);
  };

  const updateBooking = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${url}/booking/status/${selectedBookingId}`,
        {
          status: idStatus, // Pastikan nama parameter sesuai dengan backend
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );

      setShowModal(false);
      // Refresh data after update
      await getBooking();
      // Navigate to /transaksi only if idStatus is 1
      if (idStatus === 1) {
        navigate("/transaksi");
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="w-full h-screen">
      <div className="title mb-2">
        <div className="bg-white p-4 shadow-b-lg">
          <p className="text-black font-bold text-md">Booking Service</p>
        </div>
      </div>

      <div className="m-2 md:m-6 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Periode</h1>

        <div className="flex flex-col md:flex-row md:justify-start gap-4 my-2">
          <div className="start-date flex w-full md:w-auto">
            <input
              type="date"
              className="w-full md:w-auto bg-[#F7F7F7] border border-gray-400 text-gray-700 px-7 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>

          <span className="text-gray-400 hidden md:block"> - </span>

          <div className="end-date flex w-full md:w-auto">
            <input
              type="date"
              className="w-full md:w-auto bg-[#F7F7F7] border border-gray-400 text-gray-700 px-7 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      </div>
      <div className="flex mx-2 md:mx-6 p2-4 mt-4 justify-between">
        <div className="flex gap-2 mx-auto relative align-middle items-center">
          <p className="text-xs">Show</p>
          <select
            className="w-fit bg-gray-200 border border-gray-200 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={perpage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p className="text-xs">entries</p>
        </div>
        <div className="flex-grow relative flex justify-end">
          <input
            type="text"
            placeholder="Cari"
            className="lg:w-[25%] w-[50%] bg-gray-200 border border-gray-200 text-gray-700 px-2 py-1 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <IoMdSearch size={15} className="text-[#777777]" />
          </span>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F7]">
      <div className="text-xs pt-6">
          <Link
            to="/booking/add"
            className="button bg-[#F08519] w-fit rounded-md font-medium p-2 mx-2 md:mx-4 py-3 text-white text-xs"
          >
            Tambah
          </Link>
        </div>
        <div className="flex justify-center my-4">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
              <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Kategori
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Jam
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tanggal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No. Handphone
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Plat Nomor
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Deskripsi Keluhan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {FilteredBooking.length > 0 ? (
                  FilteredBooking.slice(pageclick, pageclick + perpage).map(
                    (booking, index) => (
                      <tr
                        key={booking.id_booking}
                        className="odd:bg-white even:bg-[#F9F9F9] border-b"
                      >
                        <td className="px-6 py-4">{index + 1}</td>
                        <td className="px-6 py-4">{booking.jenis_booking}</td>
                        <td className="px-6 py-4">{booking.jam_booking}</td>
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                          {booking.nama_lengkap}
                        </td>
                        <td className="px-6 py-4">{booking.tanggal_booking}</td>
                        <td className="px-6 py-4">{booking.no_telepon}</td>
                        <td className="px-6 py-4">{booking.plat_nomor}</td>
                        <td className="px-6 py-4">{booking.deskripsi}</td>
                        <td
                          className="px-6 py-4 cursor-pointer"
                          onClick={() => openModal(booking)}
                        >
                          {booking.id_status === 1 && (
                            <div className="flex justify-center bg-[#2AB74A] p-2 text-xs rounded-lg text-white">
                              <h1 className="text-xs">
                                {getStatusName(booking.id_status)}
                              </h1>
                            </div>
                          )}
                          {booking.id_status === 2 && (
                            <div className="flex justify-center bg-[#C8C8C8] p-2 text-xs rounded-lg text-black">
                              <h1 className="text-xs">
                                {getStatusName(booking.id_status)}
                              </h1>
                            </div>
                          )}
                          {booking.id_status === 3 && (
                            <div className="flex justify-center bg-[#E62129] p-2 text-xs rounded-lg text-white">
                              <h1 className="text-xs">
                                {getStatusName(booking.id_status)}
                              </h1>
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      Belum Melakukan Booking
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end mt-4 mx-6 text-xs">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={countpage}
            onPageChange={changePage}
            containerClassName={"flex items-center space-x-2"}
            pageClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
            }
            pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
            previousClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            nextClassName={
              "px-3 py-1 border border-gray-300 rounded-md bg-white"
            }
            activeClassName={"bg-[#F08519] text-white"}
            disabledClassName={"text-gray-300 cursor-not-allowed"}
          />
        </div>

        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-2/3 md:w-1/3">
              <div className="flex justify-between align-middle border-b-slate-400 border-b mb-4 p-3">
                <h1 className="text-sm font-bold">
                  Perbaharui Detail Status Booking
                </h1>
                <AiOutlineClose
                  size={25}
                  onClick={() => setShowModal(false)}
                  className="cursor-pointer"
                />
              </div>
              <form onSubmit={updateBooking}>
                <div className="px-3">
                  <div className={`py-2 ${error ? "block" : "hidden"}`}>
                    <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                      {error}
                    </p>
                  </div>
                  <div className="py-2">
                    <div className="py-2 flex flex-col sm:flex-row justify-between gap-2">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="2"
                          checked={idStatus === 2}
                          onChange={(e) => setIdStatus(2)}
                        />
                        <span className="ml-2 flex justify-center bg-[#C8C8C8] p-2 text-xs rounded-lg text-black">
                          Menunggu
                        </span>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="1"
                          checked={idStatus === 1}
                          onChange={(e) => setIdStatus(1)}
                        />
                        <span className="ml-2 flex justify-center bg-[#2AB74A] p-2 text-xs rounded-lg text-white">
                          Diterima
                        </span>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          value="3"
                          checked={idStatus === 3}
                          onChange={(e) => setIdStatus(3)}
                        />
                        <span className="ml-2 flex justify-center bg-[#E62129] p-2 text-xs rounded-lg text-white">
                          Ditolak
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <div className="py-2 flex justify-center">
                    <button
                      type="submit"
                      className="button bg-[#F08519] w-[50%] lg:w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;
