import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js"

const UserProfile = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState({
    nama_lengkap: "",
    username: "",
    email: "",
    no_telepon: "",
    alamat: "",
    plat_nomor: [],
  });
  const [newPlates, setNewPlates] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [editablePlat, setEditablePlat] = useState(null);

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`${url}/profil/${currentUser.id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setProfile(response.data);
      } catch (err) {
        console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
      }
    };
    getUserById();
  }, [currentUser]);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${url}/profil/${currentUser.id}`, profile, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setIsEditable(false);
      navigate(`/profil/${currentUser.id}`);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const toggleEdit = () => {
    setIsEditable(true);
  };

  const deletePlat = async (id_plat) => {
    try {
      await axios.delete(`${url}/plat/${id_plat}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setProfile({
        ...profile,
        plat_nomor: profile.plat_nomor.filter(
          (plat) => plat.id_plat !== id_plat
        ),
      });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const editPlat = (id_plat) => {
    setEditablePlat(id_plat);
  };

  const handleAddPlat = () => {
    setNewPlates([...newPlates, { plat_nomor: "", tipe: "" }]);
  };

  const handleNewPlateChange = (index, e) => {
    const { name, value } = e.target;
    const newPlateList = [...newPlates];
    newPlateList[index][name] = value;
    setNewPlates(newPlateList);
  };

  const handleSaveNewPlates = async () => {
    try {
      await Promise.all(
        newPlates.map((plat) =>
          axios.post(
            `${url}/plat`,
            { ...plat, id_user: currentUser.id },
            {
              headers: { access_token: localStorage.getItem("access_token") },
            }
          )
        )
      );
      setNewPlates([]);
      const response = await axios.get(`${url}/profil/${currentUser.id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setProfile(response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  const handlePlatChange = (e, platId) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      plat_nomor: profile.plat_nomor.map((plat) =>
        plat.id_plat === platId ? { ...plat, [name]: value } : plat
      ),
    });
  };

  const updatePlat = async (platId) => {
    const plat = profile.plat_nomor.find((plat) => plat.id_plat === platId);
    try {
      await axios.patch(`${url}/plat/${platId}`, plat, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      setEditablePlat(null);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div>
      <div className="title mb-6"></div>
      <div className="mx-2 md:mx-4 px-2 md:px-4">
        <h1 className="text-black font-bold text-xl">Profil</h1>
      </div>
      <div className="flex justify-center">
        <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={updateUser}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p
                className={`text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full`}
              >
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="nama_lengkap"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={profile.nama_lengkap}
                  onChange={handleChange}
                  placeholder="Nama Lengkap Anda"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Username<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="username"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={profile.username}
                  onChange={handleChange}
                  placeholder="Username"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Email<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="email"
                  name="email"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={profile.email}
                  onChange={handleChange}
                  placeholder="Email Aktif Anda"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No. Telepon<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  name="no_telepon"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={profile.no_telepon}
                  onChange={handleChange}
                  placeholder="08***********"
                  pattern="[0-9]*"
                  title="Nomor telepon hanya boleh terdiri dari angka."
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Alamat<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  name="alamat"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={profile.alamat}
                  onChange={handleChange}
                  placeholder="Alamat"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <div className="py-2 mx-4 flex justify-center">
              {isEditable && (
                <button
                  type="submit"
                  className="button bg-[#F08519] mt-2 font-bold text-white text-xs p-3 px-10 border-none rounded-lg"
                >
                  Simpan Profil
                </button>
              )}
            </div>
          </form>
          <div className="py-2 mx-4 flex justify-center">
            {!isEditable && (
              <button
                type="button"
                className="button bg-[#F08519] mt-2 font-bold text-white text-xs p-3 px-10  border-none rounded-lg"
                onClick={toggleEdit}
              >
                Edit Profile
              </button>
            )}
          </div>

          <div className="py-2 mx-4">
            <label className="label py-8 pb-3 text-xs">Plat Nomor</label>
            <div className="">
              <button
                type="button"
                className=" bg-[#2AB74A] font-bold text-white text-xs p-3 px-10  border-none rounded-lg mt-2"
                onClick={handleAddPlat}
              >
                Tambah Plat Nomor
              </button>
            </div>
            <div className="py-2">
              {profile.plat_nomor.map((plat) => (
                <div key={plat.id_plat} className="flex items-center mb-2">
                  {editablePlat === plat.id_plat ? (
                    <div className="flex flex-row items-center justify-between w-full">
                    <input
                      type="text"
                      name="plat_nomor"
                      className="input text-xs p-3 flex flex-grow rounded-lg border-solid border border-gray-400"
                      value={plat.plat_nomor}
                      onChange={(e) => handlePlatChange(e, plat.id_plat)}
                      placeholder="Plat Nomor"
                    />
                    <input
                      type="text"
                      name="tipe"
                      className="input text-xs p-3 flex rounded-lg border-solid border border-gray-400 ml-2"
                      value={plat.tipe}
                      onChange={(e) => handlePlatChange(e, plat.id_plat)}
                      placeholder="Tipe"
                    />
                    <button
                      className="bg-[#2AB74A] text-white text-xs font-bold p-3 rounded-lg ml-2"
                      onClick={() => updatePlat(plat.id_plat)}
                    >
                      Simpan
                    </button>
                  </div>
                  
                  ) : (
                    <div className="text-xs p-3 flex w-full rounded-lg bg-[#F9F9F9] justify-between">
                      <div className="flex-row flex">
                        <div className="mr-2">{plat.plat_nomor}</div>
                        <div className="mr-2"> - </div>
                        <div className="mr-2">{plat.tipe}</div>
                      </div>
                      <div className="flex">
                        <MdEdit
                          size={20}
                          className="text-[#F08519] mr-2 cursor-pointer"
                          onClick={() => editPlat(plat.id_plat)}
                        />
                        <MdDelete
                          size={20}
                          className="text-[#E62129] mr-2 cursor-pointer"
                          onClick={() => deletePlat(plat.id_plat)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {newPlates.map((newPlat, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    name="plat_nomor"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                    value={newPlat.plat_nomor}
                    onChange={(e) => handleNewPlateChange(index, e)}
                    placeholder="Plat Nomor"
                  />
                  <input
                    type="text"
                    name="tipe"
                    className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400 ml-2"
                    value={newPlat.tipe}
                    onChange={(e) => handleNewPlateChange(index, e)}
                    placeholder="Tipe"
                  />
                </div>
              ))}

              {newPlates.length > 0 && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="bg-[#F08519] mt-2 font-bold text-white text-xs p-3 px-10  border-none rounded-lg"
                    onClick={handleSaveNewPlates}
                  >
                    Simpan Plat Nomor
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="my-4 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4"></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;