import React, { useEffect, useRef, useState } from "react";
import logo from "../img/logo_crazy_fast.png";
import { useReactToPrint } from "react-to-print";
import cap from "../img/cap.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import { url } from "../context/url.js";
import { formatCurrency } from "../context/formatCurrency.js"; 

const Nota = () => {
  const [notaData, setNotaData] = useState(null);
  const componentPDF = useRef();
  const { id } = useParams();

  useEffect(() => {
    const fetchNotaData = async () => {
      try {
        const response = await axios.get(`${url}/transaksi/nota/${id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setNotaData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchNotaData();
  }, [id]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Nota",
    onAfterPrint: () => alert("Data berhasil disimpan sebagai PDF"),
  });

  if (!notaData) {
    return <div>Loading...</div>;
  }

  const diskon =
    notaData.transaksi_suku_cadang.reduce((total, sc) => total + sc.diskon, 0) +
    notaData.transaksi_jasa.reduce((total, j) => total + j.diskon_jasa, 0);

  return (
    <div>
      <header className="ml-8">
        <div className="d-grid d-md-flex justify-content-md-end mb-3">
          <button
            className="bg-[#F08519] rounded-md font-medium p-2 mx-auto my-6 py-3 text-white text-sm"
            onClick={generatePDF}
          >
            Print PDF
          </button>
        </div>
      </header>

      <section ref={componentPDF} style={{ width: "100%" }}>
        <div className="flex justify-center min-h-screen">
          <div className="bg-white border-spacing-0 w-[95%] flex flex-col items-center">
            <div className="flex justify-start items-center w-full max-w-full px-4 text-zinc-950">
              <div>
                <img src={logo} alt="Logo" className="h-[100px]" />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-bold">Crazyfast Speedshop</h1>
                <h1 className="text-xs">
                  Pabeyan Kulon, Gedongmulyo, Kec. Lasem, Kabupaten Rembang,
                  Jawa Tengah
                </h1>
              </div>
            </div>

            <div className="w-full border-t-2 py-4 text-center mt-2">
              {notaData.id_status === 2 ? (
                <h1>NOTA NO. {notaData.id_nota}</h1>
              ) : (
                <h1>INVOICE NO. {notaData.id_invoice}</h1>
              )}
            </div>

            <div className="w-full my-2 flex justify-between">
              <div className="mx-10 text-xs">
                <h1>Tanggal: {notaData.tanggal}</h1>
                <h1>Nama: {notaData.nama_lengkap}</h1>
                <h1>Plat: {notaData.plat_nomor}</h1>
              </div>
              <div className="mx-10 text-xs">
                <h1>Tipe Kendaraan: {notaData.tipe}</h1>
                <h1>Pegawai: {notaData.nama_pegawai}</h1>
              </div>
            </div>

            <div className="w-full px-10 mb-4 text-[10px]">
              <table className="w-full border-collapse border border-zinc-950">
                <thead>
                  <tr className="bg-zinc-950 text-white">
                    <th className="border border-zinc-950 text-xs font-normal">
                      Deskripsi
                    </th>
                    <th className="border border-zinc-950 text-xs font-normal">
                      Harga
                    </th>
                    <th className="border border-zinc-950 text-xs font-normal">
                      Jumlah
                    </th>
                    <th className="border border-zinc-950 text-xs font-normal">
                      Diskon
                    </th>
                    <th className="border border-zinc-950 text-xs font-normal">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notaData.transaksi_servis.map((servis, index) => (
                    <tr key={index}>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {servis.nama_servis}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(servis.harga)}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {" "}
                        -{" "}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(0)} {/* Diskon default 0 */}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(servis.harga)}
                      </td>
                    </tr>
                  ))}
                  {notaData.transaksi_suku_cadang.map((sc, index) => (
                    <tr key={index}>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {sc.nama_produk || 'N/A'} {/* Handle missing nama_suku_cadang */}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(sc.harga)}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {sc.jumlah_suku_cadang}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(sc.diskon || 0)} {/* Handle missing diskon */}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(sc.harga * sc.jumlah_suku_cadang - (sc.diskon || 0))}
                      </td>
                    </tr>
                  ))}
                  {notaData.transaksi_jasa.map((jasa, index) => (
                    <tr key={index}>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {jasa.nama_jasa || 'N/A'} {/* Handle missing nama_jasa */}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(jasa.harga)}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {" "}
                        -{" "}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(jasa.diskon_jasa || 0)} {/* Handle missing diskon_jasa */}
                      </td>
                      <td className="border border-zinc-950 text-xs font-normal px-2">
                        {formatCurrency(jasa.harga - (jasa.diskon_jasa || 0))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="w-full px-10 mb-4 text-xs">
              <h1 className="font-bold my-2">Deskripsi Keluhan:</h1>
              <p>{notaData.deskripsi_booking}</p>
            </div>

            <div className="w-full my-2 py-4 flex flex-col items-end relative">
              {notaData.id_status === 2 && (
                <div className="absolute right-0 top-[-50px]">
                  <img src={cap} alt="Cap" className="h-[100px]" />
                </div>
              )}
              <div className="mx-10 text-xs">
                <h1>
                  <b>DISKON</b> : {formatCurrency(diskon)}
                </h1>
                <h1>
                  <b>TOTAL</b> : {formatCurrency(notaData.total)}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Nota;
