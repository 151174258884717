import React, { useContext, useState, useRef, useEffect } from "react";
import { GoHomeFill } from "react-icons/go";
import { FaAngleRight } from "react-icons/fa6";
import { GoHistory } from "react-icons/go";
import { GiFlatTire } from "react-icons/gi";
import { GrResources } from "react-icons/gr";
import { FaBoxOpen } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { SiAdguard } from "react-icons/si";
import { FaUserGear } from "react-icons/fa6";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { FaRegCalendar } from "react-icons/fa6";
import { FaUserGroup } from "react-icons/fa6";
import { RiSecurePaymentLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { AuthContext } from "../context/authcContext";
import logo from "../img/logo_crazy_fast.png";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const { currentUser, logout } = useContext(AuthContext);
  const [nav, setNav] = useState(false);
  const sidebarRef = useRef(null);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setNav(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setNav(false); // Ensure sidebar is always open on larger screens
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navItems = [
    {
      icon: <GoHomeFill size={25} />,
      label: "Dashboard",
      link: "/dashboardinter",
    },
    {
      icon: <GoHistory size={25} />,
      label: "Riwayat Aktivitas",
      link: "/riwayat",
    },
    ...(currentUser && currentUser.role === 1
      ? [
          {
            icon: <GrResources size={25} />,
            label: "Manajemen Sumber Daya",
            link: "/sumber-daya",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Inventory
              </h1>
            ),
          },
          {
            icon: <GiFlatTire size={25} />,
            label: "Produk Suku Cadang",
            link: "/suku-cadang",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Data Master
              </h1>
            ),
          },
          {
            icon: <FaBoxOpen size={25} />,
            label: "Data Supplier",
            link: "/supplier",
          },
          { icon: <FaUser size={25} />, label: "Data Pengguna", link: "/user" },
          {
            icon: <FaUserGear size={25} />,
            label: "Data Pegawai",
            link: "/pegawai",
          },
          {
            icon: <FaMoneyBillTransfer size={25} />,
            label: "Data Gaji",
            link: "/gaji",
          },
          {
            icon: <SiAdguard size={25} />,
            label: "Data Klaim Garansi",
            link: "/garansi",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Laporan
              </h1>
            ),
          },
          {
            icon: <IoStatsChart size={25} />,
            label: "Laporan Keuangan",
            link: "/laporan",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Servis
              </h1>
            ),
          },
          {
            icon: <FaRegCalendar size={25} />,
            label: "Booking Service",
            link: "/booking",
          },
          {
            icon: <FaUserGroup size={25} />,
            label: "Antrian Layanan",
            link: "/antrian/booking",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Transaksi
              </h1>
            ),
          },
          {
            icon: <FaTools size={25} />,
            label: "Manajemen Layanan",
            link: "/transaksi",
          },
          {
            icon: <RiSecurePaymentLine size={25} />,
            label: "Transaksi Garansi",
            link: "/transaksi/garansi",
          },
        ]
      : []),
    ...(currentUser && currentUser.role === 2
      ? [
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Inventory
              </h1>
            ),
          },
          {
            icon: <GiFlatTire size={25} />,
            label: "Produk Suku Cadang",
            link: "/suku-cadang",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Data Master
              </h1>
            ),
          },
          {
            icon: <FaBoxOpen size={25} />,
            label: "Data Supplier",
            link: "/supplier",
          },
        ]
      : []),
    ...(currentUser && currentUser.role === 3
      ? [
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Data Master
              </h1>
            ),
          },
          { icon: <FaUser size={25} />, label: "Data Pengguna", link: "/user" },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Servis
              </h1>
            ),
          },
          {
            icon: <FaRegCalendar size={25} />,
            label: "Booking Service",
            link: "/booking",
          },
          {
            icon: <FaUserGroup size={25} />,
            label: "Antrian Layanan",
            link: "/antrian/booking",
          },
          {
            content: (
              <h1 className="text-black font-semibold text-sm md:text-lg p-2">
                Transaksi
              </h1>
            ),
          },
          {
            icon: <FaTools size={25} />,
            label: "Manajemen Layanan",
            link: "/transaksi",
          },
        ]
      : []),
  ];

  return (
    <div className="flex">
      <div
        ref={sidebarRef}
        className={`h-full border-r border-r-gray-300 bg-white relative duration-300 ${
          !nav ?  "w-2/5 lg:w-1/5" : "w-16"
        }`}
      >
        <div
          onClick={handleNav}
          className="cursor-pointer duration-300 text-white bg-[#E62129] border border-white w-10 h-10 flex items-center justify-center text-xl rounded-full absolute top-10 right-[-20px]"
        >
          <FaAngleRight className={`${!nav ? "rotate-180" : "rotate-0" }`} />
        </div>
        <div className="mx-4 my-2">
          <div className="">
            <Link to={"/"}>
            <img
              src={logo}
              alt="Logo"
              className="h-26 object-contain justify-center flex-col mx-auto rounded-lg "
              />
            </Link>
            <div className="pb-4">
              <p
                className={`items-center w-[80%] mx-auto text-center border-y-[1px] border-gray-400 py-2 ${
                  !nav ? "block" : "hidden"
                }`}
              >
                {currentUser.username}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <ul className="text-gray-400 flex flex-col gap-4 p-2">
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`flex gap-2 items-center text-base rounded-md ${
                  location.pathname === item.link ? "bg-[#FCE9E9]" : ""
                }`}
              >
                {item.content ? (
                  <div
                    className={`${!nav ? "block" : "hidden"} ${
                      location.pathname === item.link ? "text-[#E62129]" : ""
                    }`}
                  >
                    {item.content}
                  </div>
                ) : (
                  <a
                    href={item.link}
                    className="flex gap-2 text-base items-center p-2 rounded-md hover:bg-[#F4F4F4] "
                  >
                    <div
                      className={`text-[#777777] ${
                        location.pathname === item.link ? "text-[#E62129]" : ""
                      }`}
                    >
                      {item.icon}
                    </div>
                    <span
                      className={`${!nav ? "block" : "hidden"} 
                       ${
                         location.pathname === item.link ? "text-[#E62129]" : ""
                       }
                      text-xs md:text-base text-[#777777]`}
                    >
                      {item.label}
                    </span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="p-2 ml-1">
          <ul>
            <li className="flex items-start rounded-lg text-gray-400 gap-2 hover:bg-[#F4F4F4] p-2">
              <button className="flex items-center gap-2" onClick={logout}>
                <TbLogout size={25} className="text-[#777777]" />
                <span
                  className={`${
                    !nav ? "block" : "hidden" 
                  } text-[#777777] font-medium`}
                >
                  Logout
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className={`bg-[#F7F7F7] ${!nav ? "w-[80%]" : "w-full"}`}>
        <main className="w-full">{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
