import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../context/authcContext.js";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { url } from "../context/url.js";

const RiwayatUser = () => {
  const { currentUser } = useContext(AuthContext);
  const [riwayat, setRiwayat] = useState([]);
  const [riwayatBooking, setRiwayatBooking] = useState([]);
  const [StatusTransaksi, setStatusTransaksi] = useState([]);
  const [StatusBooking, setStatusBooking] = useState([]);
  const [FilteredRiwayat, setFilteredRiwayat] = useState([]);
  const [FilteredRiwayatBooking, setFilteredRiwayatBooking] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagenumber, setPagenumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const [selectedType, setSelectedType] = useState("riwayatUser"); // New state for selecting type
  const pageclick = pagenumber * perpage;
  const countpage = Math.ceil(
    (selectedType === "riwayatUser"
      ? FilteredRiwayat.length
      : FilteredRiwayatBooking.length) / perpage
  );

  const changePage = ({ selected }) => {
    setPagenumber(selected);
  };

  useEffect(() => {
    getRiwayatAntrian();
    getRiwayatBooking();
    getStatusTransaksi();
    getStatusBooking();
  }, []);

  useEffect(() => {
    filterRiwayat();
    filterRiwayatBooking();
  }, [riwayat, riwayatBooking, StatusTransaksi, StatusBooking, searchTerm]);

  const getRiwayatAntrian = async () => {
    try {
      const response = await axios.get(`${url}/riwayat/${currentUser.id}`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setRiwayat(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getRiwayatBooking = async () => {
    try {
      const response = await axios.get(
        `${url}/riwayat/booking/${currentUser.id}`,
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setRiwayatBooking(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatusTransaksi = async () => {
    try {
      const response = await axios.get(`${url}/status/transaksi`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatusTransaksi(response.data);
      }
    } catch (error) {
      console.error("Error Mengambil Data Status Transaksi:", error.response.data);
    }
  };

  const getStatusBooking = async () => {
    try {
      const response = await axios.get(`${url}/status/booking`, {
        headers: { access_token: localStorage.getItem("access_token") },
      });
      if (response.data.err) {
        alert(response.data.err);
      } else {
        setStatusBooking(response.data);
      }
    } catch (error) {
      console.error("Error Mengambil Data Status Booking:", error.response.data);
    }
  };

  const handlePerPageChange = (e) => {
    setPerpage(Number(e.target.value));
    setPagenumber(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPagenumber(0);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setPagenumber(0);
  };

  const getStatusTransaksiName = (id_status) => {
    const status = StatusTransaksi.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };
  
  const getStatusBookingName = (id_status) => {
    const status = StatusBooking.find((status) => status.id_status === id_status);
    return status ? status.status : "Unknown";
  };

  const filterRiwayat = () => {
    const filtered = riwayat.filter((riwayat) => {
      const statusName = getStatusTransaksiName(riwayat.id_status).toLowerCase();
      const tanggal = riwayat.tanggal
        ? riwayat.tanggal.toString().toLowerCase()
        : "";
      const idInvoice = riwayat.id_invoice
        ? riwayat.id_invoice.toString().toLowerCase()
        : "";
      const total = riwayat.total ? riwayat.total.toString().toLowerCase() : "";

      return (
        tanggal.includes(searchTerm) ||
        idInvoice.includes(searchTerm) ||
        total.includes(searchTerm) ||
        statusName.includes(searchTerm)
      );
    });
    setFilteredRiwayat(filtered);
  };

  const filterRiwayatBooking = () => {
    const filtered = riwayatBooking.filter((riwayatBooking) => {
      const statusBookingName = getStatusBookingName(riwayatBooking.id_status).toLowerCase();
      const platNomor = riwayatBooking.plat_nomor
        ? riwayatBooking.plat_nomor.toString().toLowerCase()
        : "";
      const deskripsi = riwayatBooking.deskripsi
        ? riwayatBooking.deskripsi.toString().toLowerCase()
        : "";

      return (
        platNomor.includes(searchTerm) ||
        deskripsi.includes(searchTerm) ||
        statusBookingName.includes(searchTerm)
      );
    });
    setFilteredRiwayatBooking(filtered);
  };

  return (
    <div className="w-full h-screen my-2 mb-8">
      <div className="m-2 md:m-4 p-2 md:p-4 rounded-lg shadow-md overflow-auto scrollbar-hide bg-white">
        <h1 className="text-2xl font-bold text-black ">Riwayat Aktivitas</h1>
        <div className="flex justify-between my-2">
          <div className="search flex justify-between gap-2">
            <div className="flex-grow relative flex justify-end">
              <input
                type="text"
                placeholder="Cari"
                className="bg-[#F7F7F7] border border-gray-400 text-gray-700 px-3 lg:px-5 py-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
                <IoMdSearch size={15} className="text-[#777777]" />
              </span>
            </div>

            <select
              className="w-fit bg-[#F7F7F7] border border-gray-400 text-[#777777] py-2 px-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={selectedType}
              onChange={handleTypeChange}
            >
              <option value="" disabled>
                Pilih Riwayat
              </option>
              <option value="riwayatUser">Riwayat Transaksi</option>
              <option value="riwayatBooking">Riwayat Booking</option>
            </select>
          </div>

          <div className="flex gap-2 items-center justify-end">
            <p className="text-xs">Show</p>
            <select
              className="w-fit bg-[#F7F7F7] border border-gray-400 text-gray-700 px-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              value={perpage}
              onChange={handlePerPageChange}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p className="text-xs">entries</p>
          </div>
        </div>
      </div>

      <div className="w-full bg-white">
        {selectedType === "riwayatUser" ? (
          // Riwayat Transaksi
          <div className="flex justify-center my-4">
            <div className="m-2 md:m-4 overflow-x-auto w-[100%] relative shadow-md rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
                <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tanggal
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No Invoice
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No Nota
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status Transaksi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Target Pengerjaan
                    </th>
                  </tr>
                </thead>
                <tbody>
  {FilteredRiwayat.length > 0 ? (
    FilteredRiwayat.slice(pageclick, pageclick + perpage).map(
      (riwayat, index) => (
        <tr
          key={riwayat.id_transaksi}
          className="odd:bg-white even:bg-[#F9F9F9] border-b hover:bg-gray-100"
        >
          <td className="px-6 py-3">
            {pagenumber * perpage + index + 1}
          </td>
          <td className="px-6 py-3">{riwayat.tanggal}</td>
          <td className="px-6 py-3">{riwayat.id_invoice}</td>
          <td className="px-6 py-4">
            {riwayat.id_nota ? (
              <a
                href={`/klaim-garansi/${currentUser.id}?id_nota=${riwayat.id_nota}`}
                target="_blank"
                rel="noopener noreferrer"
                title="Mengajukan Klaim Garansi"
                className="text-gray-500 underline hover:text-[#E62129]"
              >
                {riwayat.id_nota}
              </a>
            ) : (
              "Status On Progress"
            )}
          </td>
          <td className="px-6 py-3">
            {riwayat.total.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
            })}
          </td>
          <td className="px-6 py-3 capitalize">
            {getStatusTransaksiName(riwayat.id_status)}
          </td>
          <td className="px-6 py-4">
            {riwayat.id_status === 2 ? (
              "Sudah Selesai"
            ) : riwayat.hari_masuk < 0 ? (
              "Sudah Melewati Target Hari"
            ) : (
              `${riwayat.hari_masuk} dari ${riwayat.target_hari} hari`
            )}
          </td>
        </tr>
      )
    )
  ) : (
    <tr>
      <td colSpan="7" className="text-center py-3">
        Tidak ada data
      </td>
    </tr>
  )}
</tbody>
              </table>
            </div>
          </div>
        ) : (
          // Riwayat Booking
          <div className="flex justify-center my-4">
            <div className="m-2 md:m-4 overflow-x-auto w-[100%] relative shadow-md rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-b-2">
                <thead className="text-xs text-gray-700 bg-[#F9F9F9] border-b">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Plat Nomor
                    </th>
<th scope="col" className="px-6 py-3">
                      Tanggal Booking
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Deskripsi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status Booking
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FilteredRiwayatBooking.length > 0 ? (
                    FilteredRiwayatBooking.slice(
                      pageclick,
                      pageclick + perpage
                    ).map((riwayatBooking, index) => (
                      <tr
                        key={riwayatBooking.id_transaksi}
                        className="odd:bg-white even:bg-[#F9F9F9] border-b hover:bg-gray-100"
                      >
                        <td className="px-6 py-3">
                          {pagenumber * perpage + index + 1}
                        </td>
                        <td className="px-6 py-3">
                          {riwayatBooking.plat_nomor}
                        </td>
<td className="px-6 py-3">
                          {riwayatBooking.tanggal_booking}
                        </td>
                        <td className="px-6 py-3">
                          {riwayatBooking.deskripsi}
                        </td>
                        <td className="px-6 py-3 capitalize">
                          {getStatusBookingName(riwayatBooking.id_status)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center py-3">
                        Tidak ada data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end mx-4 text-xs">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={countpage}
          onPageChange={changePage}
          containerClassName={"flex items-center space-x-2"}
          pageClassName={
            "px-3 py-1 border border-gray-300 rounded-md hover:text-white hover:bg-[#777777]"
          }
          pageLinkClassName={"hover:text-white hover:bg-[#777777]"}
          previousClassName={
            "px-3 py-1 border border-gray-300 rounded-md bg-white"
          }
          nextClassName={"px-3 py-1 border border-gray-300 rounded-md bg-white"}
          activeClassName={"bg-[#F08519] text-white"}
          disabledClassName={"text-gray-300 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default RiwayatUser;
