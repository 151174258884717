import React from 'react';
import logo from '../img/logo_crazy_fast.png';
import { RiWhatsappFill, RiInstagramFill, RiTiktokFill } from 'react-icons/ri';
import { FaLocationDot } from 'react-icons/fa6';

const Footer = () => {
  return (
    <div className='bg-[#E62129] text-white py-8 px-4'>
      <div className='container mx-auto flex flex-col lg:flex-row gap-8'>
        
        <div className='flex flex-col items-center lg:items-start p-8 text-center lg:text-left'>
          <img src={logo} alt='Logo' className='w-32 md:w-40 py-5' />
          <div>
            <p className='font-bold mb-2'>HARI SABTU - KAMIS 08:00-16:30 (JUMAT LIBUR)</p>
            <a href="https://maps.app.goo.gl/VfaAFtvAkYiGisCm7" target="_blank" rel="noopener noreferrer">
              <h1 className='font-bold text-2xl py-2'>LOKASI</h1>
            </a>
            <p>Pabeyan Kulon, Gedongmulyo, Kec. Lasem, Kabupaten Rembang, Jawa Tengah</p>
          </div>
        </div>
        
        <div className='flex flex-col items-center justify-center align-middle p-8 text-center'>
          <p className='font-bold text-xl py-2'>Stay Connected</p>
          <div className='flex flex-wrap justify-center gap-4 py-2'>
            <a href="https://www.instagram.com/crazyfast_speedshop/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">
              <RiInstagramFill size={70} className='mx-2' />
            </a>
            <a href="https://wa.me/628974229333" target="_blank" rel="noopener noreferrer">
              <RiWhatsappFill size={70} className='mx-2' />
            </a>
            <a href="https://www.tiktok.com/@crazyfast_speedshop?_t=8anN1aPGFpS&_r=1" target="_blank" rel="noopener noreferrer">
              <RiTiktokFill size={70} className='mx-2' />
            </a>
            <a href="https://maps.app.goo.gl/VfaAFtvAkYiGisCm7" target="_blank" rel="noopener noreferrer">
              <FaLocationDot size={70} className='mx-2' />
            </a>
          </div>
          <p className='py-2'>Copyright <b>© CrazyFast SpeedShop 2024</b></p>
        </div>
        
      </div>
    </div>
  );
}

export default Footer;
