import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BookingModal from "../components/BookingModal";
import { AuthContext } from "../context/authcContext";
import { url } from "../context/url.js";
import { BsFillPatchCheckFill } from "react-icons/bs";

const BookingUser = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [JamBooking, setJamBooking] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [plat, setPlat] = useState([]);
  const [user, setUser] = useState({
    id_user: "",
    nama_lengkap: "",
    id_plat: "",
    no_telepon: "",
  });
  const [modalVisible, setModalVisible] = useState(true); // Control modal visibility
  const IdStatusDefault = 2;
  const IdJenisOnline = 1; 
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  
  const saveBooking = async (e) => {
    e.preventDefault();
    if (!user.id_plat) {
      setError("Harap Menambahkan Plat Anda Terlebih Dahulu di Profil Pengguna.");
      return;
    }
    try {
      await axios.post(
        `${url}/bookinguser`,
        {
          id_user: user.id_user,
          tanggal_booking: tanggal,
          id_plat: user.id_plat,
          id_status: IdStatusDefault,
          deskripsi: deskripsi,
          id_jam: JamBooking,
          id_jenis: IdJenisOnline,
        },
        {
          headers: { access_token: localStorage.getItem("access_token") },
        }
      );
            setShowModal(true);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data.err || "An error occurred.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`${url}/profil/${currentUser.id}`, {
          headers: { access_token: localStorage.getItem("access_token") },
        });
        setUser(response.data);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          setError(err.response.data.error || "An error occurred.");
        } else {
          setError("An error occurred.");
        }
      }
    };

    const getPlatById = async () => {
      try {
        const response = await axios.get(
          `${url}/platuser/${currentUser.id}`,
          {
            headers: { access_token: localStorage.getItem("access_token") },
          }
        );
        if (response.data.length === 0) {
          setError("Harap Menambahkan Plat Anda Terlebih Dahulu di Profil Pengguna.");
        } else {
          setPlat(response.data);
          setError(null);
        }
      } catch (err) {
        console.error("Error fetching plat data:", err); 
        if (err.response) {
          if (err.response.status === 404) {
            setError("Harap Menambahkan Plat Anda Terlebih Dahulu di Profil Pengguna.");
          } else {
            setError(err.response.data.error || "An error occurred.");
          }
        } else {
          setError("An error occurred.");
        }
      }
    };

    getUserById();
    getPlatById();
  }, [currentUser]);

  const handleModalSubmit = (data) => {
    setTanggal(data.tanggal);
    setJamBooking(data.selectedJamBooking);
    setModalVisible(false); 
  };

  return (
    <div>
    {modalVisible && (
        <BookingModal
          showModal={modalVisible}
          setShowModal={setModalVisible}
          onSubmit={handleModalSubmit}
        />
      )}      <div className={`mx-2 md:mx-4 px-2 md:px-4 mt-6 ${modalVisible ? "hidden" : "block"}`}>
        <h1 className="text-black font-bold text-xl">Form Layanan Booking</h1>
      </div>

      <div className={`flex justify-center ${modalVisible ? "hidden" : "block"}`}>
        <div className="my-2 overflow-x-auto w-[96%] relative shadow-md rounded-lg bg-white py-4">
          <form onSubmit={saveBooking}>
            <div className={`py-2 mx-4 ${error ? "block" : "hidden"}`}>
              <p className="text-[#E62129] text-xs font-medium bg-[#FCE7D1] p-3 border-none rounded-lg w-full">
                {error}
              </p>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Nama<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="text"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={user.nama_lengkap}
                  readOnly
                  placeholder="Pelanggan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                No Handphone<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <input
                  type="number"
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={user.no_telepon}
                  readOnly
                  placeholder="No Telepon Anda"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Plat Nomor<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <select
                  name="id_plat"
                  required
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={user.id_plat}
                  onChange={(e) => setUser({ ...user, id_plat: e.target.value })}
                >
                  <option value="" disabled>
                    Pilih Plat Nomor
                  </option>
                  {plat.map((p) => (
                    <option key={p.id_plat} value={p.id_plat}>
                      {p.plat_nomor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="py-2 mx-4">
              <label className="label py-8 pb-3 text-xs">
                Deskripsi Kerusakan<sup className="text-[#E62129]">*</sup>
              </label>
              <div className="py-2">
                <textarea
                  className="input text-xs p-3 flex w-full rounded-lg border-solid border border-gray-400"
                  value={deskripsi}
                  required
                  onChange={(e) => setDeskripsi(e.target.value)}
                  placeholder="Tulis Kerusakan"
                />
              </div>
            </div>
            <div className="py-2 mx-4">
              <div className="py-2 flex justify-center">
                <button
                  type="submit"
                  className="button bg-[#F08519] w-[30%] font-bold text-white text-xs p-3 border-none rounded-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

{/* Modal */}
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-md mx-4 sm:mx-6 lg:mx-8 my-6">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative mt-6 p-3 flex-auto flex justify-center">
                  <BsFillPatchCheckFill size={80} className='text-[#2AB74A]'/>
                </div>
                <div className="relative pt-3 flex-auto flex justify-center">
                  <h1 className='text-xl font-semibold'>Berhasil</h1>
                </div>
                <div className="relative px-6 flex-auto text-center">
                  <p className="my-4 text-[#777777] text-sm leading-relaxed">
                    Pengajuan Booking Anda Berhasil Diajukan. Silahkan Menghubungi <a href="https://wa.me/628974229333" target="_blank" rel="noopener noreferrer" className="hover:text-gray-800 ">08974229333</a> untuk konfirmasi booking. Terimakasih!
                  </p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="bg-[#E62129] relative p-6 flex-auto text-white hover:bg-[#E8373E] font-bold uppercase text-sm py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      navigate('/dashboard');  // Redirect to a different route upon closing modal
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}



    </div>
  );
};

export default BookingUser;
